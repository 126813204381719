import PropTypes from "prop-types";
import React from "react";

export default function InputGroup({
  items,
  onPressRightIcon,
  onChangeText,
  customForm,
  onBlur,
}) {
  return (
    <div style={{ ...styles.form, ...customForm }}>
      {items?.map((item, index) => (
        <div
          key={index}
          style={{
            ...styles.inputContainer,
            borderBottom:
              index + 1 === items.length ? "none" : "1px solid lightgray",
          }}
        >
          {item?.iconLeft && <div>{item?.iconLeft}</div>}
          <input
            disabled={item?.disabled}
            style={{
              ...styles.input,
              paddingLeft: item?.iconLeft ? 10 : 0,
              paddingRight: item?.iconRight ? 10 : 0,
              color: item?.disabled ? "gray" : "#2b2b2b",
              backgroundColor: "#fff",
            }}
            placeholder={item?.label}
            value={item?.value}
            onChange={(e) => onChangeText(item?.key, e.target.value)}
            type={
              item?.key === "password" && !item?.passwordVisible
                ? "password"
                : "text"
            }
            onBlur={() => onBlur && onBlur(item?.key, item.value)}
          />
          {item?.iconRight && (
            <button
              onClick={() => onPressRightIcon({ key: item?.key })}
              style={styles.button}
            >
              {item?.iconRight}
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

InputGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      value: PropTypes.string,
      iconLeft: PropTypes.node,
      iconRight: PropTypes.node,
      disabled: PropTypes.bool,
      passwordVisible: PropTypes.bool,
      keyboardType: PropTypes.string,
    })
  ).isRequired,
  onPressRightIcon: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  customForm: PropTypes.object,
  onBlur: PropTypes.func,
};

const styles = {
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
  },
  form: {
    width: "100%",
    borderRadius: 20,
    // padding: 10,
    border: "1px solid lightgray",
    background: "#fff",
  },
  input: {
    flex: 1,
    border: "none",
    outline: "none",
  },
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
    margin: 0,
  },
};
