import { Box, Chip, Divider, Typography } from "@mui/material";
import { Clear, History, Star } from "@mui/icons-material";
import { Dialog, Search, Table } from "../components";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import { db } from "../firebase";
import uniqolor from "uniqolor";
import { useAppContext } from "../context";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AppBarSearch() {
  const { search, setSearch, favorites } = useAppContext();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDoc);

  const [searchText, setSearchText] = useState("");
  const [lastSearches, setLastSearches] = useState([]);

  useEffect(() => {
    let unsubscribeLastSearches = null;

    if (user) {
      unsubscribeLastSearches = listenLastSearches();
    }

    return () => {
      if (unsubscribeLastSearches) unsubscribeLastSearches();
    };
  }, [user]);

  function listenLastSearches() {
    const newSearchQuery = query(
      collection(db, "search"),
      where("userUid", "==", user?.uid),
      orderBy("createdAt", "desc"),
      limit(3)
    );

    return onSnapshot(newSearchQuery, (querySnapshot) => {
      const searches = querySnapshot.docs.map((doc) => ({
        docId: doc.id,
        ...doc.data(),
      }));
      setLastSearches(searches);
    });
  }

  async function handleFavorites(e) {
    try {
      const favorite = favorites.find((f) => f.primary === e.primary);
      if (favorite) {
        await deleteDoc(doc(db, "favorites", favorite?.docId));
      } else {
        await addDoc(collection(db, "favorites"), {
          userUid: user?.uid,
          ...e,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function saveSearch(e) {
    try {
      await addDoc(collection(db, "search"), {
        userUid: user?.uid,
        search: e,
        createdAt: new Date(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function removeSearch(id) {
    try {
      await deleteDoc(doc(db, "search", id));
    } catch (error) {
      console.error(error);
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/search/${e.target.value}`);
      setSearch(false);
      saveSearch(e.target.value);
      setSearchText("");
    }
  };

  return (
    <Dialog
      open={search}
      onClose={() => setSearch(false)}
      hideHeader={true}
      sx={{ padding: "0 0 0rem 0" }}
    >
      <Search
        onChange={(e) => setSearchText(e)}
        onKeyPress={handleKeyPress}
        value={searchText}
        onClose={() => setSearch(false)}
      />

      <Divider />
      <Box
        sx={{
          paddingX: 2,
          paddingBottom:
            lastSearches?.length > 0 || favorites?.length > 0 ? 2 : 0,
        }}
      >
        <Table
          rows={lastSearches?.map((l, i) => {
            return {
              id: i,
              name: l.search,
            };
          })}
          items={[
            {
              xs: 11,
              component: (id) => {
                return (
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <History sx={{ color: "gray", paddingRight: "0.2rem" }} />
                    <Typography sx={{ paddingLeft: "0.2rem" }}>
                      {lastSearches[id]?.search}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              xs: 1,
              component: (id) => {
                return (
                  <Clear
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSearch(lastSearches[id]?.docId);
                    }}
                    sx={{ color: "gray", paddingRight: "0.2rem" }}
                  />
                );
              },
            },
          ]}
          header={
            lastSearches?.length > 0
              ? [
                  {
                    xs: 12,
                    label: "Son Aramalar",
                  },
                ]
              : undefined
          }
          onClick={(id) => {
            navigate(`/search/${lastSearches[id]?.search}`);
            setSearch(false);
          }}
        />

        <Table
          rows={favorites?.map((f, i) => {
            return {
              id: i,
              name: f.primary,
            };
          })}
          header={
            favorites?.length > 0
              ? [
                  {
                    xs: 12,
                    label: "Favoriler",
                  },
                ]
              : undefined
          }
          items={[
            {
              xs: 11,
              component: (id) => {
                return (
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Chip
                      sx={{
                        marginRight: "0.2rem",
                        color: uniqolor(favorites[id]?.type || "").color,
                        backgroundColor: `${
                          uniqolor(favorites[id]?.type || "").color
                        }10`,
                      }}
                      size="small"
                      label={
                        favorites[id]?.type === "company" ? "firma" : "ürün"
                      }
                    />
                    <Typography sx={{ paddingLeft: "0.2rem" }}>
                      {favorites[id]?.primary}
                    </Typography>
                  </Box>
                );
              },
            },
            {
              xs: 1,
              component: (id) => {
                return (
                  <Star
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavorites(favorites[id]);
                    }}
                    sx={{ color: "orange", paddingRight: "0.2rem" }}
                  />
                );
              },
            },
          ]}
          onClick={(id) => {
            if (favorites[id]?.type === "company") {
              navigate(`/companies/${favorites[id]?.id}`);
            } else {
              navigate(`products-services/${favorites[id]?.id}`);
            }
            setSearch(false);
          }}
        />
      </Box>
    </Dialog>
  );
}
