import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { createCurrency } from "../../utils";
import { useOrderContext } from "../../context";

export default function ShoppingNavigation({
  activeSection,
  handleScroll,
  shoppingCartRef,
  orderFormsRef,
  ordersRef,
}) {
  const { groupedOrders } = useOrderContext();
  const [orderTotal, setOrderTotal] = useState(0);

  useEffect(() => {
    const total = Object.keys(groupedOrders || {})?.reduce((acc, next) => {
      return (
        acc +
        Object.keys(groupedOrders[next]?.products || {}).reduce(
          (acc2, next2) => {
            return (
              acc2 +
              groupedOrders[next]?.products[next2].orderCount *
                groupedOrders[next]?.products[next2].price
            );
          },
          0
        )
      );
    }, 0);
    setOrderTotal(total);
  }, [groupedOrders]);

  return (
    <Box
      sx={{
        width: { md: "none", lg: "25%" },
        display: { xs: "none", sm: "none", md: "none", lg: "block" },
        position: "sticky",
        top: 96,
        marginX: 2,
        minWidth: "25%",
      }}
    >
      <Box
        sx={{
          border: "1px solid #dfdeda",
          borderRadius: 4,
          backgroundColor: "white",
          paddingBottom: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.6rem",
            fontWeight: "bold",
            paddingTop: 2,
            paddingLeft: 2,
          }}
        >
          Bu Sayfada
        </Typography>
        <Typography
          sx={{
            paddingY: "0.6rem",
            paddingX: 2,
            borderLeft:
              activeSection === "shoppingCart" ? "2px solid green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleScroll(shoppingCartRef)}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#f4f2ee";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "white";
          }}
        >
          Sepetim
        </Typography>
        <Typography
          sx={{
            paddingY: "0.6rem",
            paddingX: 2,
            borderLeft: activeSection === "orderForms" ? "2px solid green" : "",
            cursor: "pointer",
            //   display: pendingInvoices ? "block" : "none",
          }}
          onClick={() => handleScroll(orderFormsRef)}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#f4f2ee";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "white";
          }}
        >
          Sipariş Listeleri
        </Typography>
        <Typography
          sx={{
            paddingY: "0.6rem",
            paddingX: 2,
            borderLeft: activeSection === "orders" ? "2px solid green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleScroll(ordersRef)}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#f4f2ee";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "white";
          }}
        >
          Siparişler
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid #dfdeda",
          borderRadius: 4,
          backgroundColor: "white",
          // paddingBottom: "1rem",
          marginTop: "1rem",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.6rem",
            fontWeight: "bold",
            paddingTop: 2,
            paddingLeft: 2,
          }}
        >
          Sipariş Özeti
        </Typography>

        {Object.keys(groupedOrders || {})?.map((vendor) => {
          const orderTotal = Object.keys(
            groupedOrders[vendor]?.products || {}
          ).reduce((acc, next) => {
            return (
              acc +
              groupedOrders[vendor]?.products[next].orderCount *
                groupedOrders[vendor]?.products[next].price
            );
          }, 0);

          return (
            <Box
              key={vendor}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingY: "0.6rem",
                paddingX: 2,
                borderBottom: "1px solid #dfdeda",
              }}
            >
              <Typography
                sx={{
                  // ellipsis
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "70%",
                }}
              >
                {vendor}
              </Typography>
              <Typography
                sx={
                  {
                    // paddingTop: 2,
                    // paddingLeft: 2,
                  }
                }
              >
                {createCurrency(orderTotal).format()}
              </Typography>
            </Box>
          );
        })}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingY: "0.6rem",
            paddingX: 2,
            backgroundColor: "#ddeeff",
            borderRadius: "0 0 16px 16px",
          }}
        >
          <Typography
            sx={{
              // ellipsis
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "70%",
              fontWeight: "bold",
            }}
          >
            Toplam
          </Typography>
          <Typography
            sx={{
              // paddingTop: 2,
              // paddingLeft: 2,
              fontWeight: "bold",
            }}
          >
            {createCurrency(orderTotal).format()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
