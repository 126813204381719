import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const footerSections = [
    {
      title: t("footer.company"),
      links: [
        { title: t("footer.about"), href: "/request-demo" },
        { title: t("footer.contact"), href: "/request-demo" },
        { title: t("footer.careers"), href: "/request-demo" },
      ],
    },
    {
      title: t("footer.support"),
      links: [
        { title: t("footer.faq"), href: "/request-demo" },
        { title: t("footer.supportLink"), href: "/request-demo" },
      ],
    },
    {
      title: t("footer.legal"),
      links: [
        { title: t("footer.privacyPolicy"), href: "/privacy-policy" },
        { title: t("footer.termsOfService"), href: "/privacy-policy" },
        { title: t("footer.siteMap"), href: "/site-map" },
      ],
    },
  ];

  const socialLinks = [
    { icon: <Facebook />, href: "https://facebook.com/tedaarik" },
    { icon: <Twitter />, href: "https://twitter.com/tedaarik" },
    { icon: <LinkedIn />, href: "https://linkedin.com/company/tedaarik" },
    { icon: <Instagram />, href: "https://instagram.com/tedaarik" },
  ];

  return (
    <Box
      component="footer"
      sx={{
        // background: "red",
        background: "linear-gradient(to bottom,#F2F2F2,black)",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "flex-end",
          position: "absolute",
          bottom: "0",
          marginBottom: "1vh",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="gray" gutterBottom>
              Tedaarik
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#6b6b6b",
              }}
            >
              {t("footer.copyright")}
            </Typography>
          </Grid>
          {footerSections.map((section, index) => (
            <Grid item xs={12} sm={6} md={2} key={index}>
              <Typography variant="h6" color="gray" gutterBottom>
                {section.title}
              </Typography>
              <Box>
                {section.links.map((link, linkIndex) => (
                  <Link
                    key={linkIndex}
                    href={link.href}
                    color="#6b6b6b"
                    display="block"
                    mb={1}
                  >
                    {link.title}
                  </Link>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="gray" gutterBottom>
              {t("footer.socialMedia")}
            </Typography>
            <Box>
              {socialLinks.map((link, index) => (
                <Link
                  key={index}
                  href={link.href}
                  color="#6b6b6b"
                  display="inline-block"
                  mr={2}
                >
                  {link.icon}
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
