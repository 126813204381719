import { Avatar, Box, Chip, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useAppContext, useInvoiceContext } from "../../context";

import { Notifications } from "../../components";
import uniqolor from "uniqolor";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DesktopNotifications() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user.userDoc);

  const [isOpen, setIsOpen] = useState(false);
  const { notifications } = useAppContext();
  const { updateNotifications } = useInvoiceContext();

  const toggleNotifications = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => updateNotifications(), 3000);
    }
  };

  if (
    [
      "/assistant",
      "/privacy-policy",
      "/site-map",
      "/request-demo",
      "/sign-up",
      "/login",
      "/data-extraction",
      "/collaborative-ordering",
      "/",
    ]?.includes(pathname)
  ) {
    return;
  }
  if (pathname.includes("/invoices/")) {
    return;
  }
  if (pathname.includes("/blog/")) {
    return;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        right: "0.5rem",
        bottom: 0,
        zIndex: 9999,
        backgroundColor: "white",
        boxShadow: "0 0 10px rgba(0,0,0,.1)",
        borderRadius: "1rem   1rem 0 0 ",
        width: "360px",
        overflow: "hidden",
        maxHeight: "calc(100vh - 2rem)",
        overflowY: "auto",
        display: {
          xs: "none",
          md: "none",
          lg: "block",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            borderBottom: "1px solid rgba(0,0,0,.1)",
            padding: "0.6rem",
            backgroundColor:
              notifications?.filter((n) => n?.notified === false).length > 0
                ? "#0b66c2"
                : "white",
          }}
          onClick={toggleNotifications} // Bind toggle function to onClick event
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: `${
                  uniqolor(user?.displayName || user?.email).color
                }`,
                height: "2.4rem",
                width: "2.4rem",
              }}
              src={user?.photoURL}
            >
              {user?.email?.slice(0, 1)?.toUpperCase()}
            </Avatar>
            <Typography
              sx={{
                paddingLeft: "0.8rem",
                fontWeight: "bold",
                fontSize: "1rem",
                color: notifications?.filter((n) => n?.notified === false)
                  .length
                  ? "white"
                  : "black",
              }}
            >
              {t("notifications")}
            </Typography>
            <Chip
              size="small"
              label={notifications?.filter((n) => n?.notified === false).length}
              sx={{
                display:
                  notifications?.filter((n) => n?.notified === false).length > 0
                    ? "flex"
                    : "none",
                backgroundColor: "red",
                color: "white",
                fontWeight: "bold",
                marginLeft: "0.5rem",
                border: "1px solid white",
              }}
            />
          </Box>
          {isOpen ? (
            <KeyboardArrowDown
              sx={{
                color:
                  notifications?.filter((n) => n?.notified === false).length > 0
                    ? "white"
                    : "black",
              }}
            />
          ) : (
            <KeyboardArrowUp
              sx={{
                color:
                  notifications?.filter((n) => n?.notified === false).length > 0
                    ? "white"
                    : "black",
              }}
            />
          )}
        </Box>
      </Box>

      <Notifications isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
}
