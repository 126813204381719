import {
  DesktopNavigation,
  OverviewProductHelperPage,
  PageHeader,
  ProductInvoiceWidget,
  ProductWidget,
  Tabs,
} from "../../components";
import { useCallback, useState } from "react";

import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import fetchProductPurchases from "../../scripts/purchases/fetchProductPurchases";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Details = ({ tab, filters, loading, productPurchases, name }) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
        }}
      >
        <CircularProgress size={24} sx={{ color: "#1b1b1b" }} />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <ProductWidget
          product={{
            ...productPurchases,
            productName: name,
            price:
              productPurchases?.priceHistory[
                productPurchases?.priceHistory?.length - 1
              ] || 0,
          }}
          history={productPurchases}
        />
      </Box>
    </Box>
  );
};
const Procurement = ({ filters, purchases }) => {
  const navigate = useNavigate();

  const compare = (a, b, key, order) => {
    if (a[key] < b[key]) return order === "asc" ? -1 : 1;
    if (a[key] > b[key]) return order === "asc" ? 1 : -1;
    return 0;
  };

  const sortPurchases = (purchasesToSort) => {
    return [...purchasesToSort].sort((a, b) => {
      switch (filters.activeKey) {
        case "sortByTax":
          return compare(a, b, "tax", filters.sortByTax);
        case "sortByDiscount":
          return compare(a, b, "discount", filters.sortByDiscount);
        case "sortByTotal":
          return compare(a, b, "total", filters.sortByTotal);
        case "sortByDate":
          return compare(
            { date: new Date(a.date.seconds * 1000) },
            { date: new Date(b.date.seconds * 1000) },
            "date",
            filters.sortByDate
          );
        case "sortByName":
        default:
          return compare(
            { productName: a?.productName || "" },
            { productName: b?.productName || "" },
            "productName",
            filters.sortByName
          );
      }
    });
  };

  const sortedPurchases = sortPurchases(purchases);

  return (
    <Box>
      {sortedPurchases.map((purchase, index) => (
        <Box
          key={index}
          onClick={() => navigate(`/invoices/${purchase.invoiceId}`)}
        >
          <ProductInvoiceWidget
            title={true}
            product={{
              ...purchase,
              lastPrice: purchase?.price,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default function OverviewProduct() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userDoc);
  const dispatch = useDispatch();
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const { name } = location.state || {};
  const { dateRange } = useSelector((state) => state.dateRange);
  const [loading, setLoading] = useState(false);
  const [productPurchases, setProductPurchases] = useState();
  const [purchases, setPurchases] = useState([]);

  const [filters, setFilters] = useState({
    activeKey: "sortByName",
    sortByName: "asc",
    sortByDiscount: "asc",
    sortByTax: "asc",
    sortByTotal: "asc",
    sortByDate: "asc",
    filterByDate: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        console.log({ dateRange });
        const { purchases } = await fetchProductPurchases({
          product: name,
          user,
          filterByDate: filters?.filterByDate,
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
        });
        console.log({ purchases });
        setPurchases(purchases);
      } catch (error) {
        console.error("Error fetching product purchases:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchAggregatePurchasesByProductList();
  }, [user, dispatch, name, filters?.filterByDate, dateRange]);

  const fetchAggregatePurchasesByProductList = useCallback(async () => {
    try {
      const getAggregatePurchasesByProductList = httpsCallable(
        functions,
        "aggregatePurchasesByProductList"
      );
      const response = await getAggregatePurchasesByProductList({
        customerId: user?.companyId,
        userUid: user?.uid,
        products: [name],
        startDate: Math.floor(new Date(dateRange.startDate).getTime() / 1000),
        endDate: Math.floor(new Date(dateRange.endDate).getTime() / 1000),
        filterByDate: filters?.filterByDate,
      });
      console.log(response?.data?.purchaseAggregates);
      setProductPurchases(response?.data?.purchaseAggregates?.[name]);
    } catch (error) {
      console.error("Error fetching aggregate purchases:", error);
    }
  }, [user, dateRange, filters]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: {
            lg: "40%",
            md: "60%",
            sm: "100%",
            xs: "100%",
          },
          borderLeft: {
            lg: "1px solid lightgray",
            md: "1px solid lightgray",
            sm: "none",
            xs: "none",
          },
          borderRight: {
            lg: "1px solid lightgray",
            md: "1px solid lightgray",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Box>
          <PageHeader title={name} loading={loading} />

          <Tabs
            tab={tab}
            setTab={setTab}
            tabs={[
              {
                id: 0,
                label: t("details"),
                children: (
                  <Details
                    tab={tab}
                    filters={filters}
                    loading={loading}
                    productPurchases={productPurchases}
                    name={name}
                  />
                ),
              },
              {
                id: 1,
                label: t("invoices"),
                children: (
                  <Procurement
                    tab={tab}
                    filters={filters}
                    purchases={purchases}
                  />
                ),
              },
            ]}
          />
        </Box>
      </Box>
      <OverviewProductHelperPage
        filters={filters}
        filteredCompanies={[]}
        setFilterByDate={() =>
          setFilters({ ...filters, filterByDate: !filters.filterByDate })
        }
        setSortByName={() =>
          setFilters({
            ...filters,
            activeKey: "sortByName",
            sortByName: filters.sortByName === "asc" ? "desc" : "asc",
          })
        }
        setSortByTax={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByTax",
            sortByTax: filters.sortByTax === "asc" ? "desc" : "asc",
          })
        }
        setSortByDiscount={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByDiscount",
            sortByDiscount: filters.sortByDiscount === "asc" ? "desc" : "asc",
          })
        }
        setSortByTotal={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByTotal",
            sortByTotal: filters.sortByTotal === "asc" ? "desc" : "asc",
          })
        }
        setSortByDate={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByDate",
            sortByDate: filters.sortByDate === "asc" ? "desc" : "asc",
          })
        }
        t={t}
        tab={tab}
      />
    </Box>
  );
}
