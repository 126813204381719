import * as React from "react";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();
export default function FreeSoloCreateOption({
  value,
  options,
  onBlur,
  icon,
  onChange,
}) {
  return (
    <Autocomplete
      sx={{ padding: 0, border: "none" }}
      value={value}
      onBlur={onBlur}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options?.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        if (option && option.title) {
          return option.title;
        }
        return "";
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          onChange={onChange}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
            style: {
              border: "none",
              fontSize: "0.8rem",
            },
          }}
        />
      )}
    />
  );
}
