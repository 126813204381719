import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  products: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      const { id, primary } = action.payload;
      const index = state.companies.findIndex((company) => company.id === id);

      if (index !== -1) {
        state.companies = state.companies.filter(
          (company) => company.id !== id
        );
      } else {
        state.companies.push({ id, primary });
      }
    },
    setProducts: (state, action) => {
      const { id, primary } = action.payload;
      const index = state.products.findIndex((product) => product.id === id);

      if (index !== -1) {
        state.products = state.products.filter((product) => product.id !== id);
      } else {
        state.products.push({ id, primary });
      }
    },
  },
});

export const { setCompanies, setProducts } = filterSlice.actions;

export default filterSlice.reducer;
