import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 1,
  filters: {
    activeKey: "sortByName",
    sortByCount: "desc",
    sortByName: "asc",
    sortByTax: "asc",
    sortByDiscount: "asc",
    sortByTotal: "asc",
    sortByDate: "asc",
    onlyMyProducts: false,
    onlyMyVendors: false,
    filterByDate: true,
  },
  categories: [],
  filteredCompanies: [],
};

const overViewSlice = createSlice({
  name: "overView",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setSortByCount: (state, action) => {
      state.filters.sortByCount = action.payload;
    },
    setSortByName: (state, action) => {
      state.filters.sortByName = action.payload;
    },
    setOnlyMyProducts: (state, action) => {
      state.filters.onlyMyProducts = action.payload;
    },
    setOnlyMyVendors: (state, action) => {
      state.filters.onlyMyVendors = action.payload;
    },
    setFilterByDate: (state, action) => {
      state.filters.filterByDate = action.payload;
    },
    setFilteredCompanies: (state, action) => {
      state.filteredCompanies = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setSortByTax: (state, action) => {
      state.filters.sortByTax = action.payload;
    },
    setSortByDiscount: (state, action) => {
      state.filters.sortByDiscount = action.payload;
    },
    setSortByTotal: (state, action) => {
      state.filters.sortByTotal = action.payload;
    },
    setActiveKey: (state, action) => {
      state.filters.activeKey = action.payload;
    },
    setSortByDate: (state, action) => {
      state.filters.sortByDate = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const {
  setFilters,
  setSortByCount,
  setSortByName,
  setOnlyMyProducts,
  setOnlyMyVendors,
  setFilterByDate,
  setFilteredCompanies,
  setTab,
  setSortByTax,
  setSortByDiscount,
  setSortByTotal,
  setActiveKey,
  setSortByDate,
  setCategories,
} = overViewSlice.actions;

export default overViewSlice.reducer;
