import {
  AlternateEmail as AlternateEmailIcon,
  Badge as BadgeIcon,
  Business as BusinessIcon,
  ImportContacts as ImportContactsIcon,
  LocalPhone as LocalPhoneIcon,
  MyLocation,
  Numbers as NumbersIcon,
} from "@mui/icons-material";

import { Box } from "@mui/material";
import { Creatable } from "../../../components";
import Input from "../../Input";
import { capitalizeFirstLetters } from "../../../utils";
import { handleTaxNoOnBlur } from "../utils";

const hideOnMobile = { display: { xs: "none", md: "flex" } };
export default function VendorCard({
  fields,
  setInvoice,
  companies,
  fetchCompanies,
  admin,
  t,
}) {
  return (
    <Box onClick={fetchCompanies}>
      <Creatable
        value={fields?.VendorName?.value || ""}
        options={Object.values(companies)?.map((c) => {
          return { title: c.name, inputValue: c.name };
        })}
        onBlur={(e) => {
          const company = Object.values(companies).find(
            (c) => c.name === e.target.value
          );

          handleTaxNoOnBlur({
            id: company?.id.replace(/\s/g, "") || "",
            role: "Vendor",
            setInvoice,
          });
        }}
        icon={<BusinessIcon />}
      />

      <Input
        titleIcon={<BadgeIcon />}
        placeholder={t("taxOffice")}
        value={fields?.VendorTaxOffice?.content || ""}
        sx={hideOnMobile}
        multiline={true}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              VendorTaxOffice: {
                ...prev.fields.VendorTaxOffice,
                confidence: 1,
                content: e.target.value,
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              VendorTaxOffice: {
                ...prev.fields.VendorTaxOffice,
                confidence: 1,
                content: capitalizeFirstLetters(e.target.value),
              },
            },
          }))
        }
      />

      <Input
        placeholder={t("taxId")}
        sx={{ paddingBottom: "1rem" }}
        titleIcon={<NumbersIcon />}
        value={fields?.VendorTaxId?.value || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              VendorTaxId: {
                ...prev.fields.VendorTaxId,
                confidence: 1,
                value: e.target.value,
              },
            },
          }))
        }
        onBlur={(e) => {
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              VendorTaxId: {
                ...prev.fields.VendorTaxId,
                confidence: 1,
                value: e.target.value.replace(/\s/g, ""),
              },
            },
          }));
          handleTaxNoOnBlur({
            id: e.target.value.replace(/\s/g, ""),
            role: "Vendor",
            setInvoice,
          });
        }}
      />
      {admin && (
        <>
          <Input
            placeholder={t("phoneNumber")}
            titleIcon={<LocalPhoneIcon />}
            sx={hideOnMobile}
            value={fields?.VendorPhone?.content || ""}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  VendorPhone: {
                    ...prev.fields.VendorPhone,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  VendorPhone: {
                    ...prev.fields.VendorPhone,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
          />
          <Input
            placeholder={t("email")}
            sx={hideOnMobile}
            titleIcon={<AlternateEmailIcon />}
            value={fields?.VendorMail?.content || ""}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  VendorMail: {
                    ...prev.fields.VendorMail,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  VendorMail: {
                    ...prev.fields.VendorMail,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
          />
          <Input
            placeholder={t("address")}
            titleIcon={<ImportContactsIcon />}
            sx={hideOnMobile}
            multiline={true}
            value={fields?.VendorAddress?.content || ""}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  VendorAddress: {
                    ...prev.fields.VendorAddress,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  VendorAddress: {
                    ...prev.fields.VendorAddress,
                    confidence: 1,
                    content: capitalizeFirstLetters(e.target.value),
                  },
                },
              }))
            }
          />
          {/* invoice?.fields?.CustomerLocation?.content */}
          <Input
            placeholder={t("location")}
            titleIcon={<MyLocation />}
            sx={hideOnMobile}
            multiline={true}
            value={fields?.Location?.content || ""}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  Location: {
                    ...prev.fields.Location,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  Location: {
                    ...prev.fields.Location,
                    confidence: 1,
                    content: capitalizeFirstLetters(e.target.value),
                  },
                },
              }))
            }
          />
        </>
      )}
    </Box>
  );
}
