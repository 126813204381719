import { Notifications, UploadProcess } from "../components";

import { Box } from "@mui/material";
import { useEffect } from "react";
import { useInvoiceContext } from "../context";

export default function NotificationsPage() {

  const { setNotificationLimit, updateNotifications } = useInvoiceContext();
  useEffect(() => {
    setNotificationLimit(20);
    setTimeout(() => updateNotifications(), 5000);
  }, []);



  return (
    <Box>
      <UploadProcess />
      <Notifications isOpen={true} setIsOpen={() => {}} height="88vh" />;
    </Box>
  );
}
