import { db } from "../../firebase";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function fetchInvoices({
  user,
  startDate,
  endDate,
  filterByDate,
}) {
  try {
    const getCompanyInvoices = httpsCallable(functions, "fetchCompanyInvoices");

    const response = await getCompanyInvoices({
      user,
      endDate,
      startDate,
      filterByDate,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch invoices:", error);
  }
}
