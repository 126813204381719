import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import { Input, ProductDialog } from "../components";
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, functions } from "../firebase";
import { useEffect, useState } from "react";

import algoliasearch from "algoliasearch/lite";
import { httpsCallable } from "firebase/functions";

export default function Products() {
  const [products, setProducts] = useState();
  const [invoices, setInvoices] = useState();
  const [undefinedProducts, setUndefinedProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    getFailedInvoices();
    getUndefinedProducts();
  }, []);

  async function updateField(id, value, field) {
    try {
      const productRef = doc(db, "products", id);
      await updateDoc(productRef, {
        [field]: value,
        updatedAt: new Date().toISOString(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleBlurProductName = (id, value, key) => {
    const trimmedValue = value.trim();
    updateField(id, trimmedValue, key);
  };
  const handleBlurUnitWeight = (id, value, key) => {
    const trimmedValue = value.trim();
    updateField(id, trimmedValue, key);
  };
  const handleBlurUnit = (id, value, key) => {
    const trimmedValue = value.trim();
    updateField(id, trimmedValue, key);
  };

  const handleBlurGenericName = (id, value, key) => {
    const trimmedValue = value.trim();
    updateField(id, trimmedValue, key);
  };

  async function getUndefinedProducts() {
    try {
      const fetchProducts = httpsCallable(functions, "getUndefinedProducts");

      const response = await fetchProducts();
      console.log(response.data.products);
      setUndefinedProducts(response.data.products);
    } catch (error) {
      console.error(error);
    }
  }
  const filterProducts = (e) => {
    if (e.target.value.length > 2) {
      algoliasearch("LM6U4D5EAY", "17c93ab5b4cc3b5ac604fd089467ccf1")
        .initIndex("products")
        .search(e.target.value, {
          hitsPerPage: 100,
          typoTolerance: false,
        })
        .then(({ hits }) => {
          let object = {};
          hits.map((p) => {
            object[p.objectID] = {
              ...p,
            };
          });
          setProducts(object);
        });
    }
  };

  async function getFailedInvoices() {
    try {
      const invoicesRef = collection(db, "invoices");

      const pendingQuery = query(
        invoicesRef,
        where("calculationError", "==", true)
      );
      const failedInvoices = await getDocs(pendingQuery);

      let invoices = [];
      failedInvoices.docs.forEach((doc) => {
        invoices.push(doc.id);
      });

      setInvoices(invoices);
    } catch (error) {
      console.error("Error fetching failed invoices:", error);
    }
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField fullWidth onChange={filterProducts} />
      </Grid>
      <Grid item xs={3}>
        <Typography>name</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>genericName</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>brandName</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>productName</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography>unitWeight</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography>unit</Typography>
      </Grid>

      {Object.keys(products || {}).map((key) => {
        const product = products[key];
        return (
          <Grid container key={product.objectID}>
            <Grid item xs={3}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                {product.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Creatable
              value={product?.genericName}
              options={genericNameOptions}
              onBlur={(e) =>
                handleBlurGenericName(
                  product?.id,
                  e?.target?.value,
                  "genericName"
                )
              }
            /> */}
              <Input
                value={product?.genericName}
                onChange={(e) => {
                  setProducts({
                    ...products,
                    [product?.objectID]: {
                      ...product,
                      genericName: e.target.value,
                    },
                  });
                }}
                onBlur={(e) =>
                  handleBlurGenericName(
                    product?.objectID,
                    e?.target?.value,
                    "genericName"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                value={product?.brandName}
                onChange={(e) => {
                  setProducts({
                    ...products,
                    [product?.objectID]: {
                      ...product,
                      brandName: e.target.value,
                    },
                  });
                }}
                onBlur={(e) =>
                  handleBlurProductName(
                    product?.objectID,
                    e?.target?.value,
                    "brandName"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                value={product?.productName}
                onChange={(e) => {
                  setProducts({
                    ...products,
                    [product?.objectID]: {
                      ...product,
                      productName: e.target.value,
                    },
                  });
                }}
                onBlur={(e) =>
                  handleBlurProductName(
                    product?.objectID,
                    e?.target?.value,
                    "productName"
                  )
                }
              />
            </Grid>

            <Grid item xs={1}>
              <Input
                value={product?.unitWeight}
                onChange={(e) => {
                  setProducts({
                    ...products,
                    [product?.objectID]: {
                      ...product,
                      unitWeight: e.target.value,
                    },
                  });
                }}
                onBlur={(e) =>
                  handleBlurUnitWeight(
                    product?.objectID,
                    e?.target?.value,
                    "unitWeight"
                  )
                }
              />
            </Grid>
            <Grid item xs={1}>
              <Input
                value={product?.unit}
                onChange={(e) => {
                  setProducts({
                    ...products,
                    [product?.objectID]: {
                      ...product,
                      unit: e.target.value,
                    },
                  });
                }}
                onBlur={(e) =>
                  handleBlurUnit(product?.objectID, e?.target?.value, "unit")
                }
              />
            </Grid>
          </Grid>
        );
      })}

      {invoices?.map((i) => {
        return (
          <Grid item xs={12} key={i}>
            <Link href={`/invoices/${i}`} key={i}>
              {i}
            </Link>
          </Grid>
        );
      })}
      {undefinedProducts?.map((i, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Button onClick={() => setSelectedProduct(i)}>
              {index + 1} - {i.name}
            </Button>
          </Grid>
        );
      })}
      <ProductDialog
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />
    </Grid>
  );
}
