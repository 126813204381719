import * as React from "react";

import BottomNavigation from "./BottomNavigation";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function PersistentDrawerLeft({ children }) {
  const { pathname } = useLocation();

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
      {pathname !== "/" &&
        pathname !== "/assistant" &&
        pathname !== "/privacy-policy" &&
        !pathname.includes("/blog") && <BottomNavigation />}
    </Box>
  );
}
