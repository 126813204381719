import { Box, Button, Chip, Link } from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { checkCompanyDetails, deepCleanObject } from "../utils";

import { db } from "../../../firebase";
import { enqueueSnackbar } from "notistack";
import { getInvoiceStatus } from "../../../utils";
import { useAppContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function InvoiceActions({ invoice, getInvoiceById, id }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userDoc);

  const { setLoading } = useAppContext();
  const navigate = useNavigate();
  const admin = user?.role === "Admin";

  async function rejectInvoice(id) {
    try {
      const invoiceRef = doc(db, "cleanedInvoiceData", id);
      await updateDoc(invoiceRef, { status: "rejected" });
    } catch (error) {
      console.error(error);
    } finally {
      await getInvoiceById(invoice?.id);
    }
  }

  async function deleteInvoice(id) {
    try {
      const invoiceRef = doc(db, "cleanedInvoiceData", id);
      if (admin) {
        await deleteDoc(invoiceRef);
      } else {
        await updateDoc(invoiceRef, { status: "deleted" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      navigate(-1);
      enqueueSnackbar("Fatura silindi", { variant: "success" });
    }
  }

  async function updateInvoice(invoice) {
    try {
      const clean = deepCleanObject(invoice);
      setLoading(true);
      if (user?.role === "Admin") {
        checkCompanyDetails(invoice);
      }
      const invoiceRef = doc(db, "cleanedInvoiceData", invoice?.id);
      await updateDoc(invoiceRef, {
        ...clean,
        status: user?.role === "Admin" ? "admin-approved" : "user-approved",
      });
      enqueueSnackbar(t("invoiceChangesSaved"), {
        variant: "info",
      });

      await addDoc(collection(db, "notifications"), {
        message: "invoiceAdminApproved",
        userUid: invoice?.userUid,
        timestamp: serverTimestamp(),
        notified: false,
        invoiceId: invoice?.id,
        vendor: invoice?.fields?.VendorName?.value,
      });
    } catch (error) {
      console.error(error);
      if (invoice?.status === "admin-approved") {
        enqueueSnackbar("invoiceAdminApproved", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("invoiceChangesNotSaved", {
          variant: "error",
        });
      }
    } finally {
      await getInvoiceById(invoice?.id);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
      }}
    >
      <Link href={invoice?.imgUrl}>
        <Chip
          label={getInvoiceStatus(invoice?.status)?.status}
          color={getInvoiceStatus(invoice?.status)?.color}
        ></Chip>
      </Link>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => rejectInvoice(invoice?.id)}
          sx={{
            display: admin ? "block" : "none",
            textTransform: "none",
            borderRadius: "2rem",
            marginRight: "0.4rem",
          }}
          color="error"
        >
          {t("reject")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => deleteInvoice(id)}
          sx={{
            textTransform: "none",
            borderRadius: "2rem",
            marginRight: "0.4rem",
          }}
          color="error"
        >
          {t("delete")}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            borderRadius: "2rem",
          }}
          onClick={() => updateInvoice(invoice)}
        >
          {t("confirm")}
        </Button>
      </Box>
    </Box>
  );
}
