import { Avatar, Box, Typography } from "@mui/material";
import {
  DesktopNavigation,
  Employees,
  InputGroup,
  PageHeader,
} from "../components";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import { db } from "../firebase";
import uniqolor from "uniqolor";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function CompanyPage() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userDoc);

  const { id } = useParams();
  const [company, setCompany] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      const unsubscribe = getCompany(id);
      return () => unsubscribe && unsubscribe();
    }
  }, [id]);

  function getCompany(id) {
    const companyDocRef = doc(db, "companies", id);
    const unsubscribe = onSnapshot(
      companyDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setCompany(docSnap.data());
        } else {
          console.error("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching company data:", error);
      }
    );

    return unsubscribe;
  }

  async function handleUpdateProfile(key, value) {
    try {
      setLoading(true);
      const companyDocRef = doc(db, "companies", id);
      await setDoc(companyDocRef, { [key]: value }, { merge: true });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const inputItems = [
    {
      label: t("companyName"),
      key: "name",
      value: company.name || "",
      iconLeft: <BusinessIcon style={{ color: "#ccc" }} />,
    },
    {
      label: t("phoneNumber"),
      key: "phoneNumber",
      value: company.phoneNumber || "",
      iconLeft: <PhoneIcon style={{ color: "#ccc" }} />,
      keyboardType: "tel",
    },
    {
      label: t("email"),
      key: "email",
      value: company.email || "",
      iconLeft: <MailIcon style={{ color: "#ccc" }} />,
      keyboardType: "email",
    },
    {
      label: t("taxId"),
      key: "companyId",
      value: company.companyId || "",
      iconLeft: <ConfirmationNumberIcon style={{ color: "#ccc" }} />,
    },
    {
      label: t("country"),
      key: "country",
      value: company.country || "",
      iconLeft: <PublicIcon style={{ color: "#ccc" }} />,
    },
    {
      label: t("city"),
      key: "city",
      value: company.city || "",
      iconLeft: <LocationCityIcon style={{ color: "#ccc" }} />,
    },
    {
      label: t("address"),
      key: "address",
      value: company.address || "",
      iconLeft: <HomeIcon style={{ color: "#ccc" }} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        background: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: { lg: "76%", md: "100%", sm: "100%", xs: "100%" },
          borderLeft: "1px solid lightgray",
          borderRight: "1px solid lightgray",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          position: "relative",
          paddingBottom: 16,
        }}
      >
        <PageHeader
          title={t("companyProfile")}
          hideBackButton={false}
          loading={loading}
        />
        <Avatar
          sx={{
            backgroundColor: `${uniqolor(user?.displayName).color}`,
            width: "6rem",
            height: "6rem",
            fontSize: "5rem",
            border: "5px solid white",
            marginY: "2rem",
          }}
          src={company?.logoUrl || ""}
        >
          {user?.displayName?.slice(0, 1)?.toUpperCase()}
        </Avatar>
        <Box
          sx={{
            width: {
              lg: "50%",
              md: "60%",
              sm: "96%",
              xs: "96%",
            },

            margin: "0 auto",
          }}
        >
          <InputGroup
            items={inputItems}
            onChangeText={(key, value) => {
              setCompany({ ...company, [key]: value });
            }}
            onBlur={(key, value) => {
              handleUpdateProfile(key, value);
            }}
            onPressRightIcon={() => {}}
          />
          <Employees company={company} />
        </Box>
      </Box>
    </Box>
  );
}
