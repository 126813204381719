import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDoc: null,
  userId: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserDoc: (state, action) => {
      state.userDoc = action.payload;
    },
    clearUser: (state) => {
      state.userDoc = null;
      state.userId = null;
    },
  },
});

export const { setUserDoc, setUserId, clearUser } = userSlice.actions;
export default userSlice.reducer;
