import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import { Box, Chip, Collapse, Typography } from "@mui/material";
import { DateRangePicker, IosSwitch, Search } from "..";
import React, { useEffect, useState } from "react";

import algoliasearch from "algoliasearch/lite";
import { formatDate } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OverviewCompanyHelperPage = ({
  filters,
  setSortByName,
  t,
  setFilterByDate,
  setSortByTax,
  setSortByDiscount,
  setSortByTotal,
  setSortByDate,
  tab,
  categories,
  setFilteredCompanies,
  filteredCompanies,
}) => {
  const { dateRange } = useSelector((state) => state.dateRange);

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDoc);

  const searchClient = algoliasearch(
    "LM6U4D5EAY",
    "1fda51e86437cff7f75b96e001b71da7"
  );
  const index = searchClient.initIndex("purchases");

  useEffect(() => {
    if (query.length > 1) {
      searchPurchases(query);
    } else {
      setSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const searchPurchases = async (search) => {
    try {
      const searchParams = {
        hitsPerPage: 150,
        attributesToHighlight: [
          "vendorName",
          "invoiceId",
          "productName",
          // "subCategory",
          // "category",
        ],
      };
      if (user?.companyId) {
        searchParams.filters = `customerId:${user?.companyId}`;
      } else {
        searchParams.filters = `customerId:${user?.uid}`;
      }

      const { hits } = await index.search(search, searchParams);
      setSuggestions(processHits(hits));
    } catch (err) {
      console.error("Algolia search error:", err);
    }
  };

  const processHits = (hits) => {
    const uniqueHighlights = {};

    hits.forEach((hit) => {
      Object.keys(hit._highlightResult).forEach((key) => {
        const highlight = hit._highlightResult[key];

        if (highlight.matchLevel !== "none") {
          const cleanValue = highlight.value.replace(/<\/?em>/g, "");
          if (!uniqueHighlights[cleanValue]) {
            uniqueHighlights[cleanValue] = {
              key,
              highlight,
              vendorId: hit.vendorId,
              hit,
            };
          }
        }
      });
    });
    return Object.values(uniqueHighlights);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        width: {
          lg: "30%",
          md: "40%",
        },
        display: {
          lg: "block",
          md: "block",
          xs: "none",
          sm: "none",
        },
        top: -1,
      }}
    >
      <Search
        onSearch={(e) => setQuery(e)}
        items={suggestions}
        algolia={true}
        placeholder={"overviewSearchPlaceHolder"}
        onClick={(suggestion) => {
          if (suggestion.key === "vendorName") {
            navigate(`/overview/company/${suggestion?.cleanValue}`, {
              state: {
                name: suggestion?.cleanValue,
                taxid: suggestion.vendorId,
              },
            });
          }
          if (suggestion.key === "productName") {
            navigate(`/overview/product/${suggestion?.cleanValue}`, {
              state: { name: suggestion?.cleanValue },
            });
          }
        }}
      />
      <Box
        sx={{
          margin: "1rem",
          marginTop: "0.4rem",
          padding: "0.8rem",
          borderRadius: "15px",
          border: "1px solid lightgray",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "1.2rem", marginBottom: "1rem" }}
        >
          {t("filter")}
        </Typography>
        <Chip
          sx={{ marginBottom: 1, marginRight: 1 }}
          variant={
            filters?.activeKey === "sortByDiscount" ? "filled" : "outlined"
          }
          color={
            filters?.activeKey === "sortByDiscount" ? "primary" : "default"
          }
          label={
            filters.sortByDiscount === "asc"
              ? t("sortByDiscountAsc")
              : t("sortByDiscountDesc")
          }
          icon={
            filters?.activeKey === "sortByDiscount" ? (
              filters.sortByDiscount === "desc" ? (
                <ArrowDownwardRounded />
              ) : (
                <ArrowUpwardRounded />
              )
            ) : null
          }
          onClick={() =>
            setSortByDiscount(filters.sortByDiscount === "asc" ? "desc" : "asc")
          }
        />
        <Chip
          sx={{ marginBottom: 1, marginRight: 1 }}
          variant={filters?.activeKey === "sortByTax" ? "filled" : "outlined"}
          label={
            filters.sortByTax === "asc" ? t("sortByTaxAsc") : t("sortByTaxDesc")
          }
          color={filters?.activeKey === "sortByTax" ? "primary" : "default"}
          icon={
            filters?.activeKey === "sortByTax" ? (
              filters.sortByTax === "desc" ? (
                <ArrowDownwardRounded />
              ) : (
                <ArrowUpwardRounded />
              )
            ) : null
          }
          onClick={() =>
            setSortByTax(filters.sortByTax === "asc" ? "desc" : "asc")
          }
        />
        <Chip
          sx={{ marginBottom: 1, marginRight: 1 }}
          variant={filters?.activeKey === "sortByTotal" ? "filled" : "outlined"}
          color={filters?.activeKey === "sortByTotal" ? "primary" : "default"}
          label={
            filters.sortByTotal === "asc"
              ? t("sortByTotalAsc")
              : t("sortByTotalDesc")
          }
          onClick={() =>
            setSortByTotal(filters.sortByTotal === "asc" ? "desc" : "asc")
          }
          icon={
            filters?.activeKey === "sortByTotal" ? (
              filters.sortByTotal === "desc" ? (
                <ArrowDownwardRounded />
              ) : (
                <ArrowUpwardRounded />
              )
            ) : null
          }
        />
        {tab === 2 && (
          <Chip
            sx={{ marginBottom: 1, marginRight: 1 }}
            variant={
              filters?.activeKey === "sortByDate" ? "filled" : "outlined"
            }
            color={filters?.activeKey === "sortByDate" ? "primary" : "default"}
            label={
              filters.sortByDate === "asc"
                ? t("sortByDateAsc")
                : t("sortByDateDesc")
            }
            onClick={() =>
              setSortByDate(filters.sortByDate === "asc" ? "desc" : "asc")
            }
            icon={
              filters?.activeKey === "sortByDate" ? (
                filters.sortByDate === "desc" ? (
                  <ArrowDownwardRounded />
                ) : (
                  <ArrowUpwardRounded />
                )
              ) : null
            }
          />
        )}

        <IosSwitch
          onClick={() => setFilterByDate(!filters.filterByDate)}
          label={
            filters.filterByDate
              ? t("selectedDateRange", {
                  startDate: formatDate(dateRange.startDate, "dd MMMM"),
                  endDate: formatDate(dateRange.endDate, "dd MMMM"),
                })
              : t("allTimeSelected")
          }
          checked={filters.filterByDate}
        />
      </Box>
      <Collapse in={filters.filterByDate} timeout="auto" unmountOnExit>
        <Box
          sx={{
            margin: "1rem",
            marginTop: "0.4rem",
            borderRadius: "15px",
            border: "1px solid lightgray",
          }}
        >
          <DateRangePicker dateRange={dateRange} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default OverviewCompanyHelperPage;
