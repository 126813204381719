import {
  AttachMoney,
  Inventory,
  LabelImportantRounded,
  LocalOffer,
  PercentOutlined,
  ShoppingCart,
} from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";

import React from "react";
import { createCurrency } from "../../utils";
import { formatDate } from "../../utils";
import { t } from "i18next";
import { useNavigate } from "react-router";

const ListItem = ({ icon, label, value, valueStyle, labelStyle }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      py: 1,
      borderBottom: "1px solid",
      borderColor: "divider",
      "&:last-child": { borderBottom: "none" },
    }}
  >
    {icon}
    <Typography sx={{ ml: 2, flexGrow: 1, ...labelStyle }}>{label}</Typography>
    <Typography sx={valueStyle}>{value}</Typography>
  </Box>
);

const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "md",
        mx: "auto",
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
        borderBottom: "1px solid lightgray",
        paddingY: 1,
      }}
      onClick={() =>
        navigate(`/overview/product/${product?.productName}`, {
          state: { name: product?.productName },
        })
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingX: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar
            variant="rounded"
            // src={product?.imgUrl}
            alt={product.vendorName}
            sx={{ width: 64, height: 64 }}
          >
            {product?.vendorName?.slice(0, 1)}
          </Avatar>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              {product.vendorName}
            </Typography>

            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
              {formatDate(product?.date)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: 2, paddingBottom: 0 }}>
        <Box>
          <ListItem
            icon={<Inventory sx={{ fontSize: 18 }} />}
            label={t("quantity")}
            value={product?.quantity?.toFixed(2) + " " + product?.unit}
            valueStyle={{ fontSize: 14 }}
            labelStyle={{ fontSize: 14 }}
          />
          <ListItem
            icon={<LabelImportantRounded sx={{ fontSize: 18 }} />}
            label={t("unitPrice")}
            value={createCurrency(product?.unitPrice).format()}
            valueStyle={{ fontSize: 14 }}
            labelStyle={{ fontSize: 14 }}
          />
          <ListItem
            icon={<LocalOffer sx={{ fontSize: 18 }} />}
            label={t("subtotal")}
            value={createCurrency(product?.subTotal).format()}
            valueStyle={{ fontSize: 14 }}
            labelStyle={{ fontSize: 14 }}
          />
          <ListItem
            icon={<PercentOutlined sx={{ fontSize: 18 }} />}
            label={t("totalDiscount")}
            value={createCurrency(product?.discount).format()}
            valueStyle={{ color: "red", fontSize: 12 }}
            labelStyle={{ fontSize: 12 }}
          />
          <ListItem
            icon={<AttachMoney sx={{ fontSize: 18 }} />}
            label={t("totalVat")}
            value={createCurrency(product?.tax).format()}
            valueStyle={{ color: "gray", fontSize: 12 }}
            labelStyle={{ fontSize: 12 }}
          />
          <ListItem
            icon={<ShoppingCart sx={{ fontSize: 18 }} />}
            label={t("totalPrice")}
            value={createCurrency(product?.total).format()}
            valueStyle={{ fontWeight: "bold", fontSize: 16 }}
            labelStyle={{ fontSize: 16 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCard;
