import {
  CollaborativeOrdering,
  CompanyManagement,
  Footer,
  InvoiceParser,
  SearchAndFilter,
  Welcome,
} from ".";
import {
  CollaborativeOrderingDialog,
  InvoiceParserDialog,
  LoginDialog,
  RequestDemoDialog,
  SignUpDialog,
} from "../../components";

import React from "react";

const Landing = () => {
  return (
    <>
      <Welcome />
      <InvoiceParser />
      <CompanyManagement />
      <CollaborativeOrdering />
      <SearchAndFilter />
      <Footer />
      <RequestDemoDialog />
      <LoginDialog />
      <SignUpDialog />
      <InvoiceParserDialog />
      <CollaborativeOrderingDialog />
    </>
  );
};

export default Landing;
