import {
  CompanyInvoices,
  CompanyProducts,
  DesktopNavigation,
  OverviewCompanyDetails,
  OverviewCompanyHelperPage,
  PageHeader,
  Tabs,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { fetchVendorInvoices } from "../../scripts/invoices/fetchVendorInvoices";
import { fetchVendorPurchases } from "../../scripts/purchases/fetchVendorPurchases";
import { setCompanyInvoices } from "../../redux/Invoices/invoiceSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OverviewCompany() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { taxid, name } = location.state || {};
  const user = useSelector((state) => state.user.userDoc);
  const { companyInvoices } = useSelector((state) => state.invoices);
  const { dateRange } = useSelector((state) => state.dateRange);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const [filters, setFilters] = useState({
    activeKey: "sortByName",
    sortByName: "asc",
    sortByDiscount: "asc",
    sortByTax: "asc",
    sortByTotal: "asc",
    sortByDate: "asc",
    filterByDate: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !name) return;
      setLoading(true);
      try {
        await fetchVendorPurchases({
          user,
          dispatch,
          vendorName: name,
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
          filterByDate: filters?.filterByDate,
        });
        await fetchVendorInvoices({
          user,
          dispatch,
          vendorName: name,
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
          filterByDate: filters?.filterByDate,
        });
      } catch (error) {
        console.error("Error fetching aggregate invoices:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, dispatch, name, dateRange, filters?.filterByDate]);

  useEffect(() => {
    if (!companyInvoices) return;

    let sortedInvoices = [...companyInvoices];
    const {
      activeKey,
      sortByName,
      sortByDiscount,
      sortByTax,
      sortByTotal,
      sortByDate,
    } = filters;

    if (activeKey === "sortByName") {
      sortedInvoices.sort((a, b) =>
        sortByName === "asc"
          ? a.vendorName.localeCompare(b.vendorName)
          : b.vendorName.localeCompare(a.vendorName)
      );
    } else if (activeKey === "sortByDiscount") {
      sortedInvoices.sort((a, b) =>
        sortByDiscount === "asc"
          ? a.discount - b.discount
          : b.discount - a.discount
      );
    } else if (activeKey === "sortByTax") {
      sortedInvoices.sort((a, b) =>
        sortByTax === "asc" ? a.tax - b.tax : b.tax - a.tax
      );
    } else if (activeKey === "sortByTotal") {
      sortedInvoices.sort((a, b) =>
        sortByTotal === "asc" ? a.total - b.total : b.total - a.total
      );
    } else if (activeKey === "sortByDate") {
      sortedInvoices.sort((a, b) =>
        sortByDate === "asc"
          ? new Date(a.date.seconds * 1000) - new Date(b.date.seconds * 1000)
          : new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000)
      );
    }

    dispatch(setCompanyInvoices(sortedInvoices));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: {
            lg: "40%",
            md: "60%",
            sm: "100%",
            xs: "100%",
          },
          borderLeft: {
            lg: "1px solid lightgray",
            md: "1px solid lightgray",
            sm: "none",
            xs: "none",
          },
          borderRight: {
            lg: "1px solid lightgray",
            md: "1px solid lightgray",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: -1,
            backgroundColor: "white",
            zIndex: 10,
          }}
        >
          <PageHeader title={name} loading={loading} />
          <Tabs
            setTab={setTab}
            tab={tab}
            tabs={[
              {
                id: 0,
                label: t("details"),
                children: <OverviewCompanyDetails loading={loading} />,
              },
              {
                id: 1,
                label: t("products"),
                children: <CompanyProducts filters={filters} />,
              },
              {
                id: 1,
                label: t("invoices"),
                children: <CompanyInvoices taxId={taxid} companyName={name} />,
              },
            ]}
          />
        </Box>
      </Box>
      <OverviewCompanyHelperPage
        filters={filters}
        filteredCompanies={[]}
        setFilterByDate={() =>
          setFilters({ ...filters, filterByDate: !filters.filterByDate })
        }
        setSortByName={() =>
          setFilters({
            ...filters,
            activeKey: "sortByName",
            sortByName: filters.sortByName === "asc" ? "desc" : "asc",
          })
        }
        setSortByTax={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByTax",
            sortByTax: filters.sortByTax === "asc" ? "desc" : "asc",
          })
        }
        setSortByDiscount={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByDiscount",
            sortByDiscount: filters.sortByDiscount === "asc" ? "desc" : "asc",
          })
        }
        setSortByTotal={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByTotal",
            sortByTotal: filters.sortByTotal === "asc" ? "desc" : "asc",
          })
        }
        setSortByDate={(e) =>
          setFilters({
            ...filters,
            activeKey: "sortByDate",
            sortByDate: filters.sortByDate === "asc" ? "desc" : "asc",
          })
        }
        t={t}
        tab={tab}
      />
    </Box>
  );
}
