export function categorizeByProductName(purchases) {
  let list = {};

  purchases.forEach((purchase) => {
    const unitWeight = Number(purchase.unitWeight || 1) || 1;

    if (!list[purchase.productName]) {
      list[purchase.productName] = {
        purchases: [],
        genericName: purchase.genericName,
        productName: purchase.productName,
        lastPrice: purchase?.price || 0,
        lastUnitPrice: purchase?.price / unitWeight || 0,
        lastPuchaseDate: purchase?.date?.seconds || 0,
        priceChange: 0,
        tax: 0,
        subTotal: 0,
        total: 0,
        lowestPrice: purchase?.price / unitWeight || 0,
        lowestUnitPrice: purchase?.price / unitWeight || 0,
        unitWeight: unitWeight,
        calculationError: false,
        productId: purchase.productId,
        discount: 0,
        subCategories: [],
        quantity: 0,
        imgUrl: purchase?.imgUrl,
      };
    }

    list[purchase.productName].purchases.push(purchase);
    list[purchase.productName].tax += purchase?.tax || 0;
    list[purchase.productName].discount += purchase?.discount || 0;
    list[purchase.productName].subTotal += purchase?.subTotal || 0;
    list[purchase.productName].total += purchase?.total || 0;
    list[purchase.productName].quantity += purchase?.quantity || 0;

    if (list[purchase.productName].lowestPrice > purchase?.price / unitWeight) {
      list[purchase.productName].lowestPrice = purchase?.price / unitWeight;
      list[purchase.productName].lowestUnitPrice = purchase?.price / unitWeight;
      list[purchase.productName].lowestPriceDate = purchase?.date?.seconds;
      list[purchase.productName].genericName = purchase.genericName;
      list[purchase.productName].productName = purchase.productName;
      list[purchase.productName].unitWeight = unitWeight;
    }

    list[purchase.productName].subCategories.push(purchase?.subCategory);
    list[purchase.productName].subCategories = [
      ...new Set(list[purchase.productName].subCategories),
    ];

    list[purchase.productName].calculationError =
      list[purchase.productName].calculationError || purchase?.calculationError;

    if (list[purchase.productName].lastPuchaseDate < purchase?.date?.seconds) {
      list[purchase.productName].priceChange = Math.round(
        100 * (1 - list[purchase.productName].lastPrice / purchase?.price)
      );

      list[purchase.productName].lastPrice = purchase?.price;
      list[purchase.productName].lastUnitPrice = purchase?.price / unitWeight;
      list[purchase.productName].lastPuchaseDate = purchase?.date?.seconds;
    }
  });
  return list;
}
