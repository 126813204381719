import "react-datepicker/dist/react-datepicker.css";

import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import DatePicker from "react-datepicker";
import en from "date-fns/locale/en-US";
import { setDate } from "../redux/App/DateRangeSlice";
import tr from "date-fns/locale/tr";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

registerLocale("en", en);
registerLocale("tr", tr);

const DateRangePickerComponent = ({ dateRange }) => {
  const [startDate, setStartDateState] = useState(null);
  const [endDate, setEndDateState] = useState(null);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setStartDateState(new Date(dateRange.startDate));
    setEndDateState(new Date(dateRange.endDate));
  }, [dateRange]);

  useEffect(() => {
    setDefaultLocale(i18n.language);
  }, [i18n.language]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDateState(start);
    setEndDateState(end);

    if (start && end) {
      dispatch(
        setDate({ startDate: start.toISOString(), endDate: end.toISOString() })
      );
    }
  };

  const handlePresetClick = (daysAgo) => {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - daysAgo);
    handleDateChange([start, end]);
  };

  const handleThisMonthClick = () => {
    const start = new Date();
    start.setDate(1);
    const end = new Date();
    handleDateChange([start, end]);
  };

  const handleLastMonthClick = () => {
    const end = new Date();
    end.setDate(1);
    end.setHours(-1); // Go to the last day of the previous month
    const start = new Date(end);
    start.setDate(1); // Set to the first day of the previous month
    handleDateChange([start, end]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        ".react-datepicker": {
          display: "flex",
          justifyContent: "center",
          borderRadius: "15px",
          border: "none",
          width: "100%",
        },
        ".react-datepicker__header": {
          backgroundColor: "white",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          borderBottom: "1px solid lightgray",
        },
        ".react-datepicker__navigation": {
          borderRadius: "15px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRight: "1px solid lightgray",
          padding: "10px",
        }}
      >
        <Button sx={{ textTransform: "none" }} onClick={handleThisMonthClick}>
          {t("thisMonth")}
        </Button>
        <Button sx={{ textTransform: "none" }} onClick={handleLastMonthClick}>
          {t("lastMonth")}
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => handlePresetClick(30)}
        >
          {t("last30days")}
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => handlePresetClick(60)}
        >
          {t("last60days")}
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => handlePresetClick(90)}
        >
          {t("last90days")}
        </Button>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          ".react-datepicker__month-container": {
            width: "100%",
          },
        }}
      >
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          placeholderText={t("selectDateRange")}
          dateFormat="yyyy-MM-dd"
          locale={i18n.language}
        />
      </Box>
    </Box>
  );
};

export default DateRangePickerComponent;
