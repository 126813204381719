import { AnimatePresence, motion } from "framer-motion";
import { ArrowForward, Close, Menu } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import appleStore from "../../assets/appleStore.png";
import collaborativeOrder from "../../assets/collaborative-order.png";
import companyManagement from "../../assets/company-management.png";
import dataExtraction from "../../assets/data-extraction.png";
import exploreVendors from "../../assets/explore-vendors.png";
import fullLogo from "../../assets/full-logo.png";
import googleStore from "../../assets/googleStore.png";
import logo from "../../assets/logo.png";
import logoW from "../../assets/logo-white.png";
import searchFilter from "../../assets/searchFilter.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const numberVariants = {
  enter: () => ({
    x: -100,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: () => ({
    x: 100,
    opacity: 0,
  }),
};

const logos = [
  dataExtraction,
  companyManagement,
  collaborativeOrder,
  searchFilter,
  exploreVendors,
];

const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentLogo, setCurrentLogo] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timer) {
      clearInterval(timer);
    }

    const newTimer = setInterval(() => {
      setCurrentLogo((prevLogo) => (prevLogo + 1) % 5);
    }, 5000);

    setTimer(newTimer);

    return () => clearInterval(newTimer);
  }, [currentLogo]);

  const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };

    const menuItems = [
      { text: t("requestDemo"), path: "/request-demo" },
      { text: t("login"), path: "/login" },
      { text: t("signupfree"), path: "/sign-up" },
    ];

    return (
      <>
        <Box
          sx={{
            padding: 2,
            paddingX: { xs: 2, sm: 4, md: 6, lg: 10 },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "fixed",
            top: 0,
            width: "100%",
            boxSizing: "border-box",
            backdropFilter: "blur(4px)",
            webkitBackdropFilter: "blur(4px)",
            zIndex: 100,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))",
              pointerEvents: "none",
              zIndex: -1,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
              alignContent: "center",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                height: 42,
                marginRight: 2,
              }}
            />
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                },
              }}
            >
              <img
                src={fullLogo}
                alt="Full Logo"
                style={{
                  height: 24,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              sx={{
                borderRadius: 10,
                color: "#2b2b2b",
                textTransform: "none",
              }}
              onClick={() => navigate("/blog")}
            >
              {t("blog")}
            </Button>
            <Button
              sx={{
                borderRadius: 10,
                color: "#2b2b2b",
                textTransform: "none",
              }}
              onClick={() => navigate("/request-demo")}
            >
              {t("requestDemo")}
            </Button>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ marginX: 1 }}
            />
            <Button
              sx={{
                borderRadius: 10,
                color: "#2b2b2b",
                textTransform: "none",
              }}
              onClick={() => navigate("/login")}
            >
              {t("login")}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2b2b2b",
                borderRadius: 10,
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2b2b2b",
                },
              }}
              onClick={() => navigate("/sign-up")}
            >
              {t("signupfree")}
            </Button>
          </Box>
          <IconButton
            sx={{
              display: { xs: "flex", md: "none" },
              color: "#2b2b2b",
            }}
            aria-label="menu"
            onClick={toggleMenu}
          >
            {menuOpen ? <Close /> : <Menu />}
          </IconButton>
        </Box>
        <Collapse
          in={menuOpen}
          sx={{ position: "fixed", top: "64px", width: "100%", zIndex: 99 }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 3,
              width: "100%",
            }}
          >
            <List>
              {menuItems.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(item.path);
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Collapse>
      </>
    );
  };

  return (
    <>
      {/* <SEOHelmet /> */}

      <Box
        sx={{
          background: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",
          backgroundPosition: "center",
          minHeight: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            flexGrow: 1,
            width: {
              lg: "80%",
              md: "90%",
              sm: "96%",
              xs: "96%",
            },
            justifyContent: {
              lg: "center",
              md: "center",
              sm: "space-evenly",
              xs: "space-evenly",
            },
            alignItems: "center",
            position: "relative",
            paddingY: 4,
          }}
        >
          <Box
            sx={{
              width: {
                lg: "50%",
                md: "50%",
                sm: "96%",
                xs: "96%",
              },

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ fontSize: 36, fontWeight: 600, color: "#2b2b2b" }}
            >
              {t("simplifiedProcurementOperations")}
            </Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 600, color: "gray" }}>
              {t("allYourRestaurantOperations")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2b2b2b",
                  alignSelf: "flex-start",
                  borderRadius: 10,
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#2b2b2b",
                  },
                }}
                onClick={() => navigate("/sign-up")}
              >
                {t("signupfree")}
              </Button>
              <Button
                sx={{
                  alignSelf: "flex-start",
                  borderRadius: 10,
                  color: "#2b2b2b",
                  textTransform: "none",
                }}
                endIcon={
                  <ArrowForward sx={{ fontSize: 50, color: "#2b2b2b" }} />
                }
                onClick={() => navigate("/request-demo")}
              >
                {t("requestDemo")}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: {
                lg: "50%",
                md: "50%",
                sm: "96%",
                xs: "96%",
              },
              display: {
                lg: "flex",
                md: "flex",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={currentLogo}
                variants={numberVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={logos[currentLogo]}
                  style={{
                    width: "60%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, margin: 2 }}>
          <IconButton
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.teklifappnative",
                "_blank"
              )
            }
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={googleStore}
              alt="Google Play Store"
              style={{
                height: 40,
                cursor: "pointer",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://apps.apple.com/tr/app/tedaarik/id6504186040",
                "_blank"
              )
            }
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={appleStore}
              alt="Apple App Store"
              style={{
                height: 40,
                cursor: "pointer",
              }}
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingX: 10,
            paddingBottom: 5,
            width: "80%",
            display: {
              lg: "flex",
              md: "flex",
              sm: "none",
              xs: "none",
            },
          }}
        >
          {[
            "automatedDataExtraction",
            "companyAndUserManagement",
            "collaborativeOrdering_",
            "comprehensiveSearchAndFilter",
          ].map((text, index) => (
            <Button
              key={text}
              sx={{
                textTransform: "none",
                alignSelf: "flex-start",
                backgroundColor: currentLogo === index ? "#2b2b2b" : "",
                color: currentLogo === index ? "#fff" : "#2b2b2b",
                borderRadius: 10,
                "&:hover": {
                  backgroundColor: "#2b2b2b",
                },
                paddingX: 2,
              }}
              onMouseEnter={() => setCurrentLogo(index)}
              startIcon={
                <img
                  src={logoW}
                  style={{
                    height: 18,
                    display: currentLogo === index ? "flex" : "none",
                  }}
                />
              }
            >
              {t(text)}
            </Button>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Welcome;
