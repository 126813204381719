import { Tab, Tabs } from "@mui/material";

import { useTranslation } from "react-i18next";

export default function CategoryTabs({ value, setValue, categories }) {
  const { t } = useTranslation();
  return (
    <Tabs
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      variant="scrollable"
      scrollButtons={false}
      sx={{
        ".MuiTabs-indicator": {
          display: "none",
        },
        ".Mui-selected": {
          backgroundColor: "#E8F2FB",
          borderRadius: "1rem",
          fontWeight: "bold",
        },
        maxWidth: {
          lg: "40rem",
          md: "30rem",
          sm: "20rem",
          xs: "20rem",
        },

        minHeight: 36,
      }}
    >
      {categories?.map((category, index) => (
        <Tab
          sx={{
            marginTop: "0.5rem",
            height: "1rem",

            minHeight: "1rem",
            textTransform: "none",
          }}
          key={category}
          label={t(category)}
          onClick={() => {}}
        />
      ))}
    </Tabs>
  );
}
