import "./index.css";

import {
  AppProvider,
  Auth,
  InvoiceProvider,
  OrderProvider,
  ProductProvider,
} from "./context";
import { persistor, store } from "./store";

import App from "./App";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import SnackbarProvider from "./services/SnackbarProvider";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Auth>
            <SnackbarProvider>
              <AppProvider>
                <InvoiceProvider>
                  <OrderProvider>
                    <ProductProvider>
                      <App />
                    </ProductProvider>
                  </OrderProvider>
                </InvoiceProvider>
              </AppProvider>
            </SnackbarProvider>
          </Auth>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
