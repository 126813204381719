import { Box, Pagination } from "@mui/material";
import {
  MobileSearchNavigation,
  PageHeader,
  SearchCompanies,
  SearchInvoice,
  SearchItems,
  SearchNavigation,
} from "../components";
import { useEffect, useRef, useState } from "react";

import algoliasearch from "algoliasearch/lite";
import { useAppContext } from "../context";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function prepareSearchResults(res) {
  let products = {};
  let companies = {};
  let invoices = {};

  res?.map((item) => {
    if (!products[item.productName]) {
      products[item.productName] = {
        purchases: [],
        lastPrice: item?.price,
        name: item?.productName,
        date: item?.date,
        id: item?.objectID,
      };
    }
    const purchases = [
      ...products[item.productName]?.purchases,
      { date: item.date, price: item.price },
    ];

    const priceChange =
      purchases.length > 1
        ? Math.round((1 - purchases[1].price / purchases[0].price) * 100)
        : 0;
    products[item.productName] = {
      ...products[item.productName],
      purchases,
      priceChange,
    };

    if (!companies[item.vendorName]) {
      companies[item.vendorName] = {};
    }
    companies[item.vendorName] = { name: item?.vendorName, id: item?.vendorId };

    if (!invoices[item.invoiceId]) {
      invoices[item.invoiceId] = {
        purchases: [],
      };
    }
    invoices[item.invoiceId] = {
      id: item.invoiceId,
      name: item?.vendorName,
      vendorId: item?.vendorId,
      date: item?.date,
      purchases: [...invoices[item.invoiceId]?.purchases, item],
    };
  });

  return { products, companies, invoices };
}

export default function Search() {
  const { id } = useParams();
  const [results, setResults] = useState();
  const { dateRange } = useAppContext();
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);

  const user = useSelector((state) => state.user.userDoc);
  const admin = user?.role === "Admin";

  const companiesRef = useRef(null);
  const productsRef = useRef(null);
  const invoicesRef = useRef(null);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const searchClient = algoliasearch(
      "LM6U4D5EAY",
      "1fda51e86437cff7f75b96e001b71da7"
    );

    const searchParams = {
      hitsPerPage: 1000,
      typoTolerance: false,
      page: page,
    };
    const { startDate, endDate } = dateRange;
    const startTimestamp = new Date(startDate).getTime() / 1000;
    const endTimestamp = new Date(endDate).getTime() / 1000;

    if (admin) {
      searchParams.filters = `date.seconds >= ${startTimestamp} AND date.seconds <= ${endTimestamp}`;
    } else {
      searchParams.filters = `customerId:${user?.companyId}`;
    }

    const index = searchClient.initIndex("purchases");
    index.search(id, searchParams).then(({ hits, nbHits }) => {
      const maxPage = Math.floor(nbHits / 1000);
      setMaxPage(maxPage);

      const results = prepareSearchResults(hits);
      setResults(results);
    });
  }, [id, page, user, dateRange]);

  useEffect(() => {
    const handleScrollEvent = () => {
      const checkActiveSection = (ref, name) => {
        const element = ref.current;
        const scrollPosition = window.scrollY + 200;
        if (scrollPosition >= element.offsetTop) {
          setActiveSection(name);
        }
      };
      checkActiveSection(companiesRef, "companies");
      checkActiveSection(productsRef, "products");
      checkActiveSection(invoicesRef, "invoices");
    };
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, [companiesRef, productsRef, invoicesRef]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleScroll = (ref) => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 100;
      window.scrollTo({
        top: top - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#f4f2ee",
        padding: { md: 0, lg: 4 },
      }}
    >
      <PageHeader title={`"${id}" için arama sonuçları`} />
      <MobileSearchNavigation
        activeSection={activeSection}
        handleScroll={handleScroll}
        companiesRef={companiesRef}
        productsRef={productsRef}
        invoicesRef={invoicesRef}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          width: "100%",
          minHeight: "calc(100vh - 64px)",
          backgroundColor: "#f4f2ee",
        }}
      >
        <SearchNavigation
          activeSection={activeSection}
          handleScroll={handleScroll}
          companiesRef={companiesRef}
          productsRef={productsRef}
          invoicesRef={invoicesRef}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" },
            paddingBottom: "8rem",
          }}
        >
          <SearchCompanies
            companies={results?.companies}
            companiesRef={companiesRef}
          />
          <SearchItems productsRef={productsRef} products={results?.products} />

          <SearchInvoice
            invoicesRef={invoicesRef}
            invoices={results?.invoices}
          />
          <Pagination
            sx={{
              display: maxPage > 0 ? "block" : "none",
            }}
            page={page}
            count={maxPage}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Box>
  );
}
