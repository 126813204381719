import { Avatar, Box, Grid, Typography } from "@mui/material";

import uniqolor from "uniqolor";

export default function PageCard({ item }) {
  const Component = item.icon;

  return (
    <Grid item xs={item?.xs} md={item?.md} lg={item?.lg} xl={item?.xl}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: "1px solid #dfdeda",
          backgroundColor: "white",
          borderRadius: 4,
          padding: {
            xl: "0.9rem",
            lg: "1rem",
            md: "0.8rem",
            sm: "0.8rem",
            xs: "0.8rem",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xl: "1rem",
                  lg: "1rem",
                  md: "0.8rem",
                  sm: "0.8rem",
                  xs: "0.8rem",
                },
              }}
            >
              {item.title}
            </Typography>
            {item.titleHelper}
          </Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xl: "1.2rem",
                lg: "1.2rem",
                md: "1rem",
                sm: "0.9rem",
                xs: "0.8rem",
              },
            }}
          >
            {item.value}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xl: "1rem",
                lg: "1rem",
                md: "0.8rem",
                sm: "0.7rem",
                xs: "0.6rem",
              },
            }}
          >
            {item.description}
          </Typography>
        </Box>
        <Avatar
          sx={{
            width: {
              xl: "3rem",
              lg: "2rem",
              md: "1.2rem",
              sm: "1.2rem",
              xs: "1.2rem",
            },
            height: {
              xl: "3rem",
              lg: "2rem",
              md: "1.2rem",
              sm: "1.2rem",
              xs: "1.2rem",
            },
            backgroundColor: `${uniqolor(item.title).color}20`,
          }}
        >
          <Component
            sx={{
              fontSize: {
                xl: "2rem",
                lg: "2rem",
                md: "1rem",
                sm: "1rem",
                xs: "1rem",
              },
              color: uniqolor(item.title).color,
            }}
          />
        </Avatar>
      </Box>
    </Grid>
  );
}
