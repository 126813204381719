import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Dialog } from "..";
import React from "react";
import dataExtraction from "../../assets/data-extraction.png";
import invoiceGroup from "../../assets/invoiceGroup.png";
import overviewCompanyGroup from "../../assets/overviewCompanyGroup.png";
import overviewMenu from "../../assets/overviewMenu.png";
import overviewProductGroup from "../../assets/overviewProductGroup.png";
import { useTranslation } from "react-i18next";

const CBox = ({ title, description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // background: "white",
        padding: 2,
        gap: 2,
        width: {
          lg: "60%",
          md: "60%",
          sm: "80%",
          xs: "80%",
        },
        paddingY: "10vh",
      }}
    >
      <Typography
        sx={{
          color: "gray",
          fontWeight: 600,
          fontSize: 24,
          maxWidth: "40%",
          flexShrink: 0,
          textAlign: "end",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#fff",
          fontSize: 24,
          fontWeight: 600,
          width: "auto",

          flexGrow: 1,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

const ImageBox = ({ title, description, image, imageWidth = "30%", sx }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: 8,
        paddingY: "10vh",
        ...sx,
        flexDirection: {
          lg: sx?.flexDirection || "row",
          md: sx?.flexDirection || "row",
          sm: "column",
          xs: "column",
        },
      }}
    >
      <Box
        sx={{
          width: {
            lg: "20%",
            md: "30%",
            sm: "80%",
            xs: "80%",
          },
          paddingTop: 4,
        }}
      >
        <Typography
          sx={{
            color: "gray",
            fontWeight: 600,
            fontSize: 24,
            width: "auto",
            flexShrink: 0,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontSize: 20,
            fontWeight: 600,

            flexGrow: 1,
          }}
        >
          {description}
        </Typography>
      </Box>
      <img
        src={image}
        style={{
          width: isMobile ? "80%" : imageWidth,
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default function InvoiceParserDialog() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={Boolean(location.pathname === "/data-extraction")}
      onClose={() => navigate("/")}
      title={""}
      maxWidth={"xl"}
      bgColor="#1b1b1b"
      sx={{ padding: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#1b1b1b",
          }}
        >
          <Typography
            sx={{
              fontSize: 56,
              fontWeight: 600,
              color: "#fff",
              alignSelf: "center",
              paddingX: 2,
            }}
          >
            {t("invoiceManagement.title")}
          </Typography>
        </Box>
        <CBox
          title={t("invoiceManagement.seamlessDigitalization.title")}
          description={t(
            "invoiceManagement.seamlessDigitalization.description"
          )}
        />
        <ImageBox
          title={t("invoiceManagement.effortlessDataProcessing.title")}
          description={t(
            "invoiceManagement.effortlessDataProcessing.description"
          )}
          image={dataExtraction}
        />
        <CBox
          title={t("invoiceManagement.automaticCreation.title")}
          description={t("invoiceManagement.automaticCreation.description")}
        />

        <ImageBox
          title={t("invoiceManagement.comprehensiveOverview.title")}
          description={t("invoiceManagement.comprehensiveOverview.description")}
          image={overviewMenu}
          imageWidth="40%"
          sx={{
            flexDirection: "row-reverse",
          }}
        />
        <ImageBox
          title={t("invoiceManagement.productAnalysis.title")}
          description={t("invoiceManagement.productAnalysis.description")}
          image={overviewProductGroup}
        />
        <ImageBox
          title={t("invoiceManagement.vendorInsights.title")}
          description={t("invoiceManagement.vendorInsights.description")}
          image={overviewCompanyGroup}
          imageWidth="40%"
          sx={{
            flexDirection: "row-reverse",
          }}
        />

        <ImageBox
          title={t("invoiceManagement.invoiceArchive.title")}
          description={t("invoiceManagement.invoiceArchive.description")}
          image={invoiceGroup}
        />
      </Box>
    </Dialog>
  );
}
