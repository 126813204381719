import { DesktopNavigation, InvoicePreview, PageHeader } from "../components";

import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function DocumentPreview() {
  const { t } = useTranslation();
  const location = useLocation();
  const { focusedDocument } = location.state || {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: "80%",
          borderLeft: "1px solid lightgray",
          borderRight: "1px solid lightgray",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: -1,
            backgroundColor: "white",
            zIndex: 10,
          }}
        >
          <PageHeader title={t("documentPreview")} />
        </Box>

        <InvoicePreview
          sx={{ height: "100%", width: "100%" }}
          contentType={focusedDocument?.contentType}
          img={focusedDocument?.signedUrl}
        />
      </Box>
    </Box>
  );
}
