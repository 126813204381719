import { Box, CircularProgress, Grid, Typography } from "@mui/material";

export default function Summary({ items }) {
  return (
    <Box
      spacing={2}
      sx={{
        margin: "1rem",
        marginTop: "0.4rem",
        borderRadius: "15px",
        border: "1px solid lightgray",
      }}
    >
      {items?.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              padding: 1,
              paddingX: 2,
              borderBottom: index + 1 !== items?.length ? "1px solid #ccc" : "",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.icon && item.icon}
              <Typography sx={{ ml: 2, flexGrow: 1 }}>{item.title}</Typography>
            </Box>

            {item?.value ? (
              <Typography>{item.value}</Typography>
            ) : (
              <CircularProgress
                style={{
                  color: "#1b1b1b",
                  height: "1.2rem",
                  width: "1.2rem",
                }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
}
