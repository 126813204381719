import { Box, Button, Grid, Typography } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { createCurrency, formatDate } from "../../utils";
import { useEffect, useState } from "react";

import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import { Table } from "../../components";
import { db } from "../../firebase";
import { useAppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export function prepareChartData(transactions) {
  if (!transactions) {
    return [];
  }
  const sortedByDate = transactions
    .map((transaction) => {
      return {
        date: transaction.date.seconds,
        price: transaction.price,
      };
    })
    .sort((a, b) => a.date - b.date);

  const sortedPrices = sortedByDate.map((transaction) => transaction.price);
  if (sortedPrices.length === 1) {
    return [sortedPrices[0], sortedPrices[0]];
  }
  return sortedPrices;
}

export const PriceLineChart = ({ data, priceChange }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <SparkLineChart
        data={data}
        height={30}
        width={50}
        colors={[priceChange > 0 ? "red" : "green"]}
      />
      <Typography
        sx={{
          position: "absolute",
          bottom: `calc(50% - 0.6rem)`,
          left: 50,
          padding: 0,
          fontSize: "0.8rem",
        }}
      >
        {`%${Math.abs(priceChange).toFixed()}`}
      </Typography>
    </Box>
  );
};

export const header = [
  { xs: 5, key: "productName", label: "Ürün Adı" },
  { xs: 2, key: "priceChange", label: "Değişim" },
  { xs: 2, key: "lastPrice", label: "Fiyat" },
  { xs: 2, key: "lowestPrice", label: "En Ucuz" },
];

export default function SearchItems({ products, productsRef }) {
  const user = useSelector((state) => state.user.userDoc);

  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const { favorites } = useAppContext();

  useEffect(() => {
    const sorted = Object.keys(products || {})?.map((key) => {
      const product = products[key];
      return {
        id: key,
        productName: product?.name,
      };
    });

    setRows(sorted);
  }, [products]);

  async function handleFavorites(e) {
    try {
      const { name } = e;
      const favorite = favorites.find((f) => f.primary === e.name);
      if (favorite) {
        await deleteDoc(doc(db, "favorites", favorite?.docId));
      } else {
        await addDoc(collection(db, "favorites"), {
          userUid: user?.uid,
          type: "product",
          primary: name,
          id: name,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px solid #dfdeda",
        borderRadius: 4,
        marginTop: "2rem",
        padding: 2,
      }}
    >
      <Typography
        sx={{ fontWeight: "bold", fontSize: "1.6rem" }}
        ref={productsRef}
      >
        Ürünler
      </Typography>
      <Box>
        <Table
          header={header}
          rows={rows}
          onClick={(e) => {
            navigate(`/products-services/${e}`);
          }}
          items={[
            {
              xs: 5,
              label: (key) => key,
            },
            {
              xs: 2,
              component: (key) => {
                return (
                  <PriceLineChart
                    data={prepareChartData(products?.[key]?.purchases)}
                    priceChange={products?.[key]?.priceChange}
                  />
                );
              },
            },
            {
              xs: 2,
              label: (id) => createCurrency(products?.[id]?.lastPrice).format(),
            },
            {
              xs: 2,
              label: (id) => createCurrency(0).format(),
            },
            {
              xs: 1,
              component: (id) => {
                if (favorites?.map((f) => f.primary).includes(id)) {
                  return (
                    <Star
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFavorites(products[id]);
                      }}
                      sx={{ color: "orange" }}
                    />
                  );
                }
                return (
                  <StarBorder
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavorites(products[id]);
                    }}
                    sx={{ color: "orange" }}
                  />
                );
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
}
