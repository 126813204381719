import { Box, Button, CircularProgress, Typography } from "@mui/material";

import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function PageHeader({ title, hideBackButton = false, loading }) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingY: 1,
        borderBottom: "1px solid lightgray",
        width: "100%",
        zIndex: 10000,
        position: "sticky",
        top: 0,
        backdropFilter: "blur(12px)",
        webkitBackdropFilter: "blur(12px)",
      }}
    >
      <Button
        sx={{
          position: "absolute",
          left: 0,
          visibility: hideBackButton ? "hidden" : "visible",
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIos
          sx={{
            color: "#1b1b1b",
          }}
        />
      </Button>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <CircularProgress
        style={{
          visibility: loading ? "visible" : "hidden",
          color: "#1b1b1b",
          height: "1.4rem", // Adjust as needed
          width: "1.4rem", // Adjust as needed
          marginLeft: "0.5rem",
          position: "absolute",
          right: 5,
        }}
      />
    </Box>
  );
}
