import { Box } from "@mui/material";
import logo from "../assets/full-logo.png";
import { useAppContext } from "../context";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Loading() {
  const { loading } = useAppContext();
  const user = useSelector((state) => state.user.userDoc);

  const location = useLocation();

  if (location.pathname === "/" || !loading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        zIndex: 100000,
        backgroundColor: "#f4f2ee",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "20vw",
          height: "80vh",
          position: "fixed",
          backgroundColor: "#f4f2ee",
          flexDirection: "column",
        }}
      >
        <img
          src={logo}
          style={{
            width: "16rem",
            marginBottom: "10px",
          }}
        />
        <span className="loader"></span>
      </Box>
    </Box>
  );
}
