import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

const PointCloudNetwork = () => {
  const [points, setPoints] = useState([]);
  const [connections, setConnections] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    // Generate random points
    const newPoints = Array.from({ length: 200 }, () => ({
      x: Math.random() * 2000 - 1000,
      y: Math.random() * 2000 - 1000,
      z: Math.random() * 2000 - 1000,
    }));
    setPoints(newPoints);

    // Create main connections for specific keys close to the center
    const labels = [
      "product",
      "vendor",
      "invoice",
      "search",
      "filterBy",
      "dateRange",
      "product1",
      "product2",
      "product3",
      "product4",
      "product5",
      "product6",
      "product7",
      "product8",
      "product9",
      "product10",
    ];

    const mainConnections = labels.map((label) => {
      const pointIndex1 = Math.floor(Math.random() * 50); // Points closer to the center
      let pointIndex2;
      do {
        pointIndex2 = Math.floor(Math.random() * 50); // Points closer to the center
      } while (pointIndex2 === pointIndex1);

      return { label, points: [pointIndex1, pointIndex2] };
    });

    // Add additional random connections
    const additionalConnections = [];
    for (let i = 0; i < newPoints.length; i++) {
      for (let j = i + 1; j < newPoints.length; j++) {
        const distance = Math.sqrt(
          Math.pow(newPoints[i].x - newPoints[j].x, 2) +
            Math.pow(newPoints[i].y - newPoints[j].y, 2) +
            Math.pow(newPoints[i].z - newPoints[j].z, 2)
        );
        if (distance < 500) {
          additionalConnections.push({ points: [i, j] });
        }
      }
    }

    setConnections([...mainConnections, ...additionalConnections]);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    let animationFrameId;
    const animate = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const project = (x, y, z) => {
    const perspective = 1000;
    const scale = perspective / (perspective + z + 500);
    return {
      x: x * scale + dimensions.width / 2,
      y: y * scale + dimensions.height / 2,
      scale: scale,
    };
  };

  const rotatePoint = (x, y, z, rotX, rotY) => {
    // Rotate around Y axis
    const temp1 = x * Math.cos(rotY) - z * Math.sin(rotY);
    const temp2 = z * Math.cos(rotY) + x * Math.sin(rotY);

    // Rotate around X axis
    const y2 = y * Math.cos(rotX) - temp2 * Math.sin(rotX);
    const z2 = temp2 * Math.cos(rotX) + y * Math.sin(rotX);

    return { x: temp1, y: y2, z: z2 };
  };

  const time = Date.now() * 0.0001; // Use time to animate rotation
  const rotX = time * Math.PI * 0.1;
  const rotY = time * Math.PI * 0.1;

  return (
    <div ref={containerRef} className="fixed inset-0 w-full h-full">
      <svg
        width={dimensions.width}
        height={dimensions.height}
        style={{ filter: "blur(4px)" }}
      >
        {connections.map((connection, index) => {
          const point1 = points[connection.points[0]];
          const point2 = points[connection.points[1]];

          const rotated1 = rotatePoint(
            point1.x,
            point1.y,
            point1.z,
            rotX,
            rotY
          );
          const rotated2 = rotatePoint(
            point2.x,
            point2.y,
            point2.z,
            rotX,
            rotY
          );

          const {
            x: projX1,
            y: projY1,
            scale: scale1,
          } = project(rotated1.x, rotated1.y, rotated1.z);
          const {
            x: projX2,
            y: projY2,
            scale: scale2,
          } = project(rotated2.x, rotated2.y, rotated2.z);

          const midX = (projX1 + projX2) / 2;
          const midY = (projY1 + projY2) / 2;
          const labelScale = Math.min(scale1, scale2);

          return (
            <g key={index}>
              <line
                x1={projX1}
                y1={projY1}
                x2={projX2}
                y2={projY2}
                stroke={`rgba(0, 0, 0, 0.1)`}
                strokeWidth={Math.min(scale1, scale2)}
              />
              {connection.label && (
                <text
                  x={midX}
                  y={midY}
                  fontSize={labelScale * 50}
                  fill="rgba(0, 0, 0, 0.2)"
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{ pointerEvents: "none" }}
                >
                  {t(connection.label)}
                </text>
              )}
            </g>
          );
        })}
        {points.map((point, index) => {
          const rotated = rotatePoint(point.x, point.y, point.z, rotX, rotY);
          const {
            x: projX,
            y: projY,
            scale,
          } = project(rotated.x, rotated.y, rotated.z);

          return (
            <circle
              key={index}
              cx={projX}
              cy={projY}
              r={Math.max(0.5, scale * 2)}
              fill={`rgba(0, 0, 0, ${scale * 0.5})`}
            />
          );
        })}
      </svg>
    </div>
  );
};

export default PointCloudNetwork;
