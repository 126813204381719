import "./App.css";

import {
  AppSearch,
  DesktopNotifications,
  Drawer,
  OverviewCompany,
  OverviewProduct,
  ProductDialog,
  SEOHelmet,
} from "./components";
import {
  Assistant,
  BlogIndex,
  BlogPost,
  Categories,
  CompanyPage,
  CreateCompanyPage,
  DocumentPreview,
  InvoiceView,
  Items,
  Landing,
  Menu,
  Notifications as NotificationsComponent,
  OrderList,
  Overview,
  PrivacyPolicy,
  Products,
  Profile,
  Search,
  ShoppingCart,
  SiteMap,
  Upload,
} from "./scenes";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import Loading from "./components/Loading";
import React from "react";
import RouteTracker from "./RouteTracker";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <RouteTracker />
        <SEOHelmet />
        <Loading />
        <AppSearch />
        <DesktopNotifications />
        <ProductDialog />
        <Drawer>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/request-demo" element={<Landing />} />
            <Route path="/sign-up" element={<Landing />} />
            <Route path="/login" element={<Landing />} />
            <Route path="/data-extraction" element={<Landing />} />
            <Route path="/collaborative-ordering" element={<Landing />} />
            <Route path="/invoices/:id" element={<InvoiceView />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/products-services" element={<Items />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/products" element={<Products />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/overview/company/:id" element={<OverviewCompany />} />
            <Route path="/overview/product/:id" element={<OverviewProduct />} />
            <Route path="/search/:id" element={<Search />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/notifications" element={<NotificationsComponent />} />
            <Route path="/shopping-cart" element={<ShoppingCart />} />
            <Route path="/shopping-cart/:id" element={<OrderList />} />
            <Route path="/company/setup/new" element={<CreateCompanyPage />} />
            <Route path="/company/:id" element={<CompanyPage />} />
            <Route path="/assistant" element={<Assistant />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/site-map" element={<SiteMap />} />
            <Route path="/document-preview" element={<DocumentPreview />} />
            <Route path="/blog" element={<BlogIndex />} />
            <Route path="/blog/:lang" element={<BlogIndex />} />
            <Route path="/blog/:lang/:postSlug" element={<BlogPost />} />
          </Routes>
        </Drawer>
      </Router>
    </HelmetProvider>
  );
}

export default App;
