import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Dialog } from "..";
import { IconButton } from "@mui/material";
import { InputGroup } from "..";
import LockIcon from "@mui/icons-material/Lock";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import googleLogo from "../../assets/google.png";
import { useAuth } from "../../context";
import { useTranslation } from "react-i18next";

export default function SignUpDialog() {
  const location = useLocation();
  const { handleGoogleSignIn, handleSignUp } = useAuth();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    userName: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState([]); // State for managing errors

  const onGoogleSignIn = async () => {
    try {
      const res = await handleGoogleSignIn();
      if (res) {
        navigate("/profile");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open={Boolean(location.pathname === "/sign-up")}
      onClose={() => navigate("/")}
      title={t("signUp")}
      maxWidth={"xs"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            color: "#2b2b2b",
          }}
        >
          {t("createYourAccount")}
        </Typography>
        <InputGroup
          items={[
            {
              label: t("userName"),
              key: "userName",
              iconLeft: <PersonIcon style={{ color: "#ccc" }} />,
              value: userData?.userName,
            },
            {
              label: t("phoneNumber"),
              key: "phoneNumber",
              value: userData?.phone,
              iconLeft: <PhoneIcon style={{ color: "#ccc" }} />,
              keyboardType: "tel",
            },
            {
              label: t("email"),
              key: "email",
              value: userData?.email,
              iconLeft: <MailIcon style={{ color: "#ccc" }} />,
              keyboardType: "email",
            },
            {
              label: t("password"),
              key: "password",
              passwordVisible: showPassword,
              iconLeft: <LockIcon style={{ color: "#ccc" }} />,
              value: userData?.password,
              iconRight: (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <VisibilityIcon style={{ color: "#ccc" }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: "#ccc" }} />
                  )}
                </IconButton>
              ),
            },
          ]}
          onPressRightIcon={() => setShowPassword(!showPassword)}
          onChangeText={(key, value) =>
            setUserData({ ...userData, [key]: value })
          }
        />
        {errors.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {errors.map((error, index) => (
              <Typography key={index} color="error">
                {error}
              </Typography>
            ))}
          </Box>
        )}
        <Button
          onClick={() =>
            handleSignUp({
              userData,
              setErrors,
              navigate,
            })
          }
          variant="contained"
          sx={{
            width: "60%",
            backgroundColor: "#2b2b2b",
            borderRadius: 10,
            color: "#fff",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#2b2b2b",
            },
          }}
        >
          {t("signUp")}
        </Button>
        <Divider
          sx={{
            width: "60%",
          }}
        >
          {t("or")}
        </Divider>
        <Button
          sx={{
            borderRadius: 10,
            color: "#2b2b2b",
            textTransform: "none",
            border: "1px solid #2b2b2b",
            paddingX: 2,
          }}
          startIcon={<Avatar sx={styles.googleIcon} src={googleLogo} />}
          onClick={onGoogleSignIn}
        >
          {t("continueWithGoogle")}
        </Button>
      </Box>
    </Dialog>
  );
}

const styles = {
  googleIcon: {
    width: 20,
    height: 20,
    paddingRight: 0,
  },
};
