import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db, provider } from "../firebase";
import { clearUser, setUserDoc } from "../redux/User/userSlice";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export const Auth = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeSnapshot = onSnapshot(
          userDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              dispatch(setUserDoc(docSnapshot.data()));
            } else {
              console.log("No such document!");
              dispatch(setUserDoc({ uid: user.uid }));
            }
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching user document:", error);
            setLoading(false);
          }
        );

        return () => unsubscribeSnapshot();
      } else {
        dispatch(clearUser());
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDocRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) {
        let data = {
          email: user.email,
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          displayName: user.displayName,
          uid: user.uid,
        };
        Object.keys(data).forEach(
          (key) => data[key] === null && delete data[key]
        );
        await setDoc(userDocRef, data);
      }
      return true;
    } catch (error) {
      console.error("Error signing in with Google: ", error);
      return false;
    }
  };

  const handleAuth = async ({ userData, setError, navigate }) => {
    try {
      await signInWithEmailAndPassword(auth, userData.email, userData.password);
      navigate("/profile");
    } catch (error) {
      console.error("Error signing in:", error);
      setError(t("userNotFound"));
    }
  };

  const handleSignUp = async ({ userData, setErrors, navigate }) => {
    const newErrors = [];
    if (!userData.userName) newErrors.push(t("usernameMissing"));
    if (!userData.phoneNumber) newErrors.push(t("phoneNumberMissing"));
    if (!userData.email) newErrors.push(t("emailMissing"));
    if (!userData.password) newErrors.push(t("passwordMissing"));
    if (userData.password && userData.password.length < 6)
      newErrors.push(t("passwordTooShort"));

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors([]);
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        userData.email,
        userData.password
      );
      const user = result.user;

      let data = {
        email: user.email,
        emailVerified: true,
        phoneNumber: userData.phoneNumber,
        photoURL: null,
        displayName: userData.userName,
        uid: user.uid,
        role: "User",
      };
      Object.keys(data).forEach(
        (key) => data[key] === null && delete data[key]
      );
      const userDocRef = doc(db, "users", user.uid);

      await setDoc(userDocRef, data);
      dispatch(setUserDoc(data));

      navigate("/profile");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErrors([t("emailInUse")]);
      } else if (error.code === "auth/invalid-email") {
        setErrors([t("invalidEmail")]);
      } else if (error.code === "auth/weak-password") {
        setErrors([t("weakPassword")]);
      } else {
        setErrors([t("generalError")]);
      }
    }
  };

  const value = { handleGoogleSignIn, handleAuth, handleSignUp, loading };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
