import { Box, Button, Grid, Typography } from "@mui/material";
import { createCurrency, formatDate } from "../../utils";

import { useNavigate } from "react-router-dom";

export default function SearchInvoice({ invoices, invoicesRef }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px solid #dfdeda",
        borderRadius: 4,
        marginTop: "2rem",
        padding: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "1.6rem",
        }}
        ref={invoicesRef}
      >
        Faturalar
      </Typography>
      <Box sx={{ cursor: "pointer" }}>
        {Object.values(invoices || {})?.map((invoice, i) => {
          const { id, name, date, purchases, vendorId } = invoice;
          return (
            <Grid
              key={id}
              container
              sx={{
                paddingY: 2,
                paddingX: 1,
                borderBottom:
                  i !== Object.values(invoices || {})?.length - 1
                    ? "1px solid lightgray"
                    : "",
                alignItems: "center",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#f4f2ee";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "white";
              }}
              onClick={() => {
                navigate("/invoices/" + id);
              }}
            >
              <Grid xs={2} item>
                <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {formatDate(date, "d MMMM")}
                </Typography>
              </Grid>
              <Grid
                xs={8}
                item
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/companies/" + vendorId);
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.textDecoration = "underline";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.textDecoration = "none";
                  }}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid
                xs={2}
                item
                sx={{
                  textAlign: "end",
                }}
              >
                <Button
                  sx={{
                    borderRadius: 10,
                    borderColor: "#1876d2",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/invoices/" + id);
                  }}
                >
                  Fatura
                </Button>
              </Grid>
              <Grid
                container
                sx={{ marginX: 2, paddingTop: 2, alignItems: "center" }}
                spacing={1}
              >
                <Grid item xs={7}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Ürün Hizmet
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      // textAlign: "center",
                      color: "gray",
                    }}
                  >
                    Adet
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Fiyat
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Toplam
                  </Typography>
                </Grid>
              </Grid>
              {purchases?.map((p) => {
                const { productName, quantity, total, productId, price } = p;
                return (
                  <Grid
                    container
                    key={productId}
                    sx={{
                      marginX: 2,
                      paddingY: "0.4rem",
                      alignItems: "center",
                    }}
                    spacing={1}
                  >
                    <Grid item xs={7}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        {productName}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{}}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        {quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        {createCurrency(price).format()}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: "0.9rem" }}>
                        {createCurrency(total).format()}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
}
