import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../../firebase";

export async function fetchPendingInvoices({ user, setPendingInvoices }) {
  try {
    let invoiceQuery = query(collection(db, "cleanedInvoiceData"));
    const admin = user?.role === "Admin";
    if (!admin) {
      invoiceQuery = query(invoiceQuery, where("userUid", "==", user.uid));
    }
    invoiceQuery = query(
      invoiceQuery,
      where("status", "not-in", ["admin-approved"])
    );

    const querySnapshot = await getDocs(invoiceQuery);

    let invoices = {};
    querySnapshot.forEach((doc) => {
      const total = Number(doc.data().fields?.InvoiceTotal?.value?.amount || 0);
      const subTotal = Number(doc.data().fields?.SubTotal?.value?.amount || 0);
      const totalVat = Number(doc.data().fields?.TotalTax?.value?.amount || 0);
      const totalDiscount = Number(
        doc.data().fields?.TotalDiscount?.value?.amount || 0
      );
      const falsy = Math.abs(subTotal + totalVat - total - totalDiscount) > 1;

      invoices[doc.id] = {
        id: doc.id,
        invoiceId: doc.id,
        ...doc.data(),
        status: doc.data()?.status || "Pending",
        date: doc.data().fields?.InvoiceDate?.value?.seconds || "",
        vendorName: doc.data().fields?.VendorName?.value || "",
        productAmount: doc.data().fields?.Items?.values?.length || 0,
        subTotal: subTotal,
        totalVat: totalVat,
        total: total,
        totalDiscount: totalDiscount,
        falsy: falsy,
      };
    });
    setPendingInvoices(invoices);
  } catch (error) {
    console.error(error);
  }
}
