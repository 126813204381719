export const categories = [
  "Temel Gıda",
  "Temizlik Ürünleri",
  "Et, Tavuk, Şarküteri",
  "Süt, Kahvaltılık",
  "Meyve, Sebze",
  "Atıştırmalıklar",
  "İçecekler",
  "Deniz Ürünleri",
  "Kullan At",
  "Yapı Market, Bahçe, Diğer",
  "Alkollü İçecekler",
  "Evcil Hayvan",
  "Donuk Ürünler",
];

export const subCategories = [
  "Tuvalet Temizlik",
  "Ağız & Diş Bakımı",
  "Alkollü İçecekler",
  "Aromalı Süt",
  "Aromaterapi",
  "Atıştırmalıklar",
  "Bakliyat, Pirinç ve Makarna",
  "Bal, Reçel",
  "Balık, Deniz Ürünleri",
  "Bebek Bakım, Sağlık",
  "Beyaz Et",
  "Biber",
  "Biberon ve Emzikler",
  "Bira",
  "Bitkisel Ürünler",
  "Brandy",
  "Çabuk Hazır Gıdalar",
  "Çamaşır Deterjanı",
  "Çay, Bitki Çayı, Kahve",
  "Çekirdek",
  "Çeşniler",
  "Cilt Bakım",
  "Cin",
  "Cinsel Sağlık",
  "Çöp Kutuları",
  "Çöp Torbası ve Poşetler",
  "Çorba, Bulyon",
  "Deodorant",
  "Deterjan, Temizleyici",
  "Diğer",
  "Doğal ve Organik Ürünler",
  "Domuz Eti",
  "Dondurma, Tatlı",
  "Dondurulmuş Gıda",
  "Duş Jeli",
  "Ekmek, Unlu Mamuller",
  "El, Vücut Bakımı",
  "Enerji İçeceği",
  "Erkek Bakım",
  "Evcil Hayvan",
  "Evde Bakım Ürünleri",
  "Fermente İçecekler",
  "Fırın, Pasta Malzemeleri",
  "Fıstık ezmesi ve kremler",
  "Fonksiyonel Ürünler",
  "Gazlı İçecek",
  "Gazsız İçecek",
  "Gider Açıcılar",
  "Gıda Takviyesi",
  "Glutensiz Ürünler",
  "Grissini ve Krakerler",
  "Hamur İşleri",
  "Hava ve Koku Gidericiler",
  "Hazır Gıda",
  "Genel Temizlik Ürünleri",
  "Hazır Kahve",
  "İçkiler",
  "İşlenmiş Et Ürünleri",
  "İşlenmiş Tarım Ürünleri",
  "Kadın Hijyen, Bakım",
  "Kağıt ve Plastik Ürünler",
  "Kahvaltılık",
  "Kalamar",
  "Kanatlı Et",
  "Kapaklar",
  "Karides",
  "Kasap",
  "Kasap Ürünleri",
  "Kaşar Peyniri",
  "Kefir, Ayran",
  "Kek, Pasta",
  "Kişisel Bakım",
  "Kırmızı Et",
  "Kırtasiye",
  "Kolonya",
  "Konserve",
  "Krakerler",
  "Krema",
  "Krema ve Kaymak",
  "Kulak Bakımı",
  "Kullan At",
  "Kuru Gıda",
  "Kuru Meyve, Kuruyemiş",
  "Kurutulmuş Bitkiler",
  "Kurutulmuş Et",
  "Kutu",
  "Kuzu",
  "Laktozsuz Süt",
  "Leke Çıkarıcılar",
  "Likör",
  "Manav",
  "Mantarlar",
  "Margarin",
  "Meyve Suyu",
  "Meyve, Sebze",
  "Mısır Gevreği",
  "Mısır ve Pirinç Patlağı",
  "Mutfak Araç Gereçleri",
  "Organik Ürünler",
  "Organik Ve Diyet Gıda",
  "Otlar",
  "Parfüm ve Deodorant",
  "Peçete, Havlu, Tuvalet Kağıdı",
  "Peynir",
  "Pil, Şarj Cihazları",
  "Puding",
  "Rakı",
  "Saç Bakım",
  "Salça & Baharat",
  "Salon Yiyecekleri",
  "Şampuan",
  "Sandviçler",
  "Şarap ve Vermut",
  "Sarf Malzemeler",
  "Şarküteri",
  "Şarküteri, Kahvaltılık",
  "Şekerleme, Çikolata",
  "Sert Peynirler",
  "Simit",
  "Sirke",
  "Sivri Biber",
  "Sıvı Sabun",
  "Sıvı Yağlar",
  "Sofralık Zeytin",
  "Soslar",
  "Spirits",
  "Sporcu Gıdaları",
  "Su, Maden Suyu ve Soda",
  "Sucuk",
  "Sürülebilir Ürünler",
  "Şurup",
  "Şuruplar",
  "Süt Ürünleri",
  "Tatlı Malzemeleri",
  "Tavuk",
  "Hindi",
  "Taze Baharatlar",
  "Taze Et & Balık",
  "Taze Meyve",
  "Taze Otlar",
  "Taze Sebzeler",
  "Temel Gıda",
  "Tereyağı",
  "Tıkanıklık Açıcılar",
  "Toz İçecekler",
  "Tropikal Meyve",
  "Türk Mutfağı",
  "Turşu",
  "Tuz, Baharat",
  "Un",
  "Unlu Mamül, Tatlı",
  "Vejetaryen, Vegan",
  "Viski",
  "Vitamin ve Mineraller",
  "Vodka",
  "Vücut Bakım",
  "Yapay Lezzet Şurupları",
  "Yemek Harçları",
  "Yeşillik",
  "Yetişkin Bakım",
  "Yoğurt",
  "Yumurta",
  "Yumuşatıcılar",
  "Zeytin, Zeytinyağı",
  "Marul",
  "Tekila",
];

export const units = ["Kg", "Lt", "Cl", "Ml", "Adet"];
