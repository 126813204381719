import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Custom language detector
const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (callback) => {
    try {
      // Check if a language is saved in localStorage
      const savedLanguage = localStorage.getItem("user-language");
      if (savedLanguage) {
        callback(savedLanguage);
      } else {
        // If no saved language, use the browser's language
        const browserLang = navigator.language || navigator.userLanguage;
        callback(browserLang);
      }
    } catch (error) {
      console.error("Error detecting language:", error);
      // Fallback to English if an error occurs
      callback("en");
    }
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    try {
      localStorage.setItem("user-language", lng);
    } catch (e) {
      console.error("Failed to save language to local storage:", e);
    }
  },
};

i18n
  .use(HttpApi)
  .use(languageDetector)
  .use(initReactI18next)
  .init(
    {
      supportedLngs: ["en", "tr"],
      fallbackLng: "en",
      detection: {
        order: [
          "customDetector",
          "querystring",
          "cookie",
          "localStorage",
          "navigator",
          "htmlTag",
        ],
        caches: ["localStorage", "cookie"],
      },
      backend: {
        loadPath: "/locales/{{lng}}/translation.json",
      },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) console.error("i18n initialization error:", err);
      // You can add any additional initialization logic here
    }
  );

export default i18n;
