import {
  Box,
  Chip,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { CancelRounded, Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

const Search = ({
  onSearch,
  items,
  onClick,
  placeholder = "exploreSearchPlaceHolder",
  algolia = false,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [popoverWidth, setPopoverWidth] = useState(0);
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchRef.current) {
      setPopoverWidth(searchRef.current.offsetWidth);
    }
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (!anchorEl && searchRef.current) {
      setAnchorEl(searchRef.current);
      setPopoverWidth(searchRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    if (searchTerm.length !== 0) {
      onSearch(searchTerm);
    }
  }, [searchTerm]);

  const handleClose = (event) => {
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleFocus = () => {
    if (searchRef.current) {
      setAnchorEl(searchRef.current);
      setPopoverWidth(searchRef.current.offsetWidth);
    }
  };

  const handleBlur = (event) => {
    setTimeout(() => {
      if (
        searchRef.current &&
        !searchRef.current.contains(document.activeElement)
      ) {
        setAnchorEl(null);
      }
    }, 200);
  };

  const open = Boolean(anchorEl);
  const id = open ? "search-popover" : undefined;

  return (
    <Box sx={{ margin: "1rem", marginTop: "0.4rem" }}>
      <TextField
        inputRef={searchRef}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            padding: "0.4rem",
            borderRadius: "32px",
          },
        }}
        placeholder="Search"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                marginLeft: 1,
              }}
            >
              <SearchIcon
                color={searchTerm?.length > 0 ? "primary" : "action"}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => setSearchTerm("")}
              sx={{
                marginRight: 1,
                cursor: "pointer",
              }}
            >
              <CancelRounded
                sx={{
                  visibility: searchTerm?.length > 0 ? "visible" : "hidden",
                }}
                color="info"
              />
            </InputAdornment>
          ),
        }}
        autoComplete="off"
        onChange={handleSearch}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={searchTerm}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: popoverWidth + 96,
            marginTop: "8px",
            marginLeft: -48,
            maxHeight: "50vh",
          },
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        <List sx={{ width: "100%", py: 0 }}>
          <ListItem
            sx={{
              display: items?.length > 0 ? "flex" : "none",
            }}
          >
            <Typography variant="body2">
              {t("searchFor")} "{searchTerm}"
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "6vh",
              display: items?.length === 0 ? "flex" : "none",
            }}
          >
            <Typography variant="body2">{t(placeholder)}</Typography>
          </ListItem>

          {items?.map((suggestion, index) => {
            if (algolia) {
              const parts = suggestion?.highlight?.value
                .split(/(<\/?em>)/g)
                .filter(Boolean);
              const cleanValue = suggestion?.highlight?.value.replace(
                /<\/?em>/g,
                ""
              );

              return (
                <Box
                  key={index}
                  sx={{
                    padding: 2,
                    justifyContent: "space-between",
                    display: "flex",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  onClick={() => onClick({ ...suggestion, cleanValue })}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    {parts.map((part, i) =>
                      part === "<em>" ? (
                        <Typography
                          key={`em-${i}`}
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#E8F2FB",
                            marginLeft: "5px",
                            borderRadius: "4px",
                            paddingY: "2px",
                          }}
                        >
                          {parts[i + 1]}
                        </Typography>
                      ) : part === "</em>" || parts[i - 1] === "<em>" ? null : (
                        <Typography key={i}>{part}</Typography>
                      )
                    )}
                  </Box>
                  <Chip
                    variant="outlined"
                    color={
                      suggestion?.key === "productName"
                        ? "primary"
                        : suggestion?.key === "vendorName"
                        ? "secondary"
                        : suggestion?.key === "category"
                        ? "info"
                        : "default"
                    }
                    label={t(suggestion?.key)}
                  />
                </Box>
              );
            }
            return (
              <ListItem
                sx={{
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => onClick(suggestion)}
              >
                <ListItemText primary={suggestion} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </Box>
  );
};

export default Search;
