import {
  ArrowForward,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import shoppingCartLists from "../../assets/shopping-cart-list.png";
import shoppingCartOrderDetail from "../../assets/shopping-cart-order-detail.png";
import shoppingCartOrderList from "../../assets/shopping-cart-order-list.png";
import shoppingCartOrderShare from "../../assets/shopping-cart-order-share.png";
import shoppingCartOrders from "../../assets/shopping-cart-orders.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cart = ({
  src,
  title,
  description,
  backgroundColor = "linear-gradient(to bottom, #1b1b1b, #3b3b3b)",
  titleColor = "#fff",
}) => {
  return (
    <Box
      sx={{
        background: backgroundColor,
        width: "300px", // Set a fixed width for each cart
        borderRadius: "20px",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        flexShrink: 0, // Prevent shrinking to ensure proper scroll behavior
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: titleColor,
            padding: 4,
            paddingBottom: 0,
            alignSelf: "flex-start",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            padding: 4,
            paddingBottom: 0,
            alignSelf: "flex-start",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          {description}
        </Typography>
      </Box>
      <img
        src={src}
        alt="Invoice Detail"
        style={{
          maxWidth: "200px",
          width: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};
const InfoCart = ({ title, description, buttonText, onClick }) => {
  return (
    <Box
      sx={{
        // background: "linear-gradient(to bottom, #1b1b1b, #3b3b3b)",
        width: "300px", // Set a fixed width for each cart
        borderRadius: "20px",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        flexShrink: 0, // Prevent shrinking to ensure proper scroll behavior
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            // color: "transparent",
            padding: 4,
            paddingBottom: 0,
            alignSelf: "flex-start",
            fontSize: 38,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            paddingX: 4,

            alignSelf: "flex-start",
            fontSize: 28,
            fontWeight: 600,
          }}
        >
          {description}
        </Typography>
        <Button
          sx={{
            textTransform: "none",
            fontSize: 22,
            alignSelf: "flex-start",
            paddingX: 4,
            marginTop: 4,
          }}
          endIcon={<ArrowForward sx={{ fontSize: 50 }} />}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

const CollaborativeOrdering = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const updateArrowsVisibility = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({
      left: -containerRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      left: containerRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    updateArrowsVisibility();
    containerRef?.current?.addEventListener("scroll", updateArrowsVisibility);
    return () => {
      containerRef?.current?.removeEventListener(
        "scroll",
        updateArrowsVisibility
      );
    };
  }, []);

  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #F2F2F2, #FFFFFF)",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: {
          lg: 0,
          md: 20,
          sm: 20,
          xs: 40,
        },
        paddingBottom: {
          lg: 0,
          md: 20,
          sm: 20,
          xs: 40,
        },
      }}
    >
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          justifyContent: "space-between",
          alignContent: "flex-end",
        }}
      >
        <Typography
          sx={{
            fontSize: 44,
            fontWeight: 600,
            color: "#2b2b2b",
            alignSelf: "flex-start",
            padding: 4,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {t("collaborativeOrdering.title")}
        </Typography>
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 600,
            color: "gray",
            alignSelf: "flex-start",
            padding: 4,
            placeSelf: "end",
            paddingLeft: 0,
          }}
        >
          {t("organizeEfficiently")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          position: "relative",
        }}
      >
        {showLeftArrow && (
          <IconButton
            sx={{ position: "absolute", left: 0, zIndex: 1 }}
            onClick={scrollLeft}
          >
            <KeyboardArrowLeft />
          </IconButton>
        )}
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            paddingBottom: 2,
            width: "100%",
          }}
        >
          <Cart
            src={shoppingCartLists}
            title={t("collaborativeOrdering.categorized.title")}
          />
          <Cart
            src={shoppingCartOrderList}
            title={""}
            backgroundColor={"transparent"}
            description={""}
          />
          <InfoCart
            title={t("collaborativeOrdering.handling.title")}
            description={t("collaborativeOrdering.handling.description")}
            buttonText={t("viewAllFeatures")}
            onClick={() => navigate("/collaborative-ordering")}
          />
          <Cart
            src={shoppingCartOrders}
            title={t("collaborativeOrdering.vendorOrders.title")}
            description={""}
          />
          <Cart
            src={shoppingCartOrderDetail}
            title={""}
            description={""}
            backgroundColor={"transparent"}
          />
          <InfoCart
            title={t("collaborativeOrdering.orderDetails.title")}
            description={t("collaborativeOrdering.orderDetails.description")}
            buttonText={t("viewAllFeatures")}
            onClick={() => navigate("/collaborative-ordering")}
          />
          <Cart
            src={shoppingCartOrderShare}
            title={t("collaborativeOrdering.sharing.description")}
          />
        </Box>
        {showRightArrow && (
          <IconButton
            sx={{ position: "absolute", right: 0, zIndex: 1 }}
            onClick={scrollRight}
          >
            <KeyboardArrowRight />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default CollaborativeOrdering;
