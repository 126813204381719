import {
  ArrowForward,
  Mail,
  NewReleases,
  Person,
  Phone,
  Verified,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Dialog, InputGroup } from "../../components";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";

export default function RequestDemoDialog({ open, onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    userName: "",
    phoneNumber: "",
  });

  const redirecToCalendar = () => {
    window.open(
      "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1t-f1bScCSLgEJolIZ-K02oaUCCvL4adB3KbuiOTU6dGfxWZbUBTrDwG0_jlvD6NQrNk-BH4dp",
      "_blank"
    );
  };

  async function submitRequest({ user }) {
    try {
      const sendEmail = httpsCallable(functions, "sendEmailOnOfferRequest");

      const requestData = {
        message: `Toplanti talebi \n\n from: ${user?.userName} \n\n email: ${user?.email} \n\n phone: ${user?.phoneNumber}`,
      };
      const res = await sendEmail(requestData);
      setSuccess(res?.data?.success);
    } catch (error) {
      setSuccess(false);
      throw error;
    }
  }

  return (
    <Dialog
      open={Boolean(location.pathname === "/request-demo")}
      onClose={() => navigate("/")}
      title={t("requestDemo")}
      maxWidth={"xs"}
      confirmButton={""}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            color: "#2b2b2b",
          }}
          variant="h5"
        >
          {t("bookMeetingNow")}
        </Typography>

        <Button
          onClick={redirecToCalendar}
          sx={{
            width: "60%",
            backgroundColor: "#2b2b2b",
            borderRadius: 10,
            color: "#fff",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#2b2b2b",
            },
          }}
          endIcon={<ArrowForward sx={{ fontSize: 50, color: "#fff" }} />}
        >
          {t("selectAppointmentTime")}
        </Button>

        <Divider
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              alignSelf: "flex-start",
              fontSize: 14,
              fontWeight: 600,
              color: "gray",
            }}
            variant="h5"
          >
            {t("or")}
          </Typography>
        </Divider>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
            display: success === undefined ? "flex" : "none",
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "gray",
            }}
            variant="h5"
          >
            {t("demoForm")}
          </Typography>

          <InputGroup
            items={[
              {
                label: t("email"),
                key: "email",
                value: userData?.email,
                iconLeft: <Mail style={{ color: "#ccc" }} />,
                keyboardType: "email",
              },
              {
                label: t("userName"),
                key: "userName",
                iconLeft: <Person style={{ color: "#ccc" }} />,
                value: userData?.userName,
              },
              {
                label: t("phoneNumber"),
                key: "phoneNumber",
                value: userData?.phoneNumber,
                iconLeft: <Phone style={{ color: "#ccc" }} />,
                keyboardType: "tel",
              },
            ]}
            onPressRightIcon={() => setShowPassword(!showPassword)}
            onChangeText={(key, value) =>
              setUserData({ ...userData, [key]: value })
            }
          />

          <Button
            sx={{
              // alignSelf: "flex-start",
              borderRadius: 10,
              color: "#2b2b2b",
              textTransform: "none",
            }}
            onClick={() => submitRequest({ user: userData })}
          >
            {t("contactMe")}!
          </Button>
        </Box>
        <Box
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            display: success === true ? "flex" : "none",
          }}
        >
          <Verified sx={{ fontSize: 50, color: "green" }} />
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "gray",
            }}
            variant="h5"
            textAlign={"center"}
          >
            {t("thankYouInterest")} <br />
            {t("demoFormSuccess")}
          </Typography>
        </Box>
        <Box
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            display: success === false ? "flex" : "none",
          }}
        >
          <NewReleases sx={{ fontSize: 50, color: "red" }} />
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 600,
              color: "gray",
            }}
            variant="h5"
            textAlign={"center"}
          >
            {t("errorOccured")} <br />
            {t("tryAgain")}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}
