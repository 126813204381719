import { Box, InputBase, Typography } from "@mui/material";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import React from "react";

export default function Input({
  title,
  value,
  onChange,
  onBlur,
  textAlign = "left",
  sx,
  type = "text",
  testId,
  readOnly = false,
  disabled = false,
  confidence,
  multiline = null,
  selectAll = false,
  titleIcon,
  titleSx,
  placeholder,
  startAdornment,
  onClick,
}) {
  const handleBoxClick = (e) => {
    const input = document.getElementById(title);
    if (input && !readOnly) {
      input.focus();
      if (selectAll) {
        input.select();
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        ...sx,
      }}
      onClick={handleBoxClick}
    >
      <div
        style={{
          color: "gray",
          fontSize: "1rem",
          alignSelf: "flex-start",
          paddingTop: "0.225rem",
        }}
      >
        {titleIcon}
      </div>
      <Typography
        alignSelf="center"
        color="secondary"
        fontSize="body1"
        sx={{
          color: "gray",
          fontSize: "0.8rem",
          alignSelf: "flex-start",
          paddingTop: "0.28rem",
          ...titleSx,
        }}
        onClick={onClick}
      >
        {title}
      </Typography>
      <InputBase
        data-testid={testId}
        id={title}
        onBlur={onBlur}
        onChange={onChange}
        sx={{
          lineHeight: "1.5rem",
          paddingLeft: title ? "1rem" : "",
          flexGrow: 1,
          "& input": { textAlign: textAlign, fontSize: "0.8rem" },
          fontSize: "0.8rem",
          width: "6rem",
        }}
        value={value}
        type={type}
        inputProps={{
          inputMode: type === "tel" ? "decimal" : "text",
          pattern: "[0-9]*,?",
        }}
        readOnly={readOnly}
        disabled={disabled}
        multiline={Boolean(multiline)}
        rows={multiline?.rows}
        placeholder={placeholder}
        startAdornment={
          <Typography
            sx={{
              fontSize: "0.7rem",
              paddingRight: "0.2rem",
            }}
          >
            {startAdornment}
          </Typography>
        }
      />

      <ErrorOutlineOutlinedIcon
        sx={{
          position: "absolute",
          left: -10,
          top: "35%",
          fontSize: "0.6rem",
          color: confidence > 0.9 ? "green" : "red",
          display: confidence < 0.9 ? "block" : "none",
        }}
      />
      {confidence < 0.9 && (
        <Typography
          sx={{
            position: "absolute",
            color: "#f06666d4",
            fontSize: "0.5rem",
            left: -8,
            top: "70%",
          }}
        >
          {confidence.toFixed(2)}
        </Typography>
      )}
    </Box>
  );
}
