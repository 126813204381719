import { Box, CircularProgress } from "@mui/material";
import { InvoiceWidget, KeyValueList } from "../../components";

import { Divider } from "@mui/material";
import { ProductLineChart } from "../../components";
import { createCurrency } from "../../utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const OverviewCompanyDetails = ({ loading }) => {
  const { t } = useTranslation();
  const { companyInvoices } = useSelector((state) => state.invoices);

  const [selectedValue, setSelectedValue] = useState(null);
  const [priceLine, setPriceLine] = useState(null);

  useEffect(() => {
    if (!companyInvoices) return;
    console.log({ companyInvoices });
    const data = companyInvoices
      ?.slice()
      ?.sort((a, b) => new Date(a.date.seconds) - new Date(b.date.seconds))
      .map((invoice, index, arr) => {
        let priceChangePercentage = 0;
        if (index > 0 && arr[index - 1].total !== 0) {
          const previousInvoiceTotal = arr[index - 1].total;
          priceChangePercentage =
            ((invoice.total - previousInvoiceTotal) / previousInvoiceTotal) *
            100;
        }

        return {
          x: index,
          y: invoice?.total,
          date: invoice?.date,
          amount: invoice?.total || 0,
          invoiceId: invoice?.id,
          price: invoice?.total,
          priceChange: priceChangePercentage?.toFixed(2),
          vendorName: invoice?.vendorName,
          total: invoice?.total,
          tax: invoice?.tax,
          totalVat: invoice?.tax,
          discount: invoice?.discount || 0,
          totalDiscount: invoice?.discount || 0,
          id: invoice?.id,
          normalizedHeightMultiplier: 4000,
        };
      });
    if (data) {
      setPriceLine(data);
      setSelectedValue(data[data?.length - 1]);
    }
  }, [companyInvoices]);
  return (
    <Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
          }}
        >
          <CircularProgress size={24} sx={{ color: "#1b1b1b" }} />
        </Box>
      ) : (
        <>
          <ProductLineChart
            onSelectedPoint={(v) => setSelectedValue(priceLine[v])}
            priceLine={priceLine}
            selectedValue={selectedValue}
            hideAmount={true}
          />
          <Divider />
          <InvoiceWidget invoice={selectedValue} />
          <KeyValueList
            items={[
              { label: t("invoiceCount"), value: companyInvoices?.length },
              {
                label: t("subtotal"),
                value: createCurrency(
                  companyInvoices?.reduce((acc, curr) => acc + curr.subTotal, 0)
                ).format(),
              },
              {
                label: t("discount"),
                value: createCurrency(
                  companyInvoices?.reduce((acc, curr) => acc + curr.discount, 0)
                ).format(),
              },
              {
                label: t("vat"),
                value: createCurrency(
                  companyInvoices?.reduce((acc, curr) => acc + curr.tax, 0)
                ).format(),
              },

              {
                label: t("total"),
                value: createCurrency(
                  companyInvoices?.reduce((acc, curr) => acc + curr.total, 0)
                ).format(),
              },
            ]}
          />
          <Divider />
        </>
      )}
    </Box>
  );
};

export default OverviewCompanyDetails;
