import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import { setUserDoc, setUserId } from "./userSlice";

const firestore = getFirestore();

const userMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === setUserId.type) {
      const uid = action.payload;
      console.log("sex");
      try {
        const userDocumentRef = doc(firestore, "users", uid);
        const userDocumentSnapshot = await getDoc(userDocumentRef);

        if (userDocumentSnapshot.exists()) {
          const userData = userDocumentSnapshot.data();
          console.log({ userData });
          dispatch(setUserDoc(userData));
        } else {
          dispatch(setUserDoc(null));
        }

        const unsubscribe = onSnapshot(
          userDocumentRef,
          (userDocumentSnapshot) => {
            if (userDocumentSnapshot.exists()) {
              const userData = userDocumentSnapshot.data();
              dispatch(setUserDoc(userData));
            } else {
              dispatch(setUserDoc(null));
            }
          },
          (error) => {
            console.error("Error fetching user document:", error);
          }
        );

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching user document:", error);
      }
    }

    return next(action);
  };

export default userMiddleware;
