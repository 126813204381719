import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { CheckCircleRounded, InsertPhotoRounded } from "@mui/icons-material";

import { useAppContext } from "../../context";

const UploadNotification = ({ file, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        padding: "0.6rem",
        borderBottom: "1px solid rgba(0,0,0,.1)",
        height: "51.82px",
      }}
      onClick={() => onClick(file)}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Avatar
          variant="rounded"
          sx={{
            backgroundColor: "#d93026",
            height: "2rem",
            width: "2rem",
          }}
        >
          <InsertPhotoRounded
            sx={{
              color: "white",
            }}
          />
        </Avatar>

        <Typography
          sx={{
            paddingLeft: "0.8rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "14rem",
          }}
        >
          {file?.file?.name}
        </Typography>
      </Box>
      <Box>
        <CircularProgress
          style={{
            display: file?.status === "uploading" ? "block" : "none",
            color: "#d93026",
            height: "1.4rem", // Adjust as needed
            width: "1.4rem", // Adjust as needed
            marginLeft: "0.5rem",
          }}
        />
        <CheckCircleRounded
          style={{
            display: file?.status === "uploaded" ? "block" : "none",
            color: "#34a853",
            height: "1.4rem", // Adjust as needed
            width: "1.4rem", // Adjust as needed
            marginLeft: "0.5rem",
          }}
        />
      </Box>
    </Box>
  );
};

export default function UploadProcess() {
  const { uploadFiles, setUploadFiles } = useAppContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {uploadFiles?.map((file) => {
        return (
          <UploadNotification
            key={file?.file?.name}
            file={file}
            onClick={(e) => {
              if (e.status === "uploading") return;
              setUploadFiles((prevFiles) =>
                prevFiles.filter(
                  (prevFile) => prevFile.file?.name !== e.file?.name
                )
              );
            }}
          />
        );
      })}
    </Box>
  );
}
