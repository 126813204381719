import { Box, Chip, Typography } from "@mui/material";
import { createCurrency, sortArrayBy } from "../../utils";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

export function prepareChartData(transactions) {
  if (!transactions) {
    return [];
  }
  const sortedByDate = transactions
    .map((transaction) => {
      return {
        date: transaction.date.seconds,
        price: transaction.price,
      };
    })
    .sort((a, b) => a.date - b.date);

  const sortedPrices = sortedByDate.map((transaction) => transaction.price);
  if (sortedPrices.length === 1) {
    return [sortedPrices[0], sortedPrices[0]];
  }
  return sortedPrices;
}

export const PriceDiff = ({ label, diff }) => {
  if (Math.abs(diff) < 1) {
    return;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Chip
        size="small"
        icon={diff < 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        label={`%${Math.abs(diff).toFixed(0)}`}
        sx={{
          backgroundColor: diff > 0 ? "#deffde" : "#ffcece",
          fontSize: "0.7rem",
          "& .MuiChip-label": {
            paddingLeft: 0,
          },
          marginRight: 1,
        }}
      />
      <Typography
        sx={{
          fontSize: "0.7rem",
          paddingRight: "0.2rem",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const PriceLineChart = ({ data, priceChange }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <SparkLineChart
        data={data}
        height={30}
        width={50}
        colors={[priceChange > 0 ? "red" : "green"]}
      />
      <Typography
        sx={{
          position: "absolute",
          bottom: `calc(50% - 0.6rem)`,
          left: 50,
          padding: 0,
          fontSize: "0.8rem",
        }}
      >
        {`%${Math.abs(priceChange).toFixed()}`}
      </Typography>
    </Box>
  );
};

const gridSx = { display: { xs: "none", md: "none", lg: "block" } };

export const header = [
  { xs: 6, md: 6, lg: 2, key: "productName", label: "Ürün Adı" },
  { xs: 4, md: 4, lg: 1, key: "priceChange", label: "Değişim" },
  { xs: 2, md: 1, key: "lastPrice", label: "Fiyat" },
  { xs: 4, key: "lowestPrice", label: "En Ucuz", sx: gridSx },

  {
    xs: 1,
    key: "discount",
    label: "İskonto",
    sx: gridSx,
  },
  {
    xs: 1,
    key: "totalAmount",
    label: "Ara Toplam",
    sx: gridSx,
  },
  {
    xs: 1,
    key: "totalTax",
    label: "Kdv",
    sx: gridSx,
  },
  {
    xs: 3,
    md: 1,
    lg: 1,
    key: "total",
    label: "Toplam",
    sx: gridSx,
  },
];

export const listItems = (items, alternativePrices) => {
  return [
    {
      xs: 6,
      md: 6,
      lg: 2,
      label: (key) => key,
    },
    {
      xs: 4,
      md: 4,
      lg: 1,
      component: (key) => {
        return (
          <PriceLineChart
            data={prepareChartData(items?.[key]?.purchases)}
            priceChange={items?.[key]?.priceChange}
          />
        );
      },
    },
    {
      xs: 1,
      label: (id) => createCurrency(items?.[id]?.lastPrice).format(),
    },
    {
      sx: gridSx,
      xs: 4,

      component: (id) => {
        const item = items?.[id];
        const productName = item?.productName;
        const lastUnitPrice = item?.lastUnitPrice;
        const lowestUnitPrice =
          alternativePrices?.[productName]?.lowestUnitPrice;

        const price =
          alternativePrices?.[productName]?.lowestPrice *
          alternativePrices?.[productName]?.unitWeight;

        const diffPercentage =
          100 * (1 - (lowestUnitPrice || lastUnitPrice) / lastUnitPrice);

        return (
          <PriceDiff
            diff={diffPercentage}
            label={createCurrency(price)?.format()}
          />
        );
      },
    },

    {
      sx: gridSx,
      xs: 1,
      label: (id) => createCurrency(items?.[id]?.discount).format(),
    },
    {
      sx: gridSx,
      xs: 1,
      label: (id) => createCurrency(items?.[id]?.subTotal).format(),
    },
    {
      sx: gridSx,
      xs: 1,
      label: (id) => createCurrency(items?.[id]?.tax).format(),
    },
    {
      sx: gridSx,
      xs: 3,
      md: 1,
      lg: 1,
      label: (id) => createCurrency(items?.[id]?.total).format(),
    },
  ];
};
