import { Link, Navigate, useParams } from "react-router-dom";

import React from "react";
import { blogPosts } from "./blogPosts";
import { useTranslation } from "react-i18next";

const BlogIndex = () => {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();

  const getBaseLanguage = (langCode) => {
    return langCode ? langCode.split("-")[0] : "en";
  };

  const currentLang = lang
    ? getBaseLanguage(lang)
    : getBaseLanguage(i18n.language);

  if (!lang) {
    return <Navigate to={`/blog/${i18n.language}`} replace />;
  }

  if (currentLang !== getBaseLanguage(i18n.language)) {
    i18n.changeLanguage(currentLang);
  }

  const posts = blogPosts[currentLang] || [];

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#f3f4f6",
      minHeight: "100vh",
      padding: "1rem",
    },
    content: {
      width: "100%",
      maxWidth: "800px",
      backgroundColor: "white",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "0.5rem",
      overflow: "hidden",
    },
    header: {
      backgroundColor: "#1f2937",
      color: "white",
      padding: "1.5rem",
      textAlign: "center",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: 0,
    },
    list: {
      listStyle: "none",
      padding: "0",
      margin: 0,
    },
    listItem: {
      borderBottom: "1px solid #e5e7eb",
      transition: "background-color 0.3s",
      ":hover": {
        backgroundColor: "#f9fafb",
      },
    },
    postLink: {
      textDecoration: "none",
      color: "inherit",
      display: "block",
      padding: "1.5rem",
    },
    postContent: {
      display: "flex",
      flexDirection: "column",
    },
    imageContainer: {
      marginBottom: "1rem",
    },
    image: {
      width: "100%",
      height: "200px",
      objectFit: "contain",
      borderRadius: "0.25rem",
    },
    postTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#1f2937",
      marginBottom: "0.5rem",
    },
    postMeta: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "0.875rem",
      color: "#6b7280",
    },
    author: {
      fontWeight: "bold",
    },
    description: {
      marginTop: "0.5rem",
      color: "#4b5563",
    },
    languageToggle: {
      padding: "1rem",
      textAlign: "center",
      backgroundColor: "#f3f4f6",
    },
    languageLink: {
      color: "#3b82f6",
      textDecoration: "none",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <header style={styles.header}>
          <h1 style={styles.title}>{t("blogPosts")}</h1>
        </header>
        <ul style={styles.list}>
          {posts.map((post) => (
            <li key={post.id} style={styles.listItem}>
              <Link
                to={`/blog/${currentLang}/${post.slug}`}
                style={styles.postLink}
              >
                <div style={styles.postContent}>
                  {post.image && (
                    <div style={styles.imageContainer}>
                      <img
                        src={post.image}
                        alt={post.title}
                        style={styles.image}
                      />
                    </div>
                  )}
                  <h2 style={styles.postTitle}>{post.title}</h2>
                  <div style={styles.postMeta}>
                    <span style={styles.author}>{post.author}</span>
                    {post.date && (
                      <span>
                        {new Date(post.date).toLocaleDateString(currentLang, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </span>
                    )}
                  </div>
                  {post.description && (
                    <p style={styles.description}>{post.description}</p>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogIndex;
