import "react-day-picker/dist/style.css";

import React, { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { DayPicker } from "react-day-picker";
import Dialog from "./Dialog";
import Input from "./Input";
import { Timestamp } from "firebase/firestore"; // Import Firestore Timestamp
import { tr } from "date-fns/locale";

export default function CustomDatePicker({
  title,
  date,
  onDateChange,
  titleIcon,
  disabled,
}) {
  const [selectedDay, setSelectedDay] = useState(new Date());

  useEffect(() => {
    if (date) {
      setSelectedDay(new Date(date?.seconds * 1000));
    } else {
      setSelectedDay(new Date());
    }
  }, [date]);

  const [newDate, setNewDate] = useState();

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const pickerRef = useRef();

  const handleConfirm = () => {
    const firebaseTimestamp = Timestamp.fromDate(newDate || selectedDay);
    onDateChange(firebaseTimestamp);
    setSelectedDay(newDate);
    setIsPickerOpen(false);
  };

  return (
    <div ref={pickerRef} style={{ position: "relative" }}>
      <div onClick={() => setIsPickerOpen(true)}>
        <Input
          title={title}
          disabled={disabled}
          titleIcon={titleIcon}
          value={selectedDay?.toLocaleDateString("tr-TR")}
        />
      </div>
      <Dialog
        open={isPickerOpen}
        maxWidth={"xs"}
        onConfirm={handleConfirm}
        onClose={() => setIsPickerOpen(false)}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <DayPicker
            mode="single"
            selected={newDate || selectedDay}
            onDayClick={(v) => setNewDate(v)}
            locale={tr}
            month={newDate || selectedDay}
            onMonthChange={(e) => setNewDate(e)}
          />
        </Box>
      </Dialog>
    </div>
  );
}
