import {
  AttachMoney,
  Inventory,
  LocalOffer,
  PercentOutlined,
  ShoppingCart,
} from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";

import { ItemLineChart } from "../../components";
import React from "react";
import { createCurrency } from "../../utils";
import { t } from "i18next";
import { useNavigate } from "react-router";

const ListItem = ({ icon, label, value, valueStyle, labelStyle }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      py: 1,
      borderBottom: "1px solid",
      borderColor: "divider",
      "&:last-child": { borderBottom: "none" },
    }}
  >
    {icon}
    <Typography sx={{ ml: 2, flexGrow: 1, ...labelStyle }}>{label}</Typography>
    <Typography sx={valueStyle}>{value}</Typography>
  </Box>
);

const ProductCard = ({ product, history }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "md",
        mx: "auto",
        background:
          "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
        borderBottom: "1px solid lightgray",
        paddingY: 1,
      }}
      onClick={() =>
        navigate(`/overview/product/${product?.productName}`, {
          state: { name: product?.productName },
        })
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingX: 1,
          opacity: history ? 1 : 0.3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar
            variant="rounded"
            src={product?.imgUrl}
            alt={product.productName}
            sx={{ width: 64, height: 64 }}
          >
            {product?.productName?.slice(0, 1)}
          </Avatar>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
              {product.productName}
            </Typography>

            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              {createCurrency(product.price).format()}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "6rem",
            height: "4rem",
            display: "flex",
          }}
        >
          {history?.priceHistory?.length > 0 ? (
            <ItemLineChart
              purchases={[
                history?.priceHistory?.[0],
                ...history?.priceHistory,
              ]?.map((i) => {
                return {
                  price: i,
                };
              })}
            />
          ) : (
            <Typography sx={{ fontSize: "0.8rem" }}>
              {t("No purchases in date range")}
            </Typography>
          )}
        </Box>
      </Box>
      {history?.totalQuantity && (
        <Box sx={{ padding: 2, paddingBottom: 0 }}>
          <Box>
            <ListItem
              icon={<Inventory sx={{ fontSize: 18 }} />}
              label={t("totalQuantity")}
              value={history?.totalQuantity.toFixed(2) + " " + history?.unit}
              valueStyle={{ fontSize: 14 }}
              labelStyle={{ fontSize: 14 }}
            />
            <ListItem
              icon={<LocalOffer sx={{ fontSize: 18 }} />}
              label={t("subtotal")}
              value={createCurrency(history?.totalSubTotal).format()}
              valueStyle={{ fontSize: 14 }}
              labelStyle={{ fontSize: 14 }}
            />
            <ListItem
              icon={<PercentOutlined sx={{ fontSize: 18 }} />}
              label={t("totalDiscount")}
              value={createCurrency(history?.totalDiscount).format()}
              valueStyle={{ color: "red", fontSize: 12 }}
              labelStyle={{ fontSize: 12 }}
            />
            <ListItem
              icon={<AttachMoney sx={{ fontSize: 18 }} />}
              label={t("totalVat")}
              value={createCurrency(history?.totalVat).format()}
              valueStyle={{ color: "gray", fontSize: 12 }}
              labelStyle={{ fontSize: 12 }}
            />
            <ListItem
              icon={<ShoppingCart sx={{ fontSize: 18 }} />}
              label={t("totalPrice")}
              value={createCurrency(history?.totalAmount).format()}
              valueStyle={{ fontWeight: "bold", fontSize: 16 }}
              labelStyle={{ fontSize: 16 }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProductCard;
