import { Avatar, Box, Divider, TextField, Typography } from "@mui/material";
import { Creatable, Dialog, Input } from "../components";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  limit,
  query,
  setDoc,
} from "firebase/firestore";
import { categories, subCategories, units } from "../constants/categories";
import { useEffect, useState } from "react";

import algoliasearch from "algoliasearch/lite";
import { db } from "../firebase";
import { useProductContext } from "../context";
import { useTranslation } from "react-i18next";

export default function ProductDialog({ selectedProduct, setSelectedProduct }) {
  const [data, setData] = useState();
  const { productList, brands, categoryList } = useProductContext();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (selectedProduct) {
      setData(selectedProduct);
    }
  }, [selectedProduct]);

  function searchProducts(value) {
    const searchClient = algoliasearch(
      "LM6U4D5EAY",
      "1fda51e86437cff7f75b96e001b71da7"
    );
    const search = searchClient.initIndex("products");
    search.search(value).then(({ hits }) => {
      setProducts(hits);
      const results = hits.map((o) => o.productName);
      // make unique
      setSearchResults([...new Set(results)]);
    });
  }

  function copyProduct(e) {
    const product = products.find((o) => o.productName === e.target.value);
    if (product) {
      setData({
        category: product?.category,
        aiDriven: product?.aiDriven,
        unitWeight: product?.unitWeight,
        subCategory: product?.subCategory,
        brandName: product?.brandName,
        productName: data?.productName,
        unit: product?.unit,
        updatedAt: product?.updatedAt,
        genericName: product?.genericName,
        id: data?.id,
        name: data?.name || product?.name,
      });
    }
  }

  function handleConfirm() {
    updateProduct(data);
    setSelectedProduct(null);
  }

  async function updateProduct(product) {
    try {
      const productRef = doc(db, "products", selectedProduct?.id);
      await setDoc(
        productRef,
        {
          ...product,
          aiDriven: true,
          updatedAt: Timestamp.fromDate(new Date()),
        },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Dialog
      open={Boolean(selectedProduct?.id)}
      onClose={() => {
        setSelectedProduct(null);
      }}
      onConfirm={handleConfirm}
      confirmButton={t("confirm")}
    >
      <Creatable
        onChange={(e) => {
          searchProducts(e.target.value);
        }}
        onBlur={copyProduct}
        options={searchResults?.map((o) => ({ title: o }))}
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Ürün Değiştir
          </Typography>
        }
      />
      <Input
        titleSx={{ minWidth: "5.2rem" }}
        title="id"
        value={data?.id || data?.objectID || ""}
        disabled
      />
      <Input
        titleSx={{ minWidth: "5.2rem" }}
        title="Ürün Adı"
        value={data?.name || ""}
        disabled
      />
      <Divider />

      <Creatable
        value={data?.productName || ""}
        options={productList?.map((o) => ({ title: o }))}
        onBlur={(e) => setData({ ...data, productName: e.target.value })}
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Ürün Adı
          </Typography>
        }
      />
      <Creatable
        value={data?.genericName || ""}
        options={productList?.map((o) => ({ title: o }))}
        onBlur={(e) => setData({ ...data, genericName: e.target.value })}
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Genel Adı
          </Typography>
        }
      />
      <Creatable
        value={data?.brandName || ""}
        options={brands?.map((o) => ({ title: o }))}
        onBlur={(e) => setData({ ...data, brandName: e.target.value })}
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Marka
          </Typography>
        }
      />

      <Divider />

      <Creatable
        value={data?.category || ""}
        options={categoryList?.category?.map((o) => ({ title: o }))}
        onBlur={(e) => setData({ ...data, category: e.target.value })}
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Kategori
          </Typography>
        }
      />
      <Creatable
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Alt Kategori
          </Typography>
        }
        value={data?.subCategory || ""}
        options={categoryList?.subCategory?.map((o) => ({ title: o }))}
        onBlur={(e) => setData({ ...data, subCategory: e.target.value })}
      />

      <Divider />

      <Creatable
        value={data?.unit || ""}
        options={units?.map((o) => ({ title: o }))}
        onBlur={(e) => setData({ ...data, unit: e.target.value })}
        icon={
          <Typography sx={{ minWidth: "6rem", fontSize: "0.8rem" }}>
            Birim
          </Typography>
        }
      />
      <Input
        title="Birim Ağırlığı"
        value={data?.unitWeight || ""}
        onChange={(e) => setData({ ...data, unitWeight: e.target.value })}
        titleSx={{ minWidth: "5.2rem" }}
      />
    </Dialog>
  );
}
