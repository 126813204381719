import { Box, Grid } from "@mui/material";

import PageCard from "./PageCard";

export default function PageFilter({ cards, totalsRef }) {
  return (
    <Grid ref={totalsRef} container spacing={0} sx={{ marginBottom: 1 }}>
      {cards?.map((c, i) => {
        return <PageCard key={i} item={c} />;
      })}
    </Grid>
  );
}
