import { Box, Button, Card, CardContent, Typography } from "@mui/material";

import React from "react";
import logo from "../../assets/logo.png";
import { styled } from "@mui/system";

const SlidingContainer = styled("div")({
  display: "flex",
  width: "calc(280px * 10)",
  animation: "slide 40s linear infinite",
  "&:hover": {
    animationPlayState: "paused",
  },
  "@keyframes slide": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(calc(-280px * 5))",
    },
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  width: "20vw",
  margin: "2px",
  borderRadius: 15,
  position: "relative",
  boxShadow: "0 2px 2px 2px rgba(0, 0, 0, .1)",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 2px 2px 2px rgba(0, 0, 0, .1)",
    "& .exploreButton": {
      opacity: 1,
    },
  },
  backgroundColor: "#2b2b2b",
}));

const SlidingCards = () => {
  const cards = [
    {
      id: 1,
      title: "Upload Invoice",
      content: "Effortless Invoice Handling",
      content2: "Automated Data Extraction",
    },
    {
      id: 2,
      title: "Overview",
      content: "Smart Order Lists",
      content2: "Comprehensive Search and Filter",
    },
    {
      id: 3,
      title: "Shopping Cart",
      content: "Company and User Management",
      content2: "Collaborative Ordering",
    },
    {
      id: 4,
      title: "Analytics",
      content: "Real-Time Analytics",
      content2: "Usage Insights",
    },
    {
      id: 5,
      title: "Explore",
      content: "Vendor Exploration",
      content2: "Offer Management",
    },
  ];

  return (
    <Box sx={{ width: "100%", overflow: "hidden", paddingBottom: 2 }}>
      <SlidingContainer>
        {[...cards, ...cards].map((card, index) => (
          <StyledCard key={`${card.id}-${index}`}>
            <CardContent>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  paddingTop: 0.4,
                  paddingX: 0.6,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderTopLeftRadius: 15,
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: 18,
                    height: 18,
                    paddingBottom: 6,
                    paddingRight: 4,
                  }}
                />
                <Typography sx={{ fontWeight: "500" }} gutterBottom>
                  {card.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 4,
                }}
              >
                <Typography
                  sx={{ color: "#fff" }}
                  variant="body2"
                  color="text.secondary"
                >
                  {card.content}
                </Typography>
                <Typography
                  sx={{ color: "#fff" }}
                  variant="body2"
                  color="text.secondary"
                >
                  {card.content2}
                </Typography>
              </Box>
            </CardContent>
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 10,
                top: 0,
                opacity: 0,
                backgroundColor: "rgba(0,0,0,0.3)",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
              className="exploreButton"
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2b2b2b",
                  alignSelf: "flex-start",
                  borderRadius: 10,
                  color: "#fff",
                  textTransform: "none",

                  "&:hover": {
                    backgroundColor: "#2b2b2b",
                  },
                }}
              >
                Sign up for free
              </Button>
            </Box>
          </StyledCard>
        ))}
      </SlidingContainer>
    </Box>
  );
};

export default SlidingCards;
