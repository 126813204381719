import React, { useCallback, useEffect, useState } from "react";

import { Button } from "@mui/material";
import logo from "../../assets/logo.png";

const TypeWriter = ({ children, onTypingEnd }) => {
  const [text, setText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < children.length) {
      const timer = setTimeout(() => {
        setText((prevText) => prevText + children[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, Math.random() * 40 + 10); // Random delay between 10ms and 50ms

      return () => clearTimeout(timer);
    } else if (currentIndex === children.length) {
      onTypingEnd();
    }
  }, [children, currentIndex, onTypingEnd]);

  return <span>{text}</span>;
};

const EmptyState = ({
  header,
  textLines,
  customStyle,
  onButtonPress,
  buttonLabel,
}) => {
  const [currentLine, setCurrentLine] = useState(0);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [allLinesTyped, setAllLinesTyped] = useState(false);

  const handleTypingEnd = useCallback(() => {
    setIsTypingComplete(true);
  }, []);

  useEffect(() => {
    if (isTypingComplete) {
      if (currentLine < textLines.length - 1) {
        const timer = setTimeout(() => {
          setCurrentLine((prevLine) => {
            return prevLine + 1;
          });
          setIsTypingComplete(false);
        }, 500);
        return () => clearTimeout(timer);
      } else {
        setAllLinesTyped(true);
      }
    }
  }, [currentLine, isTypingComplete, textLines.length]);

  return (
    <div style={{ padding: 10, ...customStyle }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: 10,
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                width: 20,
                height: 20,
                objectFit: "contain",
                marginRight: 5,
              }}
            />
            <span style={{ fontWeight: "bold", fontSize: 16 }}>{header}</span>
          </div>

          {textLines.map((line, index) => (
            <div key={index} style={{ fontSize: 14, margin: "10px 0" }}>
              {index === currentLine && (
                <TypeWriter onTypingEnd={handleTypingEnd}>{line}</TypeWriter>
              )}
              {index < currentLine && <span>{line}</span>}
            </div>
          ))}
        </div>
      </div>
      {allLinesTyped && onButtonPress && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2b2b2b",
            alignSelf: "flex-start",
            borderRadius: 10,
            color: "#fff",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#2b2b2b",
            },
          }}
          onClick={onButtonPress}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
