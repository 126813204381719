import * as React from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: "rgba(0, 0, 0, 0.6)",
  "&.Mui-selected": {
    color: "#1b1b1b",
  },
  minWidth: "33%",
}));

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#1b1b1b",
  },
});

export default function BasicTabs({ tabs, tab = 0, setTab }) {
  const [value, setValue] = React.useState(tab);

  useEffect(() => {
    setValue(tab);
  }, [tab]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
          position: "sticky",
          top: 36,
          backdropFilter: "blur(12px)",
          webkitBackdropFilter: "blur(12px)",
          zIndex: 1200,
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            width: "100%",
          }}
        >
          {tabs.map((tab, index) => (
            <StyledTab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </StyledTabs>
      </Box>
      <Box
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {tabs?.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.children}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
}
