import { Box, Button, Typography } from "@mui/material";
import { KeyboardArrowRight, LockOpen } from "@mui/icons-material";

import React from "react";
import invoiceTake from "../../assets/invoice-take.png";
import overviewGroup from "../../assets/overview-group.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InvoiceParser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #F2F2F2, #FFFFFF)",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: {
          lg: 0,
          md: 5,
          xs: 16,
        },
        paddingBottom: {
          lg: 0,
          md: 20,
          xs: 40,
        },
      }}
    >
      <Box
        sx={{
          width: "80%",
        }}
      >
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 600,
            color: "#2b2b2b",
            alignSelf: "flex-start",
            padding: 4,
            paddingLeft: 0,
          }}
        >
          {t("automatedDataExtraction")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          width: "80%",
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(to bottom, #1b1b1b, #3b3b3b)",
            width: {
              lg: "25%",
              md: "40%",
              sm: "100%",
              xs: "100%",
            },
            borderRadius: "20px",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                padding: 4,
                paddingBottom: 0,
                alignSelf: "flex-start",
                fontSize: 16,
              }}
            >
              {t("simple")}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                paddingLeft: 4,
                alignSelf: "flex-start",
                fontSize: 22,
                fontWeight: 600,
              }}
            >
              {t("takePhotoInvoice")}
            </Typography>
          </Box>
          <img
            src={invoiceTake}
            alt="Invoice Detail"
            style={{
              maxWidth: "200px",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box
          sx={{
            background: "white",
            height: "100%",
            width: {
              lg: "75%",
              md: "60%",
              sm: "100%",
              xs: "100%",
              borderRadius: "20px",
            },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                padding: 4,
                paddingBottom: 0,
                alignSelf: "flex-start",
                fontSize: 16,
              }}
            >
              <LockOpen
                sx={{
                  color: "#2b2b2b",
                  fontSize: 24,
                }}
                size="large"
              />
            </Typography>
            <Typography
              sx={{
                paddingLeft: 4,
                fontSize: 24,
                fontWeight: 600,
                color: "#2b2b2b",
              }}
            >
              {t("captureInstantly")}
            </Typography>

            <Button
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: 20,
              }}
              endIcon={<KeyboardArrowRight />}
              onClick={() => navigate("/data-extraction")}
            >
              {t("seeMore")}
            </Button>
          </Box>
          <img
            src={overviewGroup}
            alt="Invoice Detail"
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "20px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceParser;
