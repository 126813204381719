import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

export default function InvoicePreview({ img, contentType, sx, onClick }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const imgElement = new Image();
    imgElement.src = img;
    imgElement.onload = () => setLoading(false);
  }, [img]);

  if (!img)
    return (
      <div
        style={{
          ...sx,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>{t("selectImageToPreview")}</p>
      </div>
    );

  if (loading) {
    return (
      <div
        style={{
          ...sx,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>{t("loading")}</p>
      </div>
    );
  }

  if (contentType === "image/png") {
    return (
      <div style={sx} onClick={onClick}>
        <img
          src={img}
          style={{
            ...sx,
          }}
        />
      </div>
    );
  }

  return (
    <div style={sx} onClick={onClick}>
      <embed
        src={img}
        type={contentType}
        style={{
          ...sx,
          maxWidth: "100%",
          display: "block",
          objectFit: "fill",
          borderRadius: "15px",
        }}
      />
    </div>
  );
}
