import {
  CalendarMonth as CalendarMonthIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";

import DatePicker from "../../DatePicker";
import Input from "../../Input";

export default function InvoiceInfo({ fields, setInvoice }) {
  return (
    <div>
      <DatePicker
        titleIcon={<CalendarMonthIcon />}
        date={fields?.InvoiceDate?.value}
        onDateChange={(date) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              InvoiceDate: {
                ...prev.fields.InvoiceDate,
                confidence: 1,
                value: date,
              },
            },
          }))
        }
      />
      {/* fatura no */}
      <Input
        titleIcon={<ReceiptIcon />}
        value={fields?.InvoiceId?.value || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              InvoiceId: {
                ...prev.fields.InvoiceId,
                confidence: 1,
                value: e.target.value,
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              InvoiceId: {
                ...prev.fields.InvoiceId,
                confidence: 1,
                value: e.target.value,
              },
            },
          }))
        }
      />
    </div>
  );
}
