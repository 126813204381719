import { persistReducer, persistStore } from "redux-persist";

import { combineReducers } from "redux";
import companyReducer from "./redux/Company/CompanySlice";
import { configureStore } from "@reduxjs/toolkit";
import dateRangeReducer from "./redux/App/DateRangeSlice";
import filterReducer from "./actions/FilterActions";
import invoiceReducer from "./redux/Invoices/invoiceSlice";
import orderReducer from "./redux/Orders/OrderSlice";
import overViewReducer from "./redux/App/OverViewSlice";
import purchaseReducer from "./redux/Purchases/PurchaseSlice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userMiddleware from "./redux/User/userMiddleware";
import userReducer from "./redux/User/userSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"], // only persist user state
};

const rootReducer = combineReducers({
  filter: filterReducer,
  company: companyReducer,
  invoices: invoiceReducer,
  overView: overViewReducer,
  purchases: purchaseReducer,
  orders: orderReducer,
  user: userReducer,
  dateRange: dateRangeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(userMiddleware),
});

export const persistor = persistStore(store);
