import {
  Timestamp,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "../../firebase";

export default async function fetchProductPurchases({
  product,
  user,
  filterByDate,
  startDate,
  endDate,
}) {
  let purchaseQuery = query(collection(db, "purchases"));
  if (user?.role !== "Admin") {
    if (user?.companyId) {
      purchaseQuery = query(
        purchaseQuery,
        where("customerId", "==", user.companyId)
      );
    } else {
      purchaseQuery = query(purchaseQuery, where("userUid", "==", user.uid));
    }
  }

  purchaseQuery = query(purchaseQuery, where("productName", "==", product));

  if (filterByDate && startDate && endDate) {
    purchaseQuery = query(
      purchaseQuery,
      where(
        "date.seconds",
        ">=",
        Timestamp.fromDate(new Date(startDate)).seconds
      ),
      where("date.seconds", "<=", Timestamp.fromDate(new Date(endDate)).seconds)
    );
  }
  purchaseQuery = query(purchaseQuery, orderBy("date.seconds", "desc"));

  const querySnapshot = await getDocs(purchaseQuery);
  let purchases = [];
  querySnapshot.forEach((doc) => {
    purchases.push({ ...doc.data(), docId: doc.id });
  });

  return { purchases };
}
