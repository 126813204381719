import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { KeyboardArrowRight } from "@mui/icons-material";
import React from "react";
import companyProfile from "../../assets/company-profile.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CompanyManagement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #FFFFFF ,#F2F2F2)",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          height: "100%",
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //
            // flexDirection: {
            // lg: "column",
            // md: "column",
            // sm: "row",
            // xs: "row",
            // },
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 36,
              fontWeight: 600,
              color: "#2b2b2b",
              alignSelf: "flex-start",
              padding: 4,
              paddingLeft: 0,
            }}
          >
            {t("companyManagement.title")}
          </Typography>
          <Typography sx={{ fontSize: 24, fontWeight: 600, color: "gray" }}>
            {t("companyManagement.description")}
          </Typography>
          <Button
            sx={{
              // alignSelf: "flex-start",
              borderRadius: 10,
              // color: "#2b2b2b",
              textTransform: "none",
              fontSize: 20,
              alignSelf: "flex-start",
              paddingLeft: 0,
              marginTop: 4,
            }}
            endIcon={<KeyboardArrowRight />}
            onClick={() => navigate("/collaborative-ordering")}
          >
            {t("companyManagement.getStarted")}
          </Button>
        </Box>
        {/* <Box> */}
        <img
          src={companyProfile}
          alt="Invoice Detail"
          style={{
            maxWidth: isMobile ? "100%" : "60%",
            width: "100%",
            objectFit: "contain",
            opacity: isMobile ? "0.2" : 1,
            position: isMobile ? "absolute" : "relative",
            right: 0,
            top: isMobile ? "30vh" : 0,
          }}
        />
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default CompanyManagement;
