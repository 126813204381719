import { createSlice } from "@reduxjs/toolkit";

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
const initialState = {
  dateRange: {
    dateRangeDialog: false,
    startDate: thirtyDaysAgo.toISOString(),
    endDate: new Date().toISOString(),
  },
};

const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    toggleDateRangeDialog: (state) => {
      state.dateRange.dateRangeDialog = !state.dateRange.dateRangeDialog;
    },
    setDate: (state, action) => {
      state.dateRange.startDate = action.payload.startDate;
      state.dateRange.endDate = action.payload.endDate;
    },
  },
});

export const { toggleDateRangeDialog, setDate } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
