import { Box, Typography } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";

import { Table } from "../../components";
import { db } from "../../firebase";
import { useAppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SearchCompanies({ companiesRef, companies }) {
  const navigate = useNavigate();
  const { favorites } = useAppContext();
  const [rows, setRows] = useState([]);
  const user = useSelector((state) => state.user.userDoc);

  useEffect(() => {
    const sorted = Object.keys(companies || {})?.map((key) => {
      const company = companies[key];
      return {
        id: key,
        companyName: company?.name,
      };
    });

    setRows(sorted);
  }, [companies]);

  async function handleFavorites(e) {
    try {
      const { id, name } = e;
      const favorite = favorites.find((f) => f.primary === e.name);
      if (favorite) {
        await deleteDoc(doc(db, "favorites", favorite?.docId));
      } else {
        await addDoc(collection(db, "favorites"), {
          userUid: user?.uid,
          type: "company",
          primary: name,
          id: id,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px solid #dfdeda",
        borderRadius: 4,
        padding: 2,
      }}
    >
      <Typography
        sx={{ fontWeight: "bold", fontSize: "1.6rem" }}
        ref={companiesRef}
      >
        Firmalar
      </Typography>
      <Table
        rows={rows}
        items={[
          {
            xs: 11,
            label: (id) => companies[id]?.name,
          },
          {
            xs: 1,
            component: (id) => {
              if (favorites?.map((f) => f.primary).includes(id)) {
                return (
                  <Star
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavorites(companies[id]);
                    }}
                    sx={{ color: "orange" }}
                  />
                );
              }
              return (
                <StarBorder
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavorites(companies[id]);
                  }}
                  sx={{ color: "orange" }}
                />
              );
            },
          },
        ]}
        onClick={(id) => {
          navigate(`/companies/${companies[id]?.id}`);
        }}
      />
    </Box>
  );
}
