import { Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const gridSx = {
  alignItems: "center",
  display: "flex",
};

const Header = ({ onSort, header }) => {
  return (
    <Grid
      container
      sx={{
        height: "2rem",
        cursor: "pointer",
        alignItems: "center",
      }}
    >
      {header?.map((item) => {
        return (
          <Grid
            key={item?.label}
            item
            sx={{ ...gridSx, ...item.sx }}
            xs={item?.xs}
            md={item?.md}
            lg={item?.lg}
            onClick={() => onSort(item?.key)}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "0.75rem",
              }}
              variant="subtitle2"
            >
              {item.label}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

function getStatusColor(status) {
  return status === "Pending"
    ? "warning"
    : status === "Rejected"
    ? "error"
    : status === "rejected"
    ? "error"
    : status === "admin-approved"
    ? "success"
    : status === "user-approved"
    ? "success"
    : status === "deleted"
    ? "error"
    : "info";
}

export default function Table({
  rows,
  onSort,
  header,
  items,
  onClick,
  onProductClick,
  editIcon = false,
}) {
  return (
    <>
      {header && <Header onSort={onSort} header={header} />}
      {rows?.map((row, i) => {
        return (
          <Grid
            key={i}
            container
            sx={{
              height: "2.4rem",
              cursor: "pointer",
              borderBottom: rows?.length - 1 === i ? "" : "1px solid #dfdeda",
              position: "relative",
              backgroundColor: row?.adminHighLight,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#f4f2ee";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                row?.adminHighLight || "white";
            }}
            onClick={() => onClick(row.id)}
          >
            <FiberManualRecordIcon
              sx={{
                display: row?.status ? "block" : "none",
                position: "absolute",
                left: -12,
                fontSize: "0.8rem",
                top: `calc(50% - 0.25rem)`,
              }}
              color={getStatusColor(row?.status)}
            />
            <EditIcon
              sx={{
                display: editIcon ? "block" : "none",
                position: "absolute",
                left: -10,
                fontSize: "0.8rem",
                top: `calc(50% - 0.25rem)`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onProductClick(row?.productId);
              }}
            />
            {items?.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  sx={{ ...gridSx, ...item?.sx }}
                  xs={item?.xs}
                  md={item?.md}
                  lg={item?.lg}
                >
                  {item?.component ? (
                    item.component(row.id)
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        paddingRight: "0.2rem",
                      }}
                    >
                      {item.label(row.id)}
                    </Typography>
                  )}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </>
  );
}
