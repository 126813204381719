import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import {
  DesktopNavigation,
  OrderForms,
  PageHeader,
  PastOrders,
  ShoppingLists,
  ShoppingNavigation,
  ShoppingOrders,
  Tabs,
} from "../components";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState, useTransition } from "react";
import { useOrderContext, useProductContext } from "../context";

import appleStore from "../assets/appleStore.png";
import { db } from "../firebase";
import googleStore from "../assets/googleStore.png";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ShoppingCart() {
  const user = useSelector((state) => state.user.userDoc);

  const { groupedOrders } = useOrderContext();
  const { orderList } = useProductContext();
  const [activeSection, setActiveSection] = useState("");
  const navigate = useNavigate();
  const orderFormsRef = useRef(null);
  const shoppingCartRef = useRef(null);
  const ordersRef = useRef(null);
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const handleScroll = (ref) => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 100;
      window.scrollTo({
        top: top - offset,
        behavior: "smooth",
      });
    }
  };

  const Test = () => {
    return <Box>sex</Box>;
  };

  useEffect(() => {
    const handleScrollEvent = () => {
      const checkActiveSection = (ref, name) => {
        const element = ref.current;
        const scrollPosition = window.scrollY + 200;
        if (scrollPosition >= element?.offsetTop) {
          setActiveSection(name);
        }
      };
      checkActiveSection(shoppingCartRef, "shoppingCart");
      checkActiveSection(orderFormsRef, "orderForms");
      checkActiveSection(ordersRef, "orders");
    };
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, [orderFormsRef, shoppingCartRef, ordersRef]);

  const sendWhatsappMessage = (products, phone) => {
    if (!products || Object.keys(products).length === 0) {
      console.error("No products to send.");
      return;
    }

    let message = "*Sipariş Listesi:*\n"; // Bold only for the title
    message += "```";
    message += Object.keys(products)
      .map((product, index) => {
        const orderCount = products[product]?.orderCount || 0;
        const unit = products[product]?.unit || "Adet";
        return `${index + 1}.  ${product} ${orderCount} ${unit}`;
      })
      .join("\n");
    message += "```"; // Close the monospace formatting

    const url = `https://wa.me/9${phone}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  async function handleOrderConfirm({ vendor, products }) {
    try {
      let vendorQuery = query(
        collection(db, "companyDetails"),
        where("name", "==", vendor)
      );
      let vendorDocs = await getDocs(vendorQuery);
      let vendorDoc = vendorDocs.docs[0];
      if (!vendorDoc) {
        throw new Error(`Vendor ${vendor} not found`);
      }
      let vendorData = vendorDoc.data();

      const orderTotal = Object.keys(products || {}).reduce((acc, product) => {
        const orderCount = products[product]?.orderCount || 0;
        const price = products[product]?.price || 0;
        return acc + orderCount * price;
      }, 0);

      let orderDoc = {
        products,
        vendorId: vendorData.taxid,
        vendorName: vendorData.name,
        phone: vendorData.phone || "",
        userId: user.uid,
        userName: user.displayName,
        companyId: user.companyId || user?.uid,
        companyName: user.companyName || user?.name,
        orderTotal: orderTotal,
      };

      await runTransaction(db, async (transaction) => {
        let orderQuery = query(
          collection(db, "orders"),
          where("companyId", "==", user.companyId),
          where("vendorName", "==", vendor)
        );
        let orderDocs = await getDocs(orderQuery);
        orderDocs.forEach((doc) => {
          transaction.delete(doc.ref);
        });

        const orderHistoryRef = doc(collection(db, "orderHistory"));
        transaction.set(orderHistoryRef, {
          ...orderDoc,
          status: "pending",
          createdAt: serverTimestamp(),
        });
      });

      sendWhatsappMessage(products, vendorData.phone);
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: {
            lg: "40%",
            md: "60%",
            sm: "100%",
            xs: "100%",
          },
          borderLeft: "1px solid lightgray",
          borderRight: "1px solid lightgray",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" />
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            margin: 2,
          }}
        >
          {t("downloadNative")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, margin: 2 }}>
          <IconButton
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.teklifappnative",
                "_blank"
              )
            }
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={googleStore}
              alt="Google Play Store"
              style={{
                height: 40,
                cursor: "pointer",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://apps.apple.com/tr/app/tedaarik/id6504186040",
                "_blank"
              )
            }
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={appleStore}
              alt="Apple App Store"
              style={{
                height: 40,
                cursor: "pointer",
              }}
            />
          </IconButton>
        </Box>
        {/* <Tabs
          tab={tab}
          tabs={[
            {
              id: 0,
              label: t("orders"),
              children: <ShoppingOrders />,
            },
            {
              id: 1,
              label: t("lists"),
              children: <ShoppingLists />,
            },
            {
              id: 1,
              label: t("history"),
              children: <PastOrders />,
            },
          ]}
        /> */}
      </Box>
    </Box>
  );
}
