import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Dialog } from "..";
import React from "react";
import { Verified } from "@mui/icons-material";
import categorizedOrders from "../../assets/categorizedOrders.png";
import categorizedProducts from "../../assets/categorizedProducts.png";
import orderHistory from "../../assets/orderHistory.png";
import orderShare from "../../assets/orderShare.png";
import { useTranslation } from "react-i18next";

const BulletPoints = ({ bullets }) => {
  const pairedBullets = bullets.reduce((result, item, index) => {
    if (index % 2 === 0) {
      result.push([item]);
    } else {
      result[result.length - 1].push(item);
    }
    return result;
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        gap: 4,
        width: "60%",

        paddingY: "5vh",
      }}
    >
      {pairedBullets.map((pair, rowIndex) => (
        <Box
          key={rowIndex}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
            width: "100%",
          }}
        >
          {pair.map((bullet, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: {
                  lg: "column",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: {
                  lg: "50%",
                  md: "50%",
                  sm: "100%",
                  xs: "100%",
                },
                paddingX: 2,
              }}
            >
              <Verified
                sx={{
                  color: "green",
                  fontSize: 32,
                  marginRight: 1,
                  marginTop: 0.5,
                  flexShrink: 0,
                }}
              />
              <Typography
                sx={{
                  color: "#1b1b1b",
                  fontSize: 24,
                  fontWeight: 600,
                  flexGrow: 1,
                }}
              >
                {bullet}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
const ImageBox = ({
  title,
  description,
  secondTitle,
  secondDescription,
  image,
  imageWidth = "30%",
  sx,
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: 8,
        paddingY: "10vh",
        ...sx,
        flexDirection: {
          lg: sx?.flexDirection || "row",
          md: sx?.flexDirection || "row",
          sm: "column",
          xs: "column",
        },
      }}
    >
      <Box
        sx={{
          width: {
            lg: "20%",
            md: "30%",
            sm: "80%",
            xs: "80%",
          },
          paddingTop: 4,
        }}
      >
        <Typography
          sx={{
            color: "gray",
            fontWeight: 600,
            fontSize: 24,
            width: "auto",
            flexShrink: 0,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#1b1b1b",
            fontSize: 20,
            fontWeight: 600,

            flexGrow: 1,
          }}
        >
          {description}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            fontWeight: 600,
            fontSize: 24,
            width: "auto",
            marginTop: 4,
            flexShrink: 0,
          }}
        >
          {secondTitle}
        </Typography>
        <Typography
          sx={{
            color: "#1b1b1b",
            fontSize: 20,
            fontWeight: 600,

            flexGrow: 1,
          }}
        >
          {secondDescription}
        </Typography>
      </Box>
      <img
        src={image}
        style={{
          width: isMobile ? "80%" : imageWidth,
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default function CollaborativeOrderingDialog() {
  const location = useLocation();
  console.log(location.pathname);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={Boolean(location.pathname === "/collaborative-ordering")}
      onClose={() => navigate("/")}
      title={""}
      maxWidth={"xl"}
      // bgColor="#1b1b1b"
      sx={{ padding: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: {
            md: 5,
            sm: 10,
            xs: 15,
          },
        }}
      >
        <Box
          sx={{
            minHeight: "60vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "75%",
            gap: 10,
            // background: "#1b1b1b",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                lg: 56,
                md: 52,
                sm: 48,
                xs: 44,
              },
              fontWeight: 600,
              color: "#1b1b1b",
              textAlign: "center",
              paddingLeft: 0,
            }}
          >
            {t("collaborativeOrdering.title")}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                lg: 48,
                md: 44,
                sm: 40,
                xs: 36,
              },
              fontWeight: 600,
              color: "gray",
              textAlign: "center",
              paddingLeft: 0,
            }}
          >
            {t("collaborativeOrdering.description")}
          </Typography>
        </Box>

        <BulletPoints
          title={t("keyFeatures")}
          bullets={[
            t("collaborativeOrdering.benefits.employeeManagement"),
            t("collaborativeOrdering.benefits.collectiveOrders"),
            t("collaborativeOrdering.benefits.automaticCategorization"),
            t("collaborativeOrdering.benefits.easySharing"),
            t("collaborativeOrdering.benefits.historicalInsights"),
          ]}
        />

        <ImageBox
          title={t("collaborativeOrdering.categorized.title")}
          description={t("collaborativeOrdering.categorized.description")}
          image={categorizedProducts}
        />

        <ImageBox
          title={t("collaborativeOrdering.vendorOrders.title")}
          description={t("collaborativeOrdering.vendorOrders.description")}
          image={categorizedOrders}
          imageWidth="40%"
          sx={{
            flexDirection: "row-reverse",
          }}
        />
        <ImageBox
          title={t("collaborativeOrdering.orderDetails.title")}
          description={t("collaborativeOrdering.orderDetails.description")}
          image={orderShare}
          secondTitle={t("collaborativeOrdering.sharing.title")}
          secondDescription={t("collaborativeOrdering.sharing.description")}
        />

        <ImageBox
          title={t("collaborativeOrdering.history.title")}
          description={t("collaborativeOrdering.history.description")}
          image={orderHistory}
          imageWidth="40%"
          sx={{
            flexDirection: "row-reverse",
          }}
        />
      </Box>
    </Dialog>
  );
}
