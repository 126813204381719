const PRODUCT_SCHEMA = {
  name: "",
  vatRate: 1,
  city: "Ankara",
  country: "Turkey",
  brandName: "Genel",
  category: "",
  subcategory: "",
  unit: "kg",
  unitWeight: 1,
  unitAmount: 1,
  genericName: "",
};

const PURCHASE_SCHEMA = {
  currency: "₺", // given
  customerId: "", // given
  date: "07-03-2024", // given
  discountPercentage: 0, // given
  productId: "", // given
  type: "purchase", // static
  publicProsal: null, // static
  proposalTo: null, // static
  purchaseAmount: 1, // given
  vendorId: "", // given
  price: 0, // given
  total: 0, // given
  unitPrice: 680.0, // calculated
  vatIncludedTotal: 4896.0, //calculated
};

const PRIVATE_PROPOSAL_SCHEMA = {
  productId: "", // given
  currency: "₺", // given
  type: "proposal", // given
  publicProsal: false, // given

  date: "07-03-2024", // given
  vendorId: "9041174064", // given
  proposalTo: ["0680629872"], // given
  price: 480.0, // given
  unitPrice: 480.0, // calculated
  discountPercentage: 0, // given
};

const PUBLIC_PROPOSAL_SCHEMA = {
  productId: "", // given
  currency: "₺", // given
  type: "proposal", // given
  publicProsal: true, // given

  date: "07-03-2024", // given
  vendorId: "9041174064", // given
  price: 480.0, // given
  unitPrice: 480.0, // calculated
};

export const COMPANY_SCHEMA = {
  name: "",
  url: "",
  companyId: "",
  description: "",
  logoUrl: "",
  employees: [],
  email: "",
  phoneNumber: "",
  country: "",
  city: "",
  address: "",
};
