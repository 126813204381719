import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { setCompanyPurchases } from "../../redux/Purchases/PurchaseSlice";

export async function fetchVendorPurchases({
  user,
  vendorName,
  dispatch,
  startDate,
  endDate,
  filterByDate,
}) {
  try {
    dispatch(setCompanyPurchases([]));
    const getVendorPurchases = httpsCallable(functions, "fetchVendorPurchases");

    const response = await getVendorPurchases({
      vendorName,
      startDate,
      endDate,
      filterByDate,
    });
    dispatch(setCompanyPurchases(response.data));
  } catch (error) {
    console.error("Error fetching company purchases:", error);
  }
}
