import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  categorizeByCategory,
  categorizeByGenericName,
  categorizeByProductName,
  chunkArray,
  prepareOrderList,
} from "../utils";

import { db } from "../firebase";
import { useAppContext } from "../context";
import { useSelector } from "react-redux";

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const { dateRange } = useAppContext();
  const user = useSelector((state) => state.user.userDoc);
  const admin = user?.role === "Admin";

  const [limit, setLimit] = useState({
    genericName: 5,
    productName: 5,
  });

  const [purchases, setPurchases] = useState({});
  const [productList, setProductList] = useState([]);
  const [alternativePrices, setAlternativePrices] = useState({});
  const { companies, products } = useSelector((state) => state?.filter);
  const [searchParams, setSearchParams] = useState({});
  const [categories, setCategories] = useState({});
  const [brands, setBrands] = useState([]);
  const [editProductId, setEditProductId] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [categoryList, setCategoryList] = useState({
    category: [],
    subCategory: [],
  });
  const [orderList, setOrderList] = useState({});

  useEffect(() => {
    if (user) {
      fetchPurchases();
    }
  }, [user, dateRange]);

  useEffect(() => {
    if (editProductId) {
      getEditProduct(editProductId);
    }
  }, [editProductId]);

  async function getEditProduct(editProductId) {
    try {
      const docRef = doc(db, "products", editProductId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setEditProduct({ ...docSnap.data(), id: docSnap.id });
      } else {
        setEditProduct({ id: editProductId });
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  }

  useEffect(() => {
    if (productList?.length) {
      fetchAlternativePrices();
    }
  }, [productList]);

  async function fetchPurchases() {
    try {
      let purchaseQuery = query(collection(db, "purchases"));

      if (!admin && user) {
        if (user?.companyId) {
          purchaseQuery = query(
            purchaseQuery,
            where("customerId", "==", user.companyId)
          );
        } else {
          purchaseQuery = query(
            purchaseQuery,
            where("userUid", "==", user.uid)
          );
        }
      }

      const { startDate, endDate } = dateRange;

      if (startDate && endDate) {
        purchaseQuery = query(
          purchaseQuery,
          where("date.seconds", ">=", Timestamp.fromDate(startDate).seconds),
          where("date.seconds", "<=", Timestamp.fromDate(endDate).seconds)
        );
      }

      onSnapshot(purchaseQuery, (querySnapshot) => {
        let purchases = [];
        querySnapshot.forEach((doc) => {
          purchases.push(doc.data());
        });

        const byProductName = categorizeByProductName(purchases);
        const byCategory = categorizeByCategory(purchases);
        const orderList = prepareOrderList(purchases);
        const genericNameList = purchases.map(
          (p) => p.genericName || "Undefined"
        );
        const productNameList = purchases.map(
          (p) => p.productName || "Undefined"
        );
        const vendorList = purchases.map((p) => p.vendorName || "Undefined");
        const categoryList = purchases.map((p) => p.category || "Undefined");
        const subCategoryList = purchases.map(
          (p) => p.subCategory || "Undefined"
        );
        const brandList = purchases.map((p) => p.brandName || "Undefined");

        const uniqueVendors = [...new Set(vendorList)];
        const uniqueCategories = [...new Set(categoryList)];
        const uniqueSubCategories = [...new Set(subCategoryList)];
        const uniqueGenericNames = [...new Set(genericNameList)];
        const uniqueProductNames = [...new Set(productNameList)];
        const uniqueNames = [
          ...new Set([...uniqueGenericNames, ...uniqueProductNames]),
        ];
        const uniqueBrands = [...new Set(brandList)];
        setSearchParams({
          companies: uniqueVendors,
          categories: uniqueCategories,
          subCategories: uniqueSubCategories,
          products: uniqueNames,
        });
        setProductList(uniqueNames);
        setPurchases(byProductName);
        setCategories(byCategory);
        setBrands(uniqueBrands);
        setCategoryList({
          category: uniqueCategories,
          subCategory: uniqueSubCategories,
        });
        setOrderList(orderList);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchAlternativePrices() {
    try {
      const chunkSize = 30;
      const productListChunks = chunkArray(productList, chunkSize);
      const [productName] = await Promise.all([
        // fetchAndCategorizeOffers(db, "genericName", productListChunks),
        fetchAndCategorizeOffers(db, "productName", productListChunks),
      ]);
      const merged = { ...productName };

      setAlternativePrices(merged);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchAndCategorizeOffers(db, key, chunks) {
    let allOffers = [];
    for (const chunk of chunks) {
      const { endDate } = dateRange;
      const weekAgo = new Date(endDate.getTime() - 1000 * 24 * 60 * 60 * 1000);

      let purchaseQuery = query(
        collection(db, "purchases"),
        where(key, "in", chunk),
        where("date.seconds", ">=", weekAgo.getTime() / 1000),
        where("date.seconds", "<=", endDate.getTime() / 1000)
      );

      const [purchaseQuerySnapshot] = await Promise.all([
        getDocs(purchaseQuery),
      ]);

      const purchases = purchaseQuerySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id, type: "purchase" };
      });
      allOffers = [...allOffers, ...purchases];
    }
    if (key === "productName") {
      return categorizeByProductName(allOffers, key);
    }
  }

  const value = {
    limit,
    setLimit,
    purchases,
    alternativePrices,
    searchParams,
    categories,
    productList,
    brands,
    categoryList,
    editProductId,
    setEditProductId,
    editProduct,
    setEditProduct,
    orderList,
    productList,
  };

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);
