import * as React from "react";

import {
  AddAPhotoRounded,
  BusinessRounded,
  Close as CloseIcon,
  DragHandle,
  NotificationsRounded,
  ShoppingBasket,
} from "@mui/icons-material";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  IconButton,
  Paper,
} from "@mui/material";
import { useAppContext, useOrderContext } from "../context";
import { useEffect, useState } from "react";

import appleStore from "../assets/appleStore.png";
import googleStore from "../assets/googleStore.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function SimpleBottomNavigation() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { notifications, uploadFiles } = useAppContext();
  const [notificationCount, setNotificationCount] = useState(0);
  const { groupedOrders } = useOrderContext();
  const theme = useTheme();
  const [showAppStoreButtons, setShowAppStoreButtons] = useState(true);

  useEffect(() => {
    const notifCount =
      notifications?.filter((n) => n.notified === false)?.length || 0;
    const fileCount = uploadFiles?.length || 0;
    setNotificationCount(notifCount + fileCount);
  }, [uploadFiles, notifications]);

  const handleClose = () => {
    setShowAppStoreButtons(false);
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: {
          md: "block",
          lg: "none",
        },

        zIndex: 3,
        paddingBottom: "env(safe-area-inset-bottom)",
      }}
      elevation={3}
    >
      {showAppStoreButtons && (
        <Box
          sx={{
            position: "absolute",
            bottom: "100%", // Position just above the BottomNavigation
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-around",
            alignItems: "center",
            gap: 2,
            padding: 2,
            backdropFilter: "blur(12px)",
            webkitBackdropFilter: "blur(12px)",
          }}
        >
          <IconButton
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.teklifappnative",
                "_blank"
              )
            }
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={googleStore}
              alt="Google Play Store"
              style={{
                height: 40,
                cursor: "pointer",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://apps.apple.com/tr/app/tedaarik/id6504186040",
                "_blank"
              )
            }
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={appleStore}
              alt="Apple App Store"
              style={{
                height: 40,
                cursor: "pointer",
              }}
            />
          </IconButton>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              // top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          backdropFilter: "blur(12px)",
          webkitBackdropFilter: "blur(12px)",
          background: "none",
        }}
      >
        <BottomNavigationAction
          onClick={() => navigate("/menu")}
          label={t("menu")}
          icon={<DragHandle />}
        />
        <BottomNavigationAction
          onClick={() => navigate("/overview")}
          label={t("overview")}
          icon={<BusinessRounded />}
        />
        <BottomNavigationAction
          onClick={() => navigate("/upload")}
          label={t("upload")}
          icon={<AddAPhotoRounded />}
        />
        <BottomNavigationAction
          onClick={() => navigate("/shopping-cart")}
          label="Sepetim"
          icon={
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  right: 0,
                  top: 13,
                  border: `2px solid ${theme.palette.background.paper}`,
                  padding: "0 4px",
                  backgroundColor: "#0b66c2",
                },
              }}
              badgeContent={Object.keys(groupedOrders || {})?.reduce(
                (acc, next) => {
                  return (
                    acc +
                    Object.keys(groupedOrders[next]?.products || {}).length
                  );
                },
                0
              )}
              color="success"
            >
              <ShoppingBasket sx={{ color: "inherit", fontSize: "32px" }} />
            </Badge>
          }
        />
      </BottomNavigation>
    </Paper>
  );
}
