import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchases: [],
  companyPurchases: [],
};

const purchaseSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    setPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    setCompanyPurchases: (state, action) => {
      state.companyPurchases = action.payload;
    },
  },
});

export const { setPurchases, setCompanyPurchases } = purchaseSlice.actions;

export default purchaseSlice.reducer;
