import {
  AccountTree,
  ArrowDropDown,
  Business,
  Campaign,
  CurrencyLira,
  Description,
  Discount,
  Percent,
  PointOfSale,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { createCurrency } from "../utils";

export function prepareItemCards(purchases, lowestPrice, lastPrice) {
  const unit = Object.values(purchases || {}).reduce((acc, nex) => {
    if (nex.unit) {
      acc = nex.unit;
    }
    return acc;
  }, "");

  function calcGenericNamePriceDiff() {
    const diff = (1 - lowestPrice / lastPrice) * 100;
    return (diff || 0).toFixed(0);
  }

  return [
    {
      title: "En Ucuz",
      value: createCurrency(lowestPrice).format(),
      icon: Campaign,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
      titleHelper: (
        <Box
          sx={{
            display: {
              lg: "flex",
              md: "flex",
              sm: "none",
              xs: "none",
            },
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ArrowDropDown
            sx={{
              color: "#3CA886",
              fontSize: "1.4rem",
              paddingLeft: "0.4rem",
              height: "1.4rem",
            }}
          />
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold", color: "#3CA886" }}
          >
            %{calcGenericNamePriceDiff()}
          </Typography>
        </Box>
      ),
    },
    {
      title: "Miktar",
      value: `${Object.values(purchases || {})
        .reduce((acc, nex) => acc + nex.quantity, 0)
        .toFixed(2)} ${unit}`,
      icon: Description,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "İskonto",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + nex.discount,
          0
        )
      ).format(),
      icon: Discount,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Ara Toplam",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + nex.subTotal,
          0
        )
      ).format(),
      icon: PointOfSale,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Kdv",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + Number(nex.tax || 0),
          0
        )
      ).format(),
      icon: Percent,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Toplam",
      value: createCurrency(
        Object.values(purchases || {}).reduce((acc, nex) => acc + nex.total, 0)
      ).format(),
      icon: CurrencyLira,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
  ];
}

export function prepareItemsCards(purchases) {
  return [
    {
      title: "Fırsat Ürünü",
      value: Object.keys(purchases || {}).filter(
        (p) => purchases?.[p]?.lastPrice > purchases?.[p]?.lowestPrice
      ).length,
      icon: Campaign,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Ürün Çeşiti",
      value: Object.keys(purchases || {}).length,
      icon: AccountTree,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Ara Toplam",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + Number(nex.subTotal || 0),
          0
        )
      ).format(),
      icon: PointOfSale,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "İskonto",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + nex.discount,
          0
        )
      ).format(),
      icon: Discount,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },

    {
      title: "Kdv",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + Number(nex.tax || 0),
          0
        )
      ).format(),
      icon: Percent,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Toplam",
      value: createCurrency(
        Object.values(purchases || {}).reduce(
          (acc, nex) => acc + Number(nex.total || 0),
          0
        )
      ).format(),
      icon: CurrencyLira,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
  ];
}
