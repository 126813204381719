import { collection, getDocs } from "firebase/firestore";

import { db } from "../../firebase";
import { setCategories } from "../../redux/App/OverViewSlice";

export async function fetchCategories({ user, dispatch }) {
  try {
    const categorizedPurchasesRef = collection(
      db,
      `analytics/${user?.companyId || user?.uid}/categories`
    );
    const snapshot = await getDocs(categorizedPurchasesRef);
    const fetchedCategories = snapshot.docs.map(
      (doc) => doc.data().originalName
    );
    dispatch(setCategories(fetchedCategories));
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
}
