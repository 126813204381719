import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { CompanyWidget } from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function OverviewCompanies({ loading, setLoading }) {
  const { t } = useTranslation();
  const { companies } = useSelector((state) => state.invoices);
  const navigate = useNavigate();
  const { filters } = useSelector((state) => state.overView);
  const [orderedList, setOrderedList] = useState({});

  const { sortByName, sortByTax, sortByDiscount, sortByTotal, activeKey } =
    useSelector((state) => state.overView.filters);

  useEffect(() => {
    const compare = (a, b, key, order) => {
      if (order === "asc") {
        return companies[a][key] - companies[b][key];
      } else {
        return companies[b][key] - companies[a][key];
      }
    };

    const sortCompanies = (a, b) => {
      switch (activeKey) {
        case "sortByTax":
          return compare(a, b, "tax", sortByTax);
        case "sortByDiscount":
          return compare(a, b, "discount", sortByDiscount);
        case "sortByTotal":
          return compare(a, b, "total", sortByTotal);
        case "sortByName":
          if (sortByName === "asc") {
            return a.localeCompare(b);
          }
          return b.localeCompare(a);
        default:
          return a.localeCompare(b);
      }
    };

    const sortedGroupedInvoices = Object.keys(companies)
      .sort((a, b) => {
        return sortCompanies(a, b);
      })
      .reduce((sortedCompanies, vendorName) => {
        sortedCompanies[vendorName] = companies[vendorName];
        return sortedCompanies;
      }, {});
    // dispatch(setCompanies(sortedGroupedInvoices));
    setOrderedList(sortedGroupedInvoices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortByName,
    sortByTax,
    sortByDiscount,
    sortByTotal,
    activeKey,
    companies,
  ]);

  return (
    <Box
      sx={{
        paddingBottom: 10,
      }}
    >
      {Object.entries(orderedList || {}).map(([company, details]) => {
        if (
          filters?.filterCompanies?.length &&
          !filters?.filterCompanies?.includes(company)
        ) {
          return null;
        }
        return (
          <CompanyWidget
            key={company}
            company={company}
            details={details}
            navigate={navigate}
            t={t}
          />
        );
      })}
    </Box>
  );
}
