import {
  AlternateEmail as AlternateEmailIcon,
  Badge as BadgeIcon,
  Business as BusinessIcon,
  ImportContacts as ImportContactsIcon,
  LocalPhone as LocalPhoneIcon,
  MyLocation,
  Numbers as NumbersIcon,
} from "@mui/icons-material";

import { Box } from "@mui/material";
import { Creatable } from "../../../components";
import Input from "../../Input";
import { capitalizeFirstLetters } from "../../../utils";
import { handleTaxNoOnBlur } from "../utils";

const hideOnMobile = { display: { xs: "none", md: "flex" } };

export default function CustomerCard({
  fields,
  setInvoice,
  companies,
  fetchCompanies,
  admin,
  t,
}) {
  return (
    <Box onClick={fetchCompanies}>
      <Creatable
        value={fields?.CustomerName?.value || ""}
        options={Object.values(companies)?.map((c) => {
          return { title: c.name, inputValue: c.name };
        })}
        onBlur={(e) => {
          const company = Object.values(companies).find(
            (c) => c.name === e.target.value
          );
          handleTaxNoOnBlur({
            id: company?.id.replace(/\s/g, "") || "",
            role: "Customer",
            setInvoice,
          });
        }}
        icon={<BusinessIcon />}
      />

      <Input
        placeholder={t("taxOffice")}
        sx={hideOnMobile}
        titleIcon={<BadgeIcon />}
        multiline={true}
        value={fields?.CustomerTaxOffice?.content || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              CustomerTaxOffice: {
                ...prev.fields.CustomerTaxOffice,
                confidence: 1,
                content: e.target.value,
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              CustomerTaxOffice: {
                ...prev.fields.CustomerTaxOffice,
                confidence: 1,
                content: capitalizeFirstLetters(e.target.value),
              },
            },
          }))
        }
      />
      <Input
        placeholder={t("taxId")}
        sx={{ paddingBottom: "1rem" }}
        titleIcon={<NumbersIcon />}
        value={fields?.CustomerTaxId?.value || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              CustomerTaxId: {
                ...prev.fields.CustomerTaxId,
                confidence: 1,
                value: e.target.value,
              },
            },
          }))
        }
        onBlur={(e) => {
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              CustomerTaxId: {
                ...prev.fields.CustomerTaxId,
                confidence: 1,
                value: e.target.value.replace(/\s/g, ""),
              },
            },
          }));
          handleTaxNoOnBlur({
            id: e.target.value.replace(/\s/g, ""),
            role: "Customer",
            setInvoice,
          });
        }}
      />
      {admin && (
        <>
          <Input
            placeholder={t("phoneNumber")}
            titleIcon={<LocalPhoneIcon />}
            value={fields?.CustomerPhone?.content || ""}
            sx={hideOnMobile}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  CustomerPhone: {
                    ...prev.fields.CustomerPhone,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  CustomerPhone: {
                    ...prev.fields.CustomerPhone,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
          />
          <Input
            placeholder={t("email")}
            titleIcon={<AlternateEmailIcon />}
            value={fields?.CustomerMail?.content || ""}
            sx={hideOnMobile}
            multiline={true}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  CustomerMail: {
                    ...prev.fields.CustomerMail,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  CustomerMail: {
                    ...prev.fields.CustomerMail,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
          />
          <Input
            placeholder={t("address")}
            titleIcon={<ImportContactsIcon />}
            sx={hideOnMobile}
            multiline={true}
            value={fields?.CustomerAddress?.content || ""}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  CustomerAddress: {
                    ...prev.fields.CustomerAddress,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  CustomerAddress: {
                    ...prev.fields.CustomerAddress,
                    confidence: 1,
                    content: capitalizeFirstLetters(e.target.value),
                  },
                },
              }))
            }
          />
          <Input
            placeholder={t("location")}
            titleIcon={<MyLocation />}
            sx={hideOnMobile}
            multiline={true}
            value={fields?.Location?.content || ""}
            onChange={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  Location: {
                    ...prev.fields.Location,
                    confidence: 1,
                    content: e.target.value,
                  },
                },
              }))
            }
            onBlur={(e) =>
              setInvoice((prev) => ({
                ...prev,
                fields: {
                  ...prev.fields,
                  Location: {
                    ...prev.fields.Location,
                    confidence: 1,
                    content: capitalizeFirstLetters(e.target.value),
                  },
                },
              }))
            }
          />
        </>
      )}
    </Box>
  );
}
