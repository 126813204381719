import {
  AddRounded,
  DeleteOutlineRounded,
  Edit,
  ImageRounded,
  RemoveRounded,
} from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useOrderContext, useProductContext } from "../../context";

import { createCurrency } from "../../utils";
import { useSelector } from "react-redux";

const ProductImage = ({ count }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      border: count > 0 ? "1px solid #0b66c2" : "1px solid #dfdeda",
    }}
  >
    <ImageRounded sx={{ color: "#e8e8e8", width: "100%", height: "100%" }} />
  </Box>
);

const ProductDetails = ({ product }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography sx={{ fontWeight: "bold" }}>
      {createCurrency(product?.price).format()}
    </Typography>
    <Typography sx={{ textAlign: "center", fontSize: "0.8rem" }}>
      {product?.productName}
    </Typography>
    <Typography sx={{ textAlign: "center", fontSize: "0.7rem", color: "gray" }}>
      {product?.unitWeight}
      {product?.unit}
    </Typography>
  </Box>
);
const FloatingActionButtons = ({
  addToOrderCart,
  removeFromOrderCount,
  product,
  order,
}) => {
  const count = order?.orderCount || 0;
  const status = product?.status;

  if (status === "confirmed") {
    return (
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          right: {
            xs: 8,
            sm: 8,
            md: 8,
            lg: 16,
          },
          top: {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 28,
          },
          borderRadius: 4,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0b66c2",
            border: "1px solid #dfdeda",
            display: "flex",
            borderTop: "none",
            borderBottom: "none",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.6rem",
            borderRadius: 2,
            paddingX: "0.8rem",
          }}
        >
          <Typography sx={{ fontWeight: "bold", color: "white" }}>
            {count}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        right: {
          xs: 8,
          sm: 8,
          md: 8,
          lg: 16,
        },
        top: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 28,
        },
        borderRadius: 4,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <ActionButton
        display="flex"
        borderRadius={count > 0 ? "1rem 1rem 0rem 0rem" : "1rem"}
        onClick={() => addToOrderCart(product)}
        icon={<AddRounded sx={{ color: "#0b66c2" }} />}
      />
      {count > 0 && <CountDisplay count={count} />}
      {count > 0 && (
        <ActionButton
          display="flex"
          borderRadius="0rem 0rem 1rem 1rem"
          onClick={() => removeFromOrderCount(product)}
          icon={<RemoveRounded sx={{ color: "#0b66c2" }} />}
        />
      )}
    </Box>
  );
};

const AdminActionButtons = ({ admin, productId, setEditProductId }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: admin ? "flex" : "none",
        flexDirection: "column",
        left: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
        },
        top: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 28,
        },
        borderRadius: 4,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      onClick={() => setEditProductId(productId)}
    >
      <ActionButton
        display="flex"
        borderRadius={"1rem"}
        icon={<Edit sx={{ color: "#0b66c2" }} />}
      />
    </Box>
  );
};

const RemoveProductButton = ({
  admin,
  product,
  removeProductFromOrderCart,
  count,
}) => {
  if (count === 0) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        display: admin || product.status === "confirmed" ? "none" : "flex",
        flexDirection: "column",
        left: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
        },
        top: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 28,
        },
        borderRadius: 4,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      onClick={() => removeProductFromOrderCart(product, count)}
    >
      <ActionButton
        display="flex"
        borderRadius={"1rem"}
        icon={<DeleteOutlineRounded sx={{ color: "#0b66c2" }} />}
      />
    </Box>
  );
};

const CountDisplay = ({ count }) => (
  <Box
    sx={{
      backgroundColor: "#0b66c2",
      border: "1px solid #dfdeda",
      display: "flex",
      borderTop: "none",
      borderBottom: "none",
      justifyContent: "center",
      alignItems: "center",
      padding: {
        xs: 0.2,
        sm: 0.4,
        md: 0.6,
        lg: 1,
      },
    }}
  >
    <Typography sx={{ fontWeight: "bold", color: "white" }}>{count}</Typography>
  </Box>
);

const ActionButton = ({ display, borderRadius, onClick, icon }) => (
  <Box
    variant="rounded"
    sx={{
      backgroundColor: "white",
      border: "1px solid #dfdeda",
      borderBottom: "none",
      display: display,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadius,
      padding: {
        xs: 0.2,
        sm: 0.4,
        md: 0.6,
        lg: 1,
      },
    }}
    onClick={onClick}
  >
    {icon}
  </Box>
);

export default function ShoppingProduct({ product, type }) {
  const { editProductId, setEditProductId } = useProductContext();
  const user = useSelector((state) => state.user.userDoc);
  const admin = user?.role === "admin";
  const {
    groupedOrders,
    groupedApprovedOrders,
    addToOrderCart,
    removeFromOrderCount,
    removeProductFromOrderCart,
  } = useOrderContext();

  return (
    <Grid item xs={4} md={3} lg={2} sx={{ position: "relative" }}>
      <AdminActionButtons
        admin={admin}
        productId={product?.productId}
        setEditProductId={setEditProductId}
      />
      <RemoveProductButton
        removeProductFromOrderCart={removeProductFromOrderCart}
        admin={admin}
        product={product}
        count={
          groupedOrders?.[product?.vendorName]?.products?.[product?.productName]
            ?.orderCount || 0
        }
      />

      <FloatingActionButtons
        addToOrderCart={addToOrderCart}
        removeFromOrderCount={removeFromOrderCount}
        product={product}
        order={
          type === "confirmed"
            ? groupedApprovedOrders?.[product?.vendorName]?.products?.[
                product?.productName
              ]
            : groupedOrders?.[product?.vendorName]?.products?.[
                product?.productName
              ]
        }
      />
      <ProductImage
        count={
          type === "confirmed"
            ? groupedApprovedOrders?.[product?.vendorName]?.products?.[
                product?.productName
              ]?.orderCount
            : groupedOrders?.[product?.vendorName]?.products?.[
                product?.productName
              ]?.orderCount
        }
      />
      <ProductDetails product={product} />
    </Grid>
  );
}
