import React, { createContext, useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { db } from "../firebase";
import { useAppContext } from "../context";
import { useSelector } from "react-redux";

const InvoiceContext = createContext();

export const InvoiceProvider = ({ children }) => {
  const { notifications, setNotifications } = useAppContext();
  const filter = useSelector((state) => state?.filter);
  const user = useSelector((state) => state.user.userDoc);

  const [invoices, setInvoices] = useState();
  const [companies, setCompanies] = useState();
  const [groupedInvoices, setGroupedInvoices] = useState(null);
  const [products, setProducts] = useState(null);
  const [focusedCompany, setFocusedCompany] = useState(null);
  const [filtered, setFiltered] = useState({
    companies: [],
    invoices: [],
  });
  const [sortedInvoices, setSortedInvoices] = useState([]);
  const [alternativePrices, setAlternativePrices] = useState({});
  const [notificationLimit, setNotificationLimit] = useState(10);

  useEffect(() => {
    filterInvoices(filter);
  }, [filter, invoices]);

  function filterInvoices(filter) {
    const companyIds = filter?.companies.map((company) => company.id);
    const productIds = filter?.products.map((product) => product.primary);
    let filteredInvoices = [...(invoices || [])];
    let filteredProducts = [...(Object.values(products || {}) || [])];
    let filteredCompanies = [...(Object.values(companies || {}) || [])];

    if (companyIds.length) {
      filteredInvoices = invoices?.filter((invoice) => {
        return companyIds.includes(invoice.vendorTaxId);
      });
      filteredProducts = filteredProducts?.filter((product) => {
        return product.vendors?.some((vendor) => companyIds.includes(vendor));
      });
      filteredCompanies = filteredCompanies?.filter((company) => {
        return companyIds.includes(company.vendorTaxId);
      });
    }

    if (productIds.length) {
      filteredInvoices = filteredInvoices?.filter((invoice) => {
        return invoice?.products?.some((product) =>
          productIds?.includes(product)
        );
      });
      filteredProducts = filteredProducts?.filter((product) => {
        return productIds.includes(product.productName);
      });
      filteredCompanies = filteredCompanies?.filter((company) => {
        return company.products?.some((product) =>
          productIds.includes(product)
        );
      });
    }

    setFiltered({
      invoices: filteredInvoices,
      products: filteredProducts,
      companies: filteredCompanies,
    });
  }

  useEffect(() => {
    const unsubscribe = listenNotifications() || (() => {});

    return () => {
      unsubscribe();
    };
  }, [user, notificationLimit]);

  function listenNotifications() {
    if (!user) return () => {};

    const newInvoiceQuery = query(
      collection(db, "notifications"),
      where("userUid", "==", user.uid),
      orderBy("timestamp", "desc"),
      limit(notificationLimit)
    );

    return onSnapshot(newInvoiceQuery, (querySnapshot) => {
      const newNotifications = [];
      querySnapshot.forEach((doc) => {
        newNotifications.push({ docId: doc.id, ...doc.data() });
      });
      setNotifications(newNotifications); // Set the notifications from snapshot
    });
  }

  async function updateNotifications() {
    try {
      const notifiedNotifications = notifications.filter(
        (notification) => notification.notified === false
      );

      for (const notification of notifiedNotifications) {
        const notificationDoc = doc(db, "notifications", notification.docId);
        await updateDoc(notificationDoc, { notified: true });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    groupByVendor();
  }, [invoices]);

  function groupByVendor() {
    const groupedInvoices = invoices?.reduce((acc, invoice) => {
      if (!acc[invoice?.fields?.VendorName?.value]) {
        acc[invoice?.fields?.VendorName?.value] = [];
      }
      acc[invoice?.fields?.VendorName?.value].push(invoice);
      return acc;
    }, {});
    setGroupedInvoices(groupedInvoices);
  }

  const value = {
    companies,
    setCompanies,
    products,
    setProducts,
    groupedInvoices,
    focusedCompany,
    setFocusedCompany,
    filtered,
    sortedInvoices,
    setSortedInvoices,
    alternativePrices,
    setNotificationLimit,
    updateNotifications,
  };

  return (
    <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>
  );
};

export const useInvoiceContext = () => useContext(InvoiceContext);
