export const capitalizeFirstLetters = (str) => {
  return str
    .split(/\s+/)
    .map((word) => {
      const letters = Array.from(word.toLowerCase());
      if (letters.length > 0) {
        letters[0] = letters[0].toUpperCase();
      }
      return letters.join("");
    })
    .join(" ");
};
