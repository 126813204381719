import { Box, Grid, Typography } from "@mui/material";
import { ShoppingNavigation, ShoppingProduct } from "../components";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { KeyboardArrowRightRounded } from "@mui/icons-material";
import { useProductContext } from "../context";

const PageHeader = ({ selectedList, setSelectedList, navigate }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        padding: 2,
        paddingBottom: 1,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          color: selectedList?.vendor ? "gray" : "black",
          cursor: "pointer",
          fontSize: {
            xs: "1rem",
            md: "1.2rem",
            lg: "1.6rem",
            xl: "1.6rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "40vw",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setSelectedList({});
          navigate("/shopping-cart");
        }}
      >
        Sipariş Listeleri
      </Typography>

      {selectedList?.vendor && (
        <>
          <KeyboardArrowRightRounded
            sx={{ color: "gray", cursor: "pointer" }}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "1rem",
                md: "1.2rem",
                lg: "1.6rem",
                xl: "1.6rem",
              },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "50vw",
              cursor: "pointer",
            }}
          >
            {selectedList?.vendor}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default function ShoppingCart() {
  const orderFormsRef = useRef(null);
  const { orderList } = useProductContext();
  const [selectedList, setSelectedList] = useState({});
  const [categorized, setCategorized] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const products = orderList[id]?.products || {};
      setSelectedList({ ...orderList[id], vendor: id });
      const categorized = categorizeList(products);
      setCategorized(categorized);
    }
  }, [id, orderList]);
  function categorizeList(list) {
    const categories = {};

    Object.values(list || {}).forEach((p) => {
      if (!categories[p?.category]) {
        categories[p?.category] = {};
      }
      if (!categories[p.category][p?.subCategory]) {
        categories[p.category][p.subCategory] = [];
      }
      categories[p.category][p.subCategory].push(p);
    });

    const sortedCategories = Object.keys(categories)
      .sort()
      .reduce((acc, category) => {
        acc[category] = Object.keys(categories[category])
          .sort()
          .reduce((subAcc, subCategory) => {
            subAcc[subCategory] = categories[category][subCategory].sort(
              (a, b) => a.productName.localeCompare(b.productName)
            );
            return subAcc;
          }, {});
        return acc;
      }, {});

    return sortedCategories;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#f4f2ee",
        padding: { sm: 0, md: 0, lg: 4 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          width: "100%",
          minHeight: "calc(100vh - 64px)",
          backgroundColor: "#f4f2ee",
        }}
      >
        <ShoppingNavigation />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
            paddingBottom: "8rem",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              border: "1px solid #dfdeda",
              backgroundColor: "white",
              borderRadius: { md: 0, lg: 4 },
              width: "100%",
              marginTop: id ? 0 : "1rem",
              marginBottom: "1rem",
            }}
            ref={orderFormsRef}
          >
            <PageHeader
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              navigate={navigate}
            />

            {Object.keys(categorized || {})?.map((category) => {
              return (
                <Box
                  key={category}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#ddeeff",
                      paddingX: 2,
                      paddingY: 1,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {category}
                    </Typography>
                  </Box>

                  {Object.keys(categorized[category] || {})?.map((sub) => {
                    return (
                      <div key={sub}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            backgroundColor: "#e6f1fc",
                            paddingX: 2,
                            paddingY: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "0.8rem" }}>
                            {sub}
                          </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                          {categorized[category][sub]?.map((p) => {
                            return (
                              <ShoppingProduct key={p?.productId} product={p} />
                            );
                          })}
                        </Grid>
                      </div>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
