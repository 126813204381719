export function categorizedList(list) {
  const categories = {};

  Object.values(list || {}).forEach((p) => {
    if (!categories[p?.category]) {
      categories[p?.category] = {};
    }
    if (!categories[p.category][p?.subCategory]) {
      categories[p.category][p.subCategory] = [];
    }
    categories[p.category][p.subCategory].push(p);
  });

  const sortedCategories = Object.keys(categories)
    .sort()
    .reduce((acc, category) => {
      acc[category] = Object.keys(categories[category])
        .sort()
        .reduce((subAcc, subCategory) => {
          subAcc[subCategory] = categories[category][subCategory].sort((a, b) =>
            a?.productName?.localeCompare(b.productName)
          );
          return subAcc;
        }, {});
      return acc;
    }, {});

  return sortedCategories;
}
