import { Box } from "@mui/material";
import { MenuItems } from "../components";

export default function DesktopNavigation() {
  return (
    <Box
      sx={{
        display: { xs: "none", sm: "none", md: "none", lg: "block" },
        position: "sticky",
        width: "24%",
        top: 2,
        padding: 1,
      }}
    >
      <Box sx={{ marginLeft: 12 }}>
        <MenuItems />
      </Box>
    </Box>
  );
}
