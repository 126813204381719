import { Helmet } from "react-helmet-async";
import React from "react";
import { blogPosts } from "../../scenes/Blog/blogPosts";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SEOHelmet = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const getMetadata = (path) => {
    const pathParts = path.split("/").filter(Boolean);
    const basePath = pathParts[0];
    const currentLang = i18n.language;

    if (basePath === "blog") {
      const blogLang = pathParts[1];
      const postSlug = pathParts[2];
      if (postSlug) {
        const post = blogPosts[blogLang]?.find((p) => p.slug === postSlug);
        if (post) {
          const description = post.content
            .split("</p>")[0]
            .replace(/<\/?[^>]+(>|$)/g, "")
            .trim()
            .substring(0, 160);
          return {
            title: `${post.title} | Tedaarik Blog`,
            description: description,
            keywords: post.keywords || t("blogPageKeywords"),
            type: "article",
            image: post.image
              ? `https://tedaarik.com${post.image}`
              : `https://tedaarik.com/default-blog-image.jpg`,
            imageWidth: "1200",
            imageHeight: "630",
            datePublished: post.datePublished || new Date().toISOString(),
            author: post.author || "Tedaarik Team",
            lang: blogLang,
          };
        }
      }
      // Default blog metadata if no specific post is found
      return {
        title: "Tedaarik Blog",
        description: t("blogPageDescription"),
        keywords: t("blogPageKeywords"),
        type: "website",
        image: `https://tedaarik.com/blog-index-image.jpg`,
        imageWidth: "1200",
        imageHeight: "630",
        lang: blogLang || currentLang,
      };
    }

    // Existing switch case with added language...
    switch (basePath) {
      case "":
        return {
          title: `${t("welcomePageTitle")} | Tedaarik`,
          description: t("welcomePageDescription"),
          keywords: t("welcomePageKeywords"),
          type: "website",
          image: `https://tedaarik.com/welcome-image.jpg`,
          imageWidth: "1200",
          imageHeight: "630",
          lang: currentLang,
        };
      case "request-demo":
      case "sign-up":
      case "login":
        return {
          title: t(`${basePath}PageTitle`),
          description: t(`${basePath}PageDescription`),
          keywords: t(`${basePath}PageKeywords`),
        };
      case "data-extraction":
      case "collaborative-ordering":
        return {
          title: t(`${basePath}PageTitle`),
          description: t(`${basePath}PageDescription`),
          keywords: t(`${basePath}PageKeywords`),
        };
      case "invoices":
      case "companies":
      case "upload":
      case "products-services":
      case "categories":
      case "profile":
      case "products":
      case "overview":
      case "search":
      case "menu":
      case "notifications":
      case "shopping-cart":
      case "company":
      case "assistant":
      case "privacy-policy":
      case "site-map":
      case "document-preview":
        return {
          title: t(`${basePath}PageTitle`),
          description: t(`${basePath}PageDescription`),
          keywords: t(`${basePath}PageKeywords`),
        };
      default:
        return {
          title: `${t("defaultPageTitle")} | Tedaarik`,
          description: t("defaultPageDescription"),
          keywords: t("defaultPageKeywords"),
          type: "website",
          image: `https://tedaarik.com/default-image.jpg`,
          imageWidth: "1200",
          imageHeight: "630",
          lang: currentLang,
        };
    }
  };

  const metadata = getMetadata(location.pathname) || {
    title: "Tedaarik - AI-Powered Procurement Agent",
    description:
      "Streamline your business purchasing with Tedaarik, your AI-powered procurement agent for efficient vendor management and cost optimization.",
    image: `https://tedaarik.com/default-image.jpg`,
    type: "website",
    lang: i18n.language,
    author: "Oğuz Can Sakatoğlu",
    datePublished: new Date().toISOString(),
  };

  console.log("Generated metadata:", metadata);

  const structuredData = {
    "@context": "https://schema.org",
    "@type":
      metadata.type === "article" ? "BlogPosting" : "SoftwareApplication",
    name: metadata.type === "article" ? metadata.title : "Tedaarik",
    headline: metadata.title,
    description: metadata.description,
    image: metadata.image,
    url: `https://tedaarik.com${location.pathname}`,
  };

  if (metadata.type !== "article") {
    structuredData.applicationCategory = "BusinessApplication";
    structuredData.operatingSystem = "Android, iOS, Web";
    structuredData.offers = {
      "@type": "Offer",
      price: "0",
      priceCurrency: "USD",
    };
    structuredData.availableOnDevice = ["Android", "iOS", "Web"];
  } else {
    structuredData.datePublished = metadata.datePublished;
    structuredData.author = {
      "@type": "Person",
      name: metadata.author,
    };
  }

  if (location.pathname.startsWith("/company/")) {
    structuredData["@type"] = "Organization";
    structuredData.name = t("companyPageTitle");
    delete structuredData.applicationCategory;
    delete structuredData.operatingSystem;
    delete structuredData.offers;
    delete structuredData.availableOnDevice;
  }

  return (
    <Helmet>
      <html lang={metadata.lang} />
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
      <link rel="canonical" href={`https://tedaarik.com${location.pathname}`} />

      {/* Open Graph tags */}

      <meta
        property="og:url"
        content={`https://tedaarik.com${location.pathname}`}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:type" content={metadata.type} />
      <meta
        property="og:url"
        content={`https://tedaarik.com${location.pathname}`}
      />
      <meta property="og:image" content={metadata.image} />
      <meta property="og:image:width" content={metadata.imageWidth} />
      <meta property="og:image:height" content={metadata.imageHeight} />
      <meta property="og:site_name" content="Tedaarik" />
      <meta property="og:locale" content={metadata.lang} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:type" content={metadata.type} />
      <meta
        property="article:published_time"
        content={metadata.datePublished}
      />
      <meta
        property="article:published_time"
        content={metadata.datePublished}
      />
      <meta property="article:author" content={metadata.author} />

      {/* Twitter Card tags */}
      <meta name="twitter:image" content={metadata.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@tedaarik" />
      <meta name="twitter:title" content={metadata.title} />
      <meta name="twitter:description" content={metadata.description} />
      <meta
        name="twitter:image"
        content={`${metadata.image}?v=${Date.now()}`}
      />
      <meta name="twitter:image:alt" content={metadata.title} />

      {/* Additional meta tags */}
      <meta name="robots" content="index, follow" />
      <meta
        name="author"
        content={metadata.type === "article" ? metadata.author : "Tedaarik"}
      />

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SEOHelmet;
