import { Box, Button, Typography } from "@mui/material";

import { KeyboardArrowRight } from "@mui/icons-material";
import { PointCloudNetwork } from "../../components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CollaborativeOrdering = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #FFFFFF,#F2F2F2)",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
        display: {
          lg: "flex",
          md: "flex",
          sm: "none",
          xs: "none",
        },
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <PointCloudNetwork />
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: 44,
            fontWeight: 600,
            color: "#2b2b2b",
            alignSelf: "center",
            padding: 4,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {t("comprehensiveSearchFilter")}
        </Typography>
        <Typography
          sx={{
            fontSize: 32,
            fontWeight: 600,
            color: "gray",
            textAlign: "center",
            padding: 4,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {t("invoicesIntoKnowledge")}
        </Typography>
        <Typography
          sx={{
            fontSize: 26,
            fontWeight: 600,
            color: "gray",
            textAlign: "center",
            padding: 4,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            width: "30%",
          }}
        >
          {t("allDataInOnePlace")}
        </Typography>

        <Button
          sx={{
            borderRadius: 10,
            textTransform: "none",
            fontSize: 26,
            alignSelf: "center",
            paddingLeft: 0,
            marginTop: 4,
          }}
          endIcon={<KeyboardArrowRight />}
          onClick={() => navigate("/request-demo")}
        >
          {t("requestDemo")}
        </Button>
      </Box>
    </Box>
  );
};

export default CollaborativeOrdering;
