import { AddRounded, PersonRemoveRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import { SearchUserDialog } from "../../components";
import { db } from "../../firebase";
import uniqolor from "uniqolor";
import { useTranslation } from "react-i18next";

export default function Employees({ company }) {
  const { t } = useTranslation();
  const [userDialog, setUserDialog] = useState(false);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedEmployee(null);
  };

  useEffect(() => {
    let unsubscribe;
    if (company?.companyId) {
      unsubscribe = fetchPendingInvitations();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [company]);

  function fetchPendingInvitations() {
    const q = query(
      collection(db, "userInvitations"),
      where("companyId", "==", company?.companyId),
      where("status", "==", "pending")
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data()?.userUid);
        setPendingInvitations(data);
      },
      (error) => {
        console.error(error);
      }
    );

    return unsubscribe;
  }

  async function inviteUserToCompany(user) {
    try {
      const res = await addDoc(collection(db, "userInvitations"), {
        userUid: user?.objectID,
        companyId: company?.companyId,
        status: "pending",
        url: company?.url,
      });
      await addDoc(collection(db, "notifications"), {
        userUid: user?.uid,
        notified: false,
        message: `${company?.name} şirketine eklenmek isteniyorsunuz. Kabul etmek için tıklayın.`,
        timestamp: serverTimestamp(),
        type: "company-invite",
        logoUrl: company?.logoUrl,
        invitationId: res.id,
        companyUrl: company?.url,
        companyId: company?.companyId,
        companyName: company?.name,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function removeUserInvitation(user) {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "userInvitations"),
          where("userUid", "==", user?.objectID),
          where("companyId", "==", company?.companyId)
        )
      );
      if (querySnapshot.size > 0) {
        const document = querySnapshot.docs[0];
        await deleteDoc(document.ref);
      } else {
        console.error("No invitations found or multiple invitations found.");
      }
    } catch (error) {
      console.error("Error removing invitation:", error);
    }
  }

  async function removeUserFromCompany({ user }) {
    try {
      const newEmployees = company.employees.filter(
        (employee) => employee?.uid !== user?.uid
      );
      const companyRef = doc(db, "companies", company?.url);
      await updateDoc(companyRef, {
        employees: newEmployees,
      });
      handleClose();
    } catch (error) {
      console.error("Error removing user:", error);
    }
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "bold",
          padding: 2,
          // borderBottom: "1px solid #dfdeda",
        }}
        variant="h5"
      >
        {t("members")}
      </Typography>

      {company?.employees?.map((employee, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              paddingX: 2,
              paddingY: 1,
              flexDirection: "row",
              alignItems: "center",
              borderBottom:
                index === company?.employees?.length - 1
                  ? "none"
                  : "1px solid #dfdeda",
              justifyContent: "space-between",
              backgroundColor: "white",
              borderTopLeftRadius: index === 0 ? 15 : 0,
              borderTopRightRadius: index === 0 ? 15 : 0,
              borderBottomLeftRadius:
                index === company?.employees?.length - 1 ? 15 : 0,
              borderBottomRightRadius:
                index === company?.employees?.length - 1 ? 15 : 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar
                src={employee?.photoURL}
                sx={{
                  backgroundColor: uniqolor(employee?.uid).color,
                }}
              >
                {employee?.name?.[0] || employee?.displayName?.[0]}
              </Avatar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 2,
                }}
              >
                <Typography>{employee?.displayName}</Typography>
                <Typography>{employee?.role}</Typography>
              </Box>
            </Box>

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, employee)}
              sx={{
                textTransform: "none",
                padding: 0,
                minWidth: "5rem",
                borderRadius: 8,
                display: employee?.role !== "Owner" ? "flex" : "none",
              }}
              variant="outlined"
              color="primary"
            >
              {t("member")}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open && selectedEmployee?.uid === employee?.uid}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => removeUserFromCompany({ user: employee })}
                sx={{ color: "#d33031" }}
              >
                {t("remove")} @{employee?.displayName}
                <PersonRemoveRounded
                  color="error"
                  sx={{ marginLeft: "0.2rem" }}
                />
              </MenuItem>
            </Menu>
            <Chip
              sx={{
                display: employee?.role === "Owner" ? "flex" : "none",
                minWidth: "5rem",
              }}
              color="primary"
              variant="outlined"
              label={t("manager")}
            />
          </Box>
        );
      })}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginY: 4,
        }}
      >
        <Button
          startIcon={<AddRounded />}
          onClick={() => setUserDialog(true)}
          variant="contained"
          sx={{
            width: "50%",
            backgroundColor: "#2b2b2b",
            borderRadius: 10,
            color: "#fff",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#2b2b2b",
            },
            alignItems: "center",
          }}
        >
          {t("addMember")}
        </Button>
      </Box>
      <SearchUserDialog
        open={userDialog}
        onClose={() => setUserDialog(false)}
        onConfirm={() => setUserDialog(false)}
        inviteUserToCompany={inviteUserToCompany}
        removeUserInvitation={removeUserInvitation}
        pendingUsers={pendingInvitations}
        employeeIds={company?.employees?.map((e) => e.uid)}
      />
    </Box>
  );
}
