import { Button, Grid } from "@mui/material";
import { createCurrency, removePercentageAndFormat } from "../../../utils";

import Input from "../../Input";
import InvoiceActionsButton from "./InvoiceActionsButton";

const gridSx = {
  display: { xs: "none", md: "none", lg: "flex" },
  alignItems: "center",
};

export default function Items({
  item,
  index,
  setInvoice,
  calculationError,
  product,
  setProductDialog,
}) {
  return (
    <Grid
      container
      key={index}
      sx={{
        backgroundColor: calculationError ? "#ffcece" : "",
      }}
    >
      <Grid
        xs={1}
        item
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <InvoiceActionsButton
          onDelete={() => {
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.filter(
                    (val, i) => i !== index
                  ),
                },
              },
            }));
          }}
          onCopy={() => {
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: [
                    ...prev.fields.Items.values.slice(0, index + 1),
                    {
                      ...prev.fields.Items.values[index],
                      productId: `${new Date().valueOf()}-${Math.floor(
                        Math.random() * 1000
                      )}`,
                    },
                    ...prev.fields.Items.values.slice(index + 1),
                  ],
                },
              },
            }));
          }}
          label={index + 1}
        />
      </Grid>
      {/* urun kodu */}
      <Grid item xs={9} md={3}>
        <Button
          sx={{
            textTransform: "none",
            fontSize: "0.8rem",
            color: "black",
            justifyContent: "left",
          }}
          onClick={() => setProductDialog()}
          fullWidth
        >
          {product?.productName || item?.properties?.Description?.value}
        </Button>
      </Grid>
      {/* miktar */}
      <Grid item xs={1} sx={gridSx}>
        <Input
          value={item?.properties?.Quantity?.value || ""}
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Quantity: {
                            ...val.properties.Quantity,
                            confidence: 1,
                            value: e.target.value,
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
          onBlur={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Quantity: {
                            ...val.properties.Quantity,
                            confidence: 1,
                            value: e.target.value,
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      {/* birim fiyat */}
      <Grid item xs={1}>
        <Input
          value={item?.properties?.UnitPrice?.value?.amount || ""}
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          UnitPrice: {
                            ...val.properties.UnitPrice,
                            confidence: 1,
                            value: {
                              ...val.properties.UnitPrice?.value,
                              amount: e.target.value,
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
          onBlur={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          UnitPrice: {
                            ...val.properties.UnitPrice,
                            confidence: 1,
                            value: {
                              ...val.properties.UnitPrice?.value,
                              amount: createCurrency(
                                e.target.value
                              ).value?.toFixed(2),
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      {/* iskonto orani */}
      <Grid item xs={1} sx={gridSx}>
        <Input
          placeholder={"0"}
          value={item?.properties?.DiscountRate?.value || ""}
          startAdornment="%"
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          DiscountRate: {
                            ...val.properties.DiscountRate,
                            confidence: 1,
                            value: e.target.value,
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      {/* iskonto tutari */}
      <Grid item xs={1} sx={gridSx}>
        <Input
          placeholder={"0.00"}
          value={item?.properties?.Discount?.value?.amount || ""}
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Discount: {
                            ...val.properties.Discount,
                            confidence: 1,
                            value: { amount: e.target.value },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
          onBlur={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Discount: {
                            ...val.properties.Discount,
                            confidence: 1,
                            value: {
                              amount: createCurrency(
                                e.target.value
                              ).value?.toFixed(2),
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      {/* kdv orani */}
      <Grid item xs={1} sx={gridSx}>
        <Input
          startAdornment={
            item?.properties?.TaxRate?.value?.includes("%") ? "" : "%"
          }
          value={item?.properties?.TaxRate?.value || ""}
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          TaxRate: {
                            ...val.properties.TaxRate,
                            confidence: 1,
                            value: removePercentageAndFormat(e.target.value),
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
          onBlur={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          TaxRate: {
                            ...val.properties.TaxRate,
                            confidence: 1,
                            value: removePercentageAndFormat(e.target.value),
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      {/* kdv tutari */}
      <Grid item xs={1} sx={gridSx}>
        <Input
          value={item?.properties?.Tax?.value?.amount || ""}
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Tax: {
                            ...val.properties.Tax,
                            confidence: 1,
                            value: {
                              ...val.properties.Tax?.value,

                              amount: e.target.value,
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
          onBlur={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Tax: {
                            ...val.properties.Tax,
                            confidence: 1,
                            value: {
                              ...val.properties.Tax?.value,
                              amount: createCurrency(
                                e.target.value
                              ).value?.toFixed(2),
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      {/* urun hizmet tutari */}
      <Grid item xs={3} md={1} sx={gridSx}>
        <Input
          value={item?.properties?.Amount?.value?.amount || ""}
          onChange={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Amount: {
                            ...val.properties.Amount,
                            confidence: 1,
                            value: {
                              ...val.properties.Amount?.value,

                              amount: e.target.value,
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
          onBlur={(e) =>
            setInvoice((prev) => ({
              ...prev,
              fields: {
                ...prev.fields,
                Items: {
                  ...prev.fields.Items,
                  values: prev.fields.Items.values.map((val, i) => {
                    if (i === index) {
                      return {
                        ...val,
                        properties: {
                          ...val.properties,
                          Amount: {
                            ...val.properties.Amount,
                            confidence: 1,
                            value: {
                              ...val.properties.Amount?.value,

                              amount: createCurrency(
                                e.target.value
                              ).value?.toFixed(2),
                            },
                          },
                        },
                      };
                    }
                    return val;
                  }),
                },
              },
            }))
          }
        />
      </Grid>
      <Grid item xs={3} md={1} sx={gridSx}>
        <Input
          value={
            (item?.properties?.Quantity?.value || 0) *
              (item?.properties?.UnitPrice?.value?.amount || 0) -
            (item?.properties?.Discount?.value?.amount || 0) +
            (item?.properties?.Tax?.value?.amount || 0)
          }
        />
      </Grid>
    </Grid>
  );
}
