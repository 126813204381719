import { Box, Grid, Typography } from "@mui/material";
import {
  CustomerCard,
  InvoiceActions,
  InvoiceInfo,
  Items,
  ItemsHeader,
  Total,
  VendorCard,
} from "./Segments/index";
import { ItemTable, Tabs } from "../../components";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { ZoomInMap, ZoomOutMap } from "@mui/icons-material";
import {
  calculateDiscount,
  calculateDiscountPercentage,
  calculateInvoiceTotal,
  calculateSubTotal,
  calculateTotal,
  calculateTotalDiscount,
  calculateTotalVat,
  calculateUnitPrice,
  calculateVat,
} from "./utils";
import { getDownloadURL, ref } from "firebase/storage";
import { useAppContext, useProductContext } from "../../context";
import { useEffect, useState } from "react";

import InvoicePreview from "../InvoicePreview";
import { categorizeByProductName } from "../../utils";
import { db } from "../../firebase";
import { storage } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Invoice({ id, setVendor }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useAppContext();

  const user = useSelector((state) => state.user.userDoc);

  const { alternativePrices, setEditProductId, editProductId } =
    useProductContext();
  const [invoice, setInvoice] = useState();

  const [expanded, setExpanded] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [purchases, setPurchases] = useState({});
  const [products, setProducts] = useState({});

  const [img, setImg] = useState();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (user && invoice) {
      fetchPurchases();
      fetchProducts();
    }
  }, [user, invoice, editProductId]);

  async function fetchProducts() {
    try {
      const productIds = invoice?.fields?.Items?.values?.map(
        (i) => i.productId || "undefined"
      );

      const productsQueries = productIds.map((id) => doc(db, "products", id));
      const productsSnapshots = await Promise.all(
        productsQueries.map((d) => getDoc(d))
      );

      const productsData = {};
      productsSnapshots.forEach((snapshot) => {
        if (snapshot.exists()) {
          productsData[snapshot.id] = snapshot.data();
        }
      });
      setProducts(productsData);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchPurchases() {
    try {
      let purchaseQuery = query(
        collection(db, "purchases"),
        where("invoiceId", "==", invoice?.id)
      );

      onSnapshot(purchaseQuery, (querySnapshot) => {
        let purchases = [];
        querySnapshot.forEach((doc) => {
          purchases.push(doc.data());
        });
        const byGenericName = categorizeByProductName(purchases);
        setPurchases(byGenericName);
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      getInvoiceById(id);
    }
  }, [id]);

  async function getInvoiceById(id) {
    try {
      if (!id) {
        return;
      }
      const invoiceRef = doc(db, "cleanedInvoiceData", id);
      const docSnap = await getDoc(invoiceRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        let values = data.fields.Items?.values?.map((value) => {
          return {
            ...value,
            productId:
              value.productId ||
              `${new Date().valueOf()}-${Math.floor(Math.random() * 1000)}`,
          };
        });

        setInvoice({
          ...data,
          fields: {
            ...data.fields,

            InvoiceDate: {
              ...data.fields.InvoiceDate,
              value:
                data.fields.InvoiceDate?.value ||
                Timestamp.fromDate(new Date()),
            },
            Items: {
              ...data.fields.Items,
              values: [...(values || [])],
            },
          },
          id: docSnap.id,
        });
        setVendor({
          name: data.fields.VendorName?.value,
          taxId: data.fields.VendorTaxId?.value,
        });
      } else {
        setInvoice({ id: "not-found" });
        setVendor({ name: "", taxId: "" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (invoice?.filePath) {
      const storageRef = ref(storage, invoice.filePath);
      getDownloadURL(storageRef)
        .then((url) => {
          setImg(url);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [invoice]);

  async function getCompanies() {
    try {
      if (Object.keys(companies || {}).length > 0) return;
      const querySnapshot = await getDocs(
        query(collection(db, "companyDetails"), where("verified", "==", true))
      );
      const companiesList = {};
      querySnapshot.forEach((doc) => {
        companiesList[doc.id] = { ...doc.data(), id: doc.id };
      });
      setCompanies(companiesList);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Typography
        sx={{
          display: invoice?.id === "not-found" ? "block" : "none",
        }}
      >
        Fatura bulunamadı.
      </Typography>
      <Box
        sx={{
          display: invoice?.id === "not-found" ? "none" : "block",
          padding: 2,
          marginBottom: 4,
        }}
      >
        <InvoiceActions
          id={id}
          imgUrl={img}
          invoice={invoice}
          getInvoiceById={getInvoiceById}
        />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: expanded ? "block" : "none",
            }}
          >
            <InvoicePreview
              img={img || invoice?.imgUrl}
              contentType={invoice?.contentType}
              sx={{ height: "100%", width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={expanded ? 6 : 12}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6}>
                <VendorCard
                  fields={invoice?.fields}
                  setInvoice={setInvoice}
                  companies={companies}
                  fetchCompanies={() => getCompanies()}
                  admin={user?.role === "Admin"}
                  t={t}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <CustomerCard
                  fields={invoice?.fields}
                  setInvoice={setInvoice}
                  companies={companies}
                  fetchCompanies={() => getCompanies()}
                  admin={user?.role === "Admin"}
                  t={t}
                />
              </Grid>
              <Grid
                item
                xs={6}
                onClick={() => setExpanded(!expanded)}
                sx={{
                  position: "relative",
                  display: { xs: "none", md: "block" },
                }}
              >
                <InvoicePreview
                  onClick={() => setExpanded(!expanded)}
                  img={img || invoice?.imgUrl}
                  contentType={invoice?.contentType}
                  sx={{
                    width: "4rem",
                    height: "4rem",
                  }}
                />
                <Box
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    position: "absolute",
                    top: 16,
                    left: 16,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {expanded ? <ZoomInMap /> : <ZoomOutMap />}
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <InvoiceInfo fields={invoice?.fields} setInvoice={setInvoice} />
              </Grid>
            </Grid>
            <Tabs
              tab={tab}
              setTab={setTab}
              tabs={[
                {
                  id: 1,
                  label: t("invoice"),
                  children: (
                    <Box
                      sx={{ overflowX: "auto", width: "100%" }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Box>
                        <ItemsHeader
                          calculateVatTotals={(e) => {
                            e.preventDefault();
                            calculateVat(invoice?.fields, setInvoice);
                          }}
                          calculateDiscount={(e) => {
                            e.preventDefault();
                            calculateDiscount(invoice?.fields, setInvoice);
                          }}
                          calculateTotal={(e) => {
                            e.preventDefault();
                            calculateTotal(invoice?.fields, setInvoice);
                          }}
                          calculateUnitPrice={(e) => {
                            e.preventDefault();
                            calculateUnitPrice(invoice?.fields, setInvoice);
                          }}
                          calculateDiscountPercentage={(e) => {
                            e.preventDefault();
                            calculateDiscountPercentage(
                              invoice?.fields,
                              setInvoice
                            );
                          }}
                        />
                        {invoice?.fields?.Items?.values?.map((item, index) => {
                          const purchase = Object.values(purchases).find(
                            (purchase) => purchase.productId === item.productId
                          );

                          return (
                            <Items
                              key={index}
                              item={item}
                              index={index}
                              setInvoice={setInvoice}
                              calculationError={
                                purchase ? purchase.calculationError : undefined
                              }
                              product={products?.[item.productId]}
                              setProductDialog={() => {
                                setEditProductId(item.productId);
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  ),
                },
                {
                  id: 2,
                  label: t("productsAndServices"),
                  children: (
                    <ItemTable
                      items={purchases}
                      alternativePrices={alternativePrices}
                      handleNavigate={(id) =>
                        navigate(
                          `/overview/product/${purchases?.[id]?.productName}`,
                          {
                            state: { name: purchases?.[id]?.productName },
                          }
                        )
                      }
                    />
                  ),
                },
              ]}
            />
            <Total
              fields={invoice?.fields}
              setInvoice={setInvoice}
              onClickTotalVat={() =>
                calculateTotalVat(invoice?.fields, setInvoice)
              }
              onClickSubTotal={() =>
                calculateSubTotal(invoice?.fields, setInvoice)
              }
              onClickTotal={() =>
                calculateInvoiceTotal(invoice?.fields, setInvoice)
              }
              onClickDiscount={() =>
                calculateTotalDiscount(invoice?.fields, setInvoice)
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
