import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Dialog from "./Dialog";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ProfileDialog({ open, onClose, onConfirm }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userDoc);

  const [data, setData] = useState();
  useEffect(() => {
    setData({
      displayName: user?.displayName,
      phoneNumber: user?.phoneNumber,
      country: user?.country,
      city: user?.city,
      email: user?.email,
    });
  }, [open]);

  return (
    <Dialog
      title={t("profileInfo")}
      open={open}
      onClose={onClose}
      confirmButton={t("save")}
      onConfirm={() => onConfirm(data)}
    >
      <Box>
        <TextField
          sx={{ marginBottom: "2rem" }}
          fullWidth
          label={t("email")}
          value={data?.email || ""}
          disabled
        />
        <TextField
          sx={{ marginBottom: "2rem" }}
          fullWidth
          label={t("userName")}
          value={data?.displayName || ""}
          onChange={(e) => setData({ ...data, displayName: e.target.value })}
        />
        <TextField
          sx={{ marginBottom: "2rem" }}
          fullWidth
          label={t("phoneNumber")}
          type="tel"
          value={data?.phoneNumber || ""}
          onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
        />
        <Typography
          sx={{
            marginLeft: "0.4rem",
          }}
          variant="h6"
          fontWeight="bold"
        >
          {t("location")}
        </Typography>
        <TextField
          sx={{
            marginTop: "1rem",
          }}
          fullWidth
          label={t("country")}
          value={data?.country || ""}
          onChange={(e) => setData({ ...data, country: e.target.value })}
        />
        <TextField
          sx={{
            marginTop: "2rem",
          }}
          fullWidth
          label={t("city")}
          value={data?.city || ""}
          onChange={(e) => setData({ ...data, city: e.target.value })}
        />
      </Box>
    </Dialog>
  );
}
