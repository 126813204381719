import { Box, Button, Typography } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useRef, useState } from "react";

import { AddAPhotoRounded } from "@mui/icons-material";
import { storage } from "../firebase";
import { styled } from "@mui/material/styles";
import { useAppContext } from "../context";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DragDropBox = styled((props) => {
  const { isDragging, ...other } = props;
  return <Box {...other} />;
})(({ isDragging }) => ({
  border: isDragging ? "2px dashed #000" : "2px dashed #ccc",
  borderRadius: "15px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: isDragging ? "#f0f0f0" : "#fff",
}));

export default function UploadInvoice() {
  const { setUploadFiles } = useAppContext();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userDoc);

  const [isDragging, setIsDragging] = useState(false);
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length) {
      processFiles(files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length) {
      processFiles(files);
    }
  };

  const processFiles = (files) => {
    const newFiles = Array.from(files).map((file, i) => ({
      file: file,
      status: "uploading",
      order: i,
    }));
    setUploadFiles((prevFiles) => [...prevFiles, ...newFiles]);
    newFiles.forEach((file) => {
      handleUpload(file);
    });
  };

  const handleUpload = (file) => {
    const timestamp = Date.now();
    const fileNameWithTimestamp = `${
      file.file.name.split(".")[0]
    }-${timestamp}.${file.file.name.split(".").pop()}`;
    const imgRef = ref(
      storage,
      `${user.uid}/invoices/${fileNameWithTimestamp}`
    );
    uploadBytes(imgRef, file.file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setUploadFiles((prevFiles) =>
          prevFiles.map((prevFile) =>
            prevFile === file ? { ...prevFile, status: "uploaded" } : prevFile
          )
        );
      });
    });
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",
      }}
      onClick={handleClick}
    >
      <VisuallyHiddenInput
        multiple
        type="file"
        onChange={handleFileChange}
        ref={inputRef}
      />
      <DragDropBox
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {t("uploadInvoice")}
        </Typography>
        <Typography variant="body1">{t("dragDropFilesHere")}</Typography>
        <Typography variant="body2">{t("or")}</Typography>
        <Button
          component="label"
          startIcon={<AddAPhotoRounded />}
          onClick={handleClick}
          sx={{
            textTransform: "none",
          }}
        >
          {t("clickToSelectFiles")}
        </Button>
      </DragDropBox>
    </Box>
  );
}
