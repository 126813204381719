import { enUS, tr } from "date-fns/locale";

import { format } from "date-fns";
import i18next from "i18next";

// Function to get the appropriate locale based on the current language
const getLocale = (language) => {
  switch (language) {
    case "en":
      return enUS;
    case "tr":
      return tr;
    default:
      return enUS;
  }
};

export const formatDate = (seconds, formatStr = "d MMMM EEEE") => {
  const currentLanguage = i18next.language || "en"; // Default to 'en' if no language is set
  const locale = getLocale(currentLanguage);
  if (typeof seconds === "string") {
    const date = new Date(seconds).getTime();
    return format(date, formatStr, { locale });
  }

  if (seconds?.seconds) {
    seconds = seconds.seconds;
    const date = new Date(seconds * 1000);
    return format(date, formatStr, { locale });
  }
  if (seconds) {
    const date = new Date(seconds * 1000);
    return format(date, formatStr, { locale });
  }
  return "";
};
