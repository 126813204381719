import {
  Box,
  Button,
  DialogContent,
  IconButton,
  Dialog as MuiDialog,
  Slide,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Close } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={500} />;
});

function Dialog({
  open,
  onClose,
  title,
  children,
  maxWidth,
  onConfirm,
  confirmButton,
  confirmId = "submit-dialog",
  bgColor,
  disabled = false,
  sx,
  hideHeader = false,
}) {
  const [style, setStyle] = useState({});
  const [touchStartY, setTouchStartY] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const dialogRef = useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleTouchStart = useCallback((e) => {
    setTouchStartY(e.touches[0].clientY);
    setTranslateY(0);
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      const currentTouchY = e.touches[0].clientY;
      const deltaY = currentTouchY - touchStartY;
      if (deltaY > 0) {
        setTranslateY(deltaY);
      }
    },
    [touchStartY]
  );
  const handleTouchEnd = useCallback(() => {
    if (translateY > 150) {
      onClose();
    } else {
      setTranslateY(0);
    }
  }, [translateY, onClose]);

  useEffect(() => {
    const updateStyles = () => {
      const dialogs = document.querySelectorAll(".stackable-dialog");
      const thisDialogIndex = Array.from(dialogs).findIndex(
        (dialog) => dialog.getAttribute("data-dialog-title") === title
      );

      const updatedStyle = {
        transition: "width 0.3s ease, margin-top 0.3s ease",
        width: "100%",
        marginTop: `${4 + thisDialogIndex * 2}vh`,
      };

      if (thisDialogIndex > 0) {
        for (let i = 0; i < thisDialogIndex; i++) {
          const previousDialog = dialogs[i];
          previousDialog.style.marginTop = `${4 + i * 2}vh`;
          previousDialog.style.width = `calc(100% - ${(i + 1) * 2}vw)`;
        }
      }

      setStyle(updatedStyle);
    };

    if (open) {
      requestAnimationFrame(updateStyles);
    }
  }, [open, title]);

  useEffect(() => {
    if (!open) {
      setStyle({});
      const dialogs = document.querySelectorAll(".stackable-dialog");
      const thisDialogIndex = Array.from(dialogs).findIndex(
        (dialog) => dialog.getAttribute("data-dialog-title") === title
      );
      requestAnimationFrame(() => {
        const dialogs = document.querySelectorAll(".stackable-dialog");
        dialogs.forEach((dialog) => {
          dialog.style.width = "100%";
          dialog.style.marginTop = `${4 + thisDialogIndex * 2}vh`;
        });
      });
    }
  }, [open]);

  return (
    <MuiDialog
      ref={dialogRef}
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      PaperProps={{
        style: style,
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleTouchEnd,
        className: "stackable-dialog",
        "data-dialog-title": title,
      }}
      sx={{
        borderRadius: 20,
        zIndex: 101,
      }}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          background: bgColor,
          height: "2.6rem",
          paddingX: hideHeader ? "0rem" : "0.5rem",
          display: hideHeader ? "none" : "flex",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            left: 0,
          }}
          onClick={onClose}
          aria-label="delete"
        >
          <Close sx={{ color: bgColor ? "#fff" : "#3b3b3b" }} />
        </IconButton>

        <Typography alignSelf="center" fontWeight="bold">
          {title}
        </Typography>
        <Button
          disabled={disabled}
          data-testid={confirmId}
          onClick={onConfirm}
          sx={{
            position: "absolute",
            right: 10,
            fontWeight: "bold",
            fontSize: "0.9rem",
            height: "2.6rem",
            textTransform: "none",
            color: "#2b2b2b",
          }}
        >
          {confirmButton}
        </Button>
      </Box>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",

          ...sx,
        }}
      >
        <div style={{ width: "100%", backgroundColor: bgColor }}>
          {children}
        </div>
      </DialogContent>
    </MuiDialog>
  );
}

export default Dialog;
