import { Box, Typography } from "@mui/material";

export default function SearchNavigation({
  activeSection,
  handleScroll,
  companiesRef,
  productsRef,
  invoicesRef,
}) {
  return (
    <Box
      sx={{
        width: { md: "none", lg: "25%" },
        display: { xs: "none", sm: "none", md: "none", lg: "block" },
        position: "sticky",
        top: 96,
        border: "1px solid #dfdeda",
        borderRadius: 4,
        backgroundColor: "white",
        marginX: 2,
        paddingBottom: "1rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.6rem",
          fontWeight: "bold",
          paddingTop: 2,
          paddingLeft: 2,
        }}
      >
        Bu Sayfada
      </Typography>
      <Typography
        sx={{
          paddingY: "0.6rem",
          paddingX: 2,
          borderLeft: activeSection === "companies" ? "2px solid green" : "",
          cursor: "pointer",
        }}
        onClick={() => handleScroll(companiesRef)}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#f4f2ee";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "white";
        }}
      >
        Firmalar
      </Typography>
      <Typography
        sx={{
          paddingY: "0.6rem",
          paddingX: 2,
          borderLeft: activeSection === "products" ? "2px solid green" : "",
          cursor: "pointer",
        }}
        onClick={() => handleScroll(productsRef)}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#f4f2ee";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "white";
        }}
      >
        Ürünler
      </Typography>
      <Typography
        sx={{
          paddingY: "0.6rem",
          paddingX: 2,
          borderLeft: activeSection === "invoices" ? "2px solid green" : "",
          cursor: "pointer",
        }}
        onClick={() => handleScroll(invoicesRef)}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#f4f2ee";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "white";
        }}
      >
        Faturalar
      </Typography>
    </Box>
  );
}
