import { Link, Navigate, useParams } from "react-router-dom";

import React from "react";
import { blogPosts } from "./blogPosts";

const BlogPost = () => {
  const { lang, postSlug } = useParams();

  const posts = blogPosts[lang] || [];
  const post = posts.find((p) => p.slug === postSlug);

  if (!post) {
    return <Navigate to={`/blog/${lang}`} replace />;
  }

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#f3f4f6",
      minHeight: "100vh",
      padding: "1rem",
    },
    article: {
      width: "100%",
      maxWidth: "800px",
      backgroundColor: "white",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "0.5rem",
      overflow: "hidden",
    },
    header: {
      backgroundColor: "#1f2937",
      color: "white",
      padding: "1.5rem",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "0 0 1rem 0",
    },
    meta: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      fontSize: "0.875rem",
    },
    author: {
      fontWeight: "bold",
    },
    date: {
      color: "#d1d5db",
    },
    imageContainer: {
      width: "100%",
      height: "300px",
      overflow: "hidden",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    content: {
      padding: "1.5rem",
    },
    prose: {
      lineHeight: "1.75",
      fontSize: "1.125rem",
      color: "#1f2937",
    },
    backLink: {
      display: "inline-block",
      marginTop: "2rem",
      padding: "0.5rem 1rem",
      backgroundColor: "#3b82f6",
      color: "white",
      textDecoration: "none",
      borderRadius: "0.25rem",
      fontWeight: "bold",
      transition: "background-color 0.3s",
    },
  };

  return (
    <div style={styles.container}>
      <article style={styles.article}>
        <header style={styles.header}>
          <h1 style={styles.title}>{post.title}</h1>
          <div style={styles.meta}>
            {post.author && <span style={styles.author}>By {post.author}</span>}
            {post.date && (
              <span style={styles.date}>
                {new Date(post.date).toLocaleDateString(lang, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            )}
          </div>
        </header>
        {post.image && (
          <div style={styles.imageContainer}>
            <img src={post.image} alt={post.title} style={styles.image} />
          </div>
        )}
        <div style={styles.content}>
          <div
            style={styles.prose}
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <Link to={`/blog/${lang}`} style={styles.backLink}>
            {lang === "en" ? "Back to Blog" : "Blog'a Dön"}
          </Link>
        </div>
      </article>
    </div>
  );
};

export default BlogPost;
