import { Avatar, Box, Button, Skeleton, Typography } from "@mui/material";
import {
  DeleteOutline,
  InsertPhotoRounded,
  Replay,
  Verified,
} from "@mui/icons-material";
import {
  DesktopNavigation,
  EmptyState,
  InvoicePreview,
  PageHeader,
  UploadInvoice,
  UploadProcess,
} from "../components";
import React, { useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db } from "../firebase";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Upload() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDoc);

  const [images, setImages] = useState(null);
  const [focusedDocument, setFocusedDocument] = useState(null);

  useEffect(() => {
    if (user) {
      getFailedImageList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function getFailedImageList() {
    try {
      const functions = getFunctions();
      const getFailedImageList = httpsCallable(functions, "getFailedImageList");
      const res = await getFailedImageList({ user });
      setImages(res.data?.failedImages);
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteDocumentFromStorage(file) {
    try {
      const functions = getFunctions();
      const deleteStorageFile = httpsCallable(functions, "deleteStorageFile");

      const res = await deleteStorageFile({ user, filePath: file?.filePath });
      setImages((prev) =>
        prev.filter((item) => item.filePath !== file.filePath)
      );
      setFocusedDocument(null);
      enqueueSnackbar(t("documentDeleted"), { variant: "success" });
    } catch (error) {
      console.error(error);
    }
  }

  async function processDocument(item) {
    try {
      const functions = getFunctions();
      const processUploadedFileAndCleanData = httpsCallable(
        functions,
        "processUploadedFileAndCleanData"
      );
      const res = await processUploadedFileAndCleanData({
        imgUrl: item.signedUrl,
        userUid: user.uid,
        contentType: item.contentType,
        filePath: item.filePath,
      });

      await addDoc(collection(db, "notifications"), {
        message: "newInvoiceUploaded",
        userUid: user.uid,
        timestamp: serverTimestamp(),
        notified: false,
        invoiceId: res?.data?.id,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("errorOccured"), {
        variant: "error",
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          // width: "40%",
          width: { lg: "40%", md: "75%", sm: "100%", xs: "100%" },

          borderLeft: {
            lg: "1px solid lightgray",
            md: "1px solid lightgray",
            sm: "none",
            xs: "none",
          },
          borderRight: {
            lg: "1px solid lightgray",
            md: "1px solid lightgray",
            sm: "none",
            xs: "none",
          },
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: -1,
            backgroundColor: "white",
            zIndex: 10,
          }}
        >
          <PageHeader title={t("uploadInvoice")} />
        </Box>

        {images === null || images.length > 0 ? (
          <Typography
            sx={{
              paddingX: "1rem",
              paddingTop: "0.6rem",
              fontWeight: "bold",
            }}
          >
            {t("pendingDocuments")}
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0.6rem",
              borderBottom: "1px solid rgba(0,0,0,.1)",
            }}
          >
            <Verified
              style={{
                color: "#34a853",
              }}
            />
            <Typography
              sx={{
                paddingX: "1rem",
                fontWeight: "bold",
              }}
            >
              {t("noPendingDocuments")}
            </Typography>
          </Box>
        )}

        {images === null && (
          <Box sx={{ padding: 1 }}>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={60}
              sx={{ marginBottom: 1 }}
            />
            <Skeleton variant="rounded" width={"100%"} height={60} />
          </Box>
        )}
        <Box
          sx={{
            margin: "1rem",
            marginTop: "0.4rem",
            display: { lg: "none", md: "none", sm: "block", xs: "block" },

            cursor: "pointer",
          }}
        >
          <UploadInvoice />
        </Box>

        {images?.map((image, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0.6rem",
                borderBottom: "1px solid rgba(0,0,0,.1)",
                height: "51.82px",
                cursor: "pointer",
              }}
              onClick={() => setFocusedDocument(image)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    backgroundColor: "#d93026",
                    height: "2rem",
                    width: "2rem",
                  }}
                >
                  <InsertPhotoRounded
                    sx={{
                      color: "white",
                    }}
                  />
                </Avatar>
                <Typography
                  sx={{
                    paddingLeft: "0.8rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "18rem",
                  }}
                >
                  {image?.filePath}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => deleteDocumentFromStorage(image)}
                  color="error"
                  sx={{
                    display: user?.role === "Admin" ? "block" : "none",
                  }}
                >
                  <DeleteOutline />
                </Button>
                <Button onClick={() => processDocument(image)}>
                  <Replay />
                </Button>
              </Box>
            </Box>
          );
        })}

        <UploadProcess />
      </Box>
      <Box
        sx={{
          position: "sticky",
          width: "30%",
          display: { lg: "block", md: "block", sm: "none", xs: "none" },

          top: -1,
        }}
      >
        <Box
          sx={{
            margin: "1rem",
            marginTop: "0.4rem",
            // padding: "0.8rem",
            // borderRadius: "15px",
            // border: "1px solid lightgray",
            cursor: "pointer",
          }}
        >
          <UploadInvoice />
        </Box>
        <Box
          sx={{
            margin: "1rem",
            marginTop: "0.4rem",
            padding: "0.8rem",
            borderRadius: "15px",
            border: "1px solid lightgray",
            cursor: "pointer",
          }}
          onClick={() => {
            focusedDocument?.signedUrl &&
              navigate("/document-preview", { state: { focusedDocument } });
          }}
        >
          <InvoicePreview
            sx={{ height: "100%", width: "100%" }}
            contentType={focusedDocument?.contentType}
            img={focusedDocument?.signedUrl}
          />
        </Box>
        <Box
          sx={{
            margin: "1rem",
            marginTop: "0.4rem",
            padding: "0.8rem",
            borderRadius: "15px",
            border: "1px solid lightgray",
            cursor: "pointer",
          }}
        >
          <EmptyState
            customStyle={{ flex: 1, marginTop: 20 }}
            header={`${t("welcome")}!`}
            textLines={[
              t("uploadInvoiceText1"),
              t("uploadInvoiceText2"),
              t("uploadInvoiceText3"),
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
}
