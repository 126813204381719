import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import { db } from "../../firebase";

export const handleTaxNoOnBlur = async function ({ id, role, setInvoice }) {
  try {
    const companyRef = doc(db, "companyDetails", id);
    const docSnap = await getDoc(companyRef);
    if (docSnap.exists()) {
      const res = docSnap.data();

      if (role === "Customer") {
        setInvoice((prev) => ({
          ...prev,
          fields: {
            ...prev.fields,
            CustomerName: {
              ...prev.fields.CustomerName,
              confidence: 1,
              value: res.name || "",
            },
            CustomerAddress: {
              ...prev.fields.CustomerAddress,
              confidence: 1,
              content: res.address || "",
            },
            CustomerPhone: {
              ...prev.fields.CustomerPhone,
              confidence: 1,
              content: res.phone || "",
            },
            CustomerMail: {
              ...prev.fields.CustomerMail,
              confidence: 1,
              content: res.mail || "",
            },
            CustomerTaxOffice: {
              ...prev.fields.CustomerTaxOffice,
              confidence: 1,
              content: res.taxoffice || "",
            },
            CustomerTaxId: {
              ...prev.fields.CustomerTaxId,
              confidence: 1,
              value: res.taxid || "",
            },
          },
        }));
      }
      if (role === "Vendor") {
        setInvoice((prev) => ({
          ...prev,
          fields: {
            ...prev.fields,
            VendorName: {
              ...prev.fields.VendorName,
              confidence: 1,
              value: res.name || "",
            },
            VendorAddress: {
              ...prev.fields.VendorAddress,
              confidence: 1,
              content: res.address || "",
            },
            VendorPhone: {
              ...prev.fields.VendorPhone,
              confidence: 1,
              content: res.phone || "",
            },
            VendorMail: {
              ...prev.fields.VendorMail,
              confidence: 1,
              content: res.mail || "",
            },
            VendorTaxOffice: {
              ...prev.fields.VendorTaxOffice,
              confidence: 1,
              content: res.taxoffice || "",
            },
            VendorTaxId: {
              ...prev.fields.VendorTaxId,
              confidence: 1,
              value: res.taxid || "",
            },
          },
        }));
      }
    } else {
      console.error("No such document!");
    }
  } catch (error) {
    console.error(error);
  }
};

export function convertToVatRate(vatRate) {
  if (typeof vatRate !== "string" || !vatRate.trim()) {
    return 0;
  }

  const cleanedRate = vatRate.replace(/\s/g, "").replace(",", ".");

  const numberPattern = /(\d+\.?\d*)%?/;
  const match = cleanedRate.match(numberPattern);

  if (!match) return 0;

  const [matchedString, numberPart] = match;

  const numberValue = parseFloat(numberPart);

  const rate = matchedString.includes("%") ? numberValue / 100 : numberValue;

  return isNaN(rate) ? 0 : rate;
}

export const calculateVat = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  const updatedItems = fields.Items.values.map((item) => {
    const quantity = Number(item.properties?.Quantity?.value || 0);
    const unitPrice = Number(item.properties?.UnitPrice?.value?.amount || 0);
    const discountRate =
      (Number(item.properties?.DiscountRate?.value || 0) || 0) / 100;
    const taxRate =
      convertToVatRate(item.properties?.TaxRate?.value || 0) / 100;
    const tax = quantity * unitPrice * taxRate * (1 - discountRate);

    return {
      ...item,
      properties: {
        ...item.properties,
        Tax: {
          ...item.properties.Tax,
          confidence: 1, // Assuming confidence should be set to 1 after calculation
          value: {
            ...item.properties.Tax?.value,
            amount: tax,
          },
        },
      },
    };
  });

  // Update the state with the new items
  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      Items: {
        ...prev.fields.Items,
        values: updatedItems,
      },
    },
  }));
};

export const calculateDiscount = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  const updatedItems = fields.Items.values.map((item) => {
    const quantity = Number(item.properties?.Quantity?.value || 0);
    const unitPrice = Number(item.properties?.UnitPrice?.value?.amount || 0);
    const discountRate =
      (Number(item.properties?.DiscountRate?.value || 0) || 0) / 100;
    const discount = quantity * unitPrice * discountRate; // Calculated tax

    return {
      ...item,
      properties: {
        ...item.properties,
        Discount: {
          ...item.properties.Discount,
          confidence: 1, // Assuming confidence should be set to 1 after calculation
          value: {
            ...item.properties.Discount?.value,
            amount: discount,
          },
        },
      },
    };
  });

  // Update the state with the new items
  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      Items: {
        ...prev.fields.Items,
        values: updatedItems,
      },
    },
  }));
};

export const calculateDiscountPercentage = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }
  const updatedItems = fields.Items.values.map((item) => {
    const quantity = Number(item.properties?.Quantity?.value || 0);
    const unitPrice = Number(item.properties?.UnitPrice?.value?.amount || 0);
    const discount = Number(item.properties?.Discount?.value?.amount || 0);
    const discountRate = (discount / (quantity * unitPrice)) * 100;
    return {
      ...item,
      properties: {
        ...item.properties,
        DiscountRate: {
          ...item.properties.DiscountRate,
          confidence: 1,
          value: discountRate,
        },
      },
    };
  });

  // Update the state with the new items
  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      Items: {
        ...prev.fields.Items,
        values: updatedItems,
      },
    },
  }));
};

export const calculateTotal = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  // Calculate new tax values
  const updatedItems = fields.Items.values.map((item) => {
    const quantity = Number(item.properties?.Quantity?.value || 0);
    const unitPrice = parseFloat(
      item.properties?.UnitPrice?.value?.amount || 0
    );
    const total = quantity * unitPrice; // Calculated tax

    return {
      ...item,
      properties: {
        ...item.properties,
        Amount: {
          ...item.properties.Amount,
          confidence: 1, // Assuming confidence should be set to 1 after calculation
          value: {
            ...item.properties.Amount?.value,
            amount: total,
          },
        },
      },
    };
  });

  // Update the state with the new items
  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      Items: {
        ...prev.fields.Items,
        values: updatedItems,
      },
    },
  }));
};

export const calculateTotalDiscount = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  const totalDiscount = fields.Items.values.reduce((acc, item) => {
    const dicount = parseFloat(item.properties?.Discount?.value?.amount || 0);
    return acc + dicount;
  }, 0);

  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      TotalDiscount: {
        ...prev.fields.TotalDiscount,
        confidence: 1,
        value: {
          ...prev.fields.TotalDiscount?.value,
          amount: totalDiscount,
        },
      },
    },
  }));
};

export const calculateUnitPrice = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  const updatedItems = fields.Items.values.map((item) => {
    const quantity = Number(item.properties?.Quantity?.value || 0);
    // const unitPrice = Number(item.properties?.UnitPrice?.value?.amount || 0);
    const total = Number(item.properties?.Amount?.value?.amount || 0);
    const vatRate = convertToVatRate(item.properties?.TaxRate?.value || 0);

    const unitPrice = total / (1 + vatRate / 100) / quantity;

    return {
      ...item,
      properties: {
        ...item.properties,
        UnitPrice: {
          ...item.properties.UnitPrice,
          confidence: 1,
          value: {
            ...item.properties.UnitPrice?.value,
            amount: unitPrice,
          },
        },
      },
    };
  });

  // Update the state with the new items
  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      Items: {
        ...prev.fields.Items,
        values: updatedItems,
      },
    },
  }));
};

export const calculateTotalVat = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  const totalVat = fields.Items.values.reduce((acc, item) => {
    const tax = parseFloat(item.properties?.Tax?.value?.amount || 0);
    return acc + tax;
  }, 0);

  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      TotalTax: {
        ...prev.fields.TotalTax,
        confidence: 1,
        value: {
          ...prev.fields.TotalTax?.value,
          amount: totalVat,
        },
      },
    },
  }));
};

export const calculateSubTotal = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }

  const subTotal = fields.Items.values.reduce((acc, item) => {
    const sub = parseFloat(item.properties?.Amount?.value?.amount || 0);
    return acc + sub;
  }, 0);

  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      SubTotal: {
        ...prev.fields.SubTotal,
        confidence: 1,
        value: {
          ...prev.fields.SubTotal?.value,
          amount: subTotal,
        },
      },
    },
  }));
};

export const calculateInvoiceTotal = (fields, setInvoice) => {
  if (!fields?.Items?.values) {
    console.error("Items are not defined");
    return;
  }
  const subTotal = Number(fields?.SubTotal?.value?.amount || 0);
  const discount = Number(fields?.TotalDiscount?.value?.amount || 0);
  const tax = Number(fields?.TotalTax?.value?.amount || 0);
  const total = subTotal + tax - discount;

  setInvoice((prev) => ({
    ...prev,
    fields: {
      ...prev.fields,
      InvoiceTotal: {
        ...prev.fields.InvoiceTotal,
        confidence: 1,
        value: {
          ...prev.fields.InvoiceTotal?.value,
          amount: total,
        },
      },
    },
  }));
};

export function deepCleanObject(obj, convertUndefinedToNull = false) {
  if (Array.isArray(obj)) {
    return obj.map((value) => {
      if (typeof value === "object" && value !== null) {
        return deepCleanObject(value, convertUndefinedToNull);
      }
      return value;
    });
  } else if (typeof obj === "object" && obj !== null) {
    const cleaned = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        cleaned[key] = deepCleanObject(obj[key], convertUndefinedToNull);
      } else if (obj[key] !== undefined) {
        cleaned[key] = obj[key];
      } else if (convertUndefinedToNull) {
        cleaned[key] = null;
      }
    });
    return cleaned;
  }
  return obj;
}

export const checkCompanyDetails = async function (invoice) {
  const vendorDetails = {
    name: invoice?.fields?.VendorName?.value,
    address: invoice?.fields?.VendorAddress?.content,
    phone: invoice?.fields?.VendorPhone?.content,
    mail: invoice?.fields?.VendorMail?.content,
    taxoffice: invoice?.fields?.VendorTaxOffice?.content,
    taxid: invoice?.fields?.VendorTaxId?.value,
    location: invoice?.fields?.Location?.content,
  };

  const customerDetails = {
    name: invoice?.fields?.CustomerName?.value,
    address: invoice?.fields?.CustomerAddress?.content,
    phone: invoice?.fields?.CustomerPhone?.content,
    mail: invoice?.fields?.CustomerMail?.content,
    taxoffice: invoice?.fields?.CustomerTaxOffice?.content,
    taxid: invoice?.fields?.CustomerTaxId?.value,
  };

  Object.keys(vendorDetails).forEach(
    (key) => vendorDetails[key] === undefined && delete vendorDetails[key]
  );

  Object.keys(customerDetails).forEach(
    (key) => customerDetails[key] === undefined && delete customerDetails[key]
  );

  try {
    await saveOrUpdateCompanyDetails(vendorDetails);
    await saveOrUpdateCompanyDetails(customerDetails);
  } catch (error) {
    console.error(error);
  }
};

async function saveOrUpdateCompanyDetails(details) {
  try {
    const companyRef = doc(collection(db, "companyDetails"), details?.taxid);
    const docSnap = await getDoc(companyRef);
    const data = docSnap.data();
    const isComplete = checkCompanyDetailsComplete(details);
    if (data?.verified) {
      return;
    }

    if (docSnap.exists()) {
      await updateDoc(companyRef, { ...details, verified: isComplete });
    } else {
      await setDoc(companyRef, { ...details, verified: isComplete });
    }
  } catch (error) {
    console.error(error);
  }
}

function checkCompanyDetailsComplete(details) {
  return Object.values(details).every((value) => value.length > 2);
}
