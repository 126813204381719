import { AccessTimeRounded, AddRounded } from "@mui/icons-material";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { Dialog } from "../../components";
import algoliasearch from "algoliasearch/lite";
import { useTranslation } from "react-i18next";

export default function SearchUserDialog({
  open,
  onClose,
  onConfirm,
  inviteUserToCompany,
  removeUserInvitation,
  pendingUsers,
  employeeIds,
}) {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (search.length > 3) {
      searchUsers(search);
    }
  }, [search]);

  async function searchUsers(search) {
    algoliasearch("LM6U4D5EAY", "1edf580258a77271c4bdfc08130aa35e")
      .initIndex("users")
      .search(search, {
        hitsPerPage: 100,
      })
      .then(({ hits }) => {
        hits = hits.filter(
          (hit) =>
            !employeeIds.includes(hit.objectID) &&
            hit.objectID !== employeeIds[0]
        );

        setUsers(hits);
      });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={t("addMember")}
      confirmButton=""
      sx={{ minHeight: "40rem" }}
    >
      <TextField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        placeholder={t("searchUser")}
      />

      {users?.map((employee, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              paddingY: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #dfdeda",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Avatar src={employee?.photoURL}>
                {employee?.displayName?.[0]?.toUpperCase()}
              </Avatar>
              <Typography sx={{ paddingLeft: 1 }}>
                {employee?.displayName}
              </Typography>
            </Box>
            {pendingUsers?.includes(employee?.objectID) ? (
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  padding: "0.5rem 1rem",
                  borderRadius: 8,
                  height: "2.2rem",
                }}
                startIcon={<AccessTimeRounded />}
                variant="outlined"
                onClick={() => removeUserInvitation(employee)}
              >
                {t("pending")}
              </Button>
            ) : (
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  padding: "0.5rem 1rem",
                  borderRadius: 8,
                  height: "2.2rem",
                }}
                startIcon={<AddRounded />}
                variant="outlined"
                onClick={() => inviteUserToCompany(employee)}
              >
                {t("invite")}
              </Button>
            )}
          </Box>
        );
      })}
    </Dialog>
  );
}
