import { Avatar, Box } from "@mui/material";
import {
  DesktopNavigation,
  InputGroup,
  PageHeader,
  ProfileDialog,
} from "../components";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import { db } from "../firebase";
import uniqolor from "uniqolor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const [profileDialog, setProfileDialog] = useState(false);
  const user = useSelector((state) => state.user.userDoc);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      setLoading(true);
    } else {
      setLoading(false);
      setUserData(user);
    }
  }, [user]);

  async function handleUpdateProfile(key, value) {
    try {
      setLoading(true);
      const updatedData = { ...userData, [key]: value };
      const userDocRef = doc(db, "users", user.uid);
      console.log(key, value);
      await setDoc(userDocRef, { [key]: value }, { merge: true });
      setUserData(updatedData);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  }

  const inputItems = [
    {
      label: t("email"),
      key: "email",
      value: userData?.email || "",
      iconLeft: <MailIcon style={{ color: "#ccc" }} />,
      keyboardType: "email",
      disabled: true,
    },
    {
      label: t("userName"),
      key: "displayName",
      value: userData?.displayName || "",
      iconLeft: <PersonIcon style={{ color: "#ccc" }} />,
    },
    {
      label: t("phoneNumber"),
      key: "phoneNumber",
      value: userData?.phoneNumber || "",
      iconLeft: <PhoneIcon style={{ color: "#ccc" }} />,
      keyboardType: "tel",
    },
    {
      label: t("country"),
      key: "country",
      value: userData?.country || "",
      iconLeft: <PublicIcon style={{ color: "#ccc" }} />,
    },
    {
      label: t("city"),
      key: "city",
      value: userData?.city || "",
      iconLeft: <LocationCityIcon style={{ color: "#ccc" }} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        background: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: { lg: "76%", md: "100%", sm: "100%", xs: "100%" },
          borderLeft: "1px solid lightgray",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        <PageHeader
          title={t("profile")}
          hideBackButton={false}
          loading={loading}
        />

        <Avatar
          sx={{
            backgroundColor: `${uniqolor(user?.displayName).color}`,
            width: "6rem",
            height: "6rem",
            fontSize: "5rem",
            border: "5px solid white",
            marginY: "2rem",
          }}
          src={user?.logoUrl}
        >
          {user?.displayName?.slice(0, 1)?.toUpperCase()}
        </Avatar>
        <Box
          sx={{
            width: { lg: "50%", md: "75%", sm: "96%", xs: "96%" },
          }}
        >
          <InputGroup
            items={inputItems}
            onChangeText={(key, value) => {
              setUserData({ ...userData, [key]: value });
            }}
            onBlur={(key, value) => {
              handleUpdateProfile(key, value);
            }}
            onPressRightIcon={() => {}}
          />
        </Box>
      </Box>

      <ProfileDialog
        open={profileDialog}
        onClose={() => setProfileDialog(false)}
        onConfirm={(value) => {
          Object.entries(value).forEach(([key, val]) =>
            handleUpdateProfile(key, val)
          );
        }}
      />
    </Box>
  );
}
