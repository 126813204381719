import { Box, Chip } from "@mui/material";

export default function MobileSearchNavigation({
  activeSection,
  handleScroll,
  companiesRef,
  productsRef,
  invoicesRef,
}) {
  return (
    <Box
      sx={{
        display: {
          md: "flex",
          lg: "none",
        },
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "0.4rem 0",
      }}
    >
      <Chip
        sx={{
          backgroundColor: activeSection === "companies" ? "#f4f2ee" : "white",
          border: "1px solid #dfdeda",
          marginLeft: "0.2rem",
        }}
        label="Firmalar"
        onClick={() => handleScroll(companiesRef)}
        variant="outlined"
      />
      <Chip
        sx={{
          backgroundColor: activeSection === "products" ? "#f4f2ee" : "white",
          border: "1px solid #dfdeda",
          marginLeft: "0.2rem",
        }}
        label="Ürünler"
        onClick={() => handleScroll(productsRef)}
        variant="outlined"
      />
      <Chip
        sx={{
          backgroundColor: activeSection === "invoices" ? "#f4f2ee" : "white",
          border: "1px solid #dfdeda",
          marginLeft: "0.2rem",
        }}
        label="Faturalar"
        onClick={() => handleScroll(invoicesRef)}
        variant="outlined"
      />
    </Box>
  );
}
