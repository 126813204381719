import { Invoice, PageHeader } from "../components";

import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function InvoiceView() {
  const { id } = useParams();
  const [vendor, setVendor] = useState({
    name: "",
    taxId: "",
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <PageHeader title={vendor?.name} />
        <Invoice id={id} setVendor={setVendor} vendor={vendor} />
      </Box>
    </Box>
  );
}
