import { Bar, ComposedChart, Line, ResponsiveContainer, XAxis } from "recharts";
import { Box, Typography } from "@mui/material";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowsAltIcon from "@mui/icons-material/SwapHoriz";
import React from "react";
import { createCurrency } from "../utils";
import { formatDate } from "../utils";
import { useTranslation } from "react-i18next";

const CustomDot = (props) => {
  const { cx, cy, index, selectedValue } = props;
  if (index === selectedValue.x) {
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        stroke="#0b66c2"
        strokeWidth={2}
        fill="white"
      />
    );
  }
  return (
    <circle
      cx={cx}
      cy={cy}
      r={3}
      stroke="#cfe6fc"
      strokeWidth={2}
      fill="blue"
    />
  );
};

const ProductLineChart = ({
  selectedValue,
  priceLine,
  onSelectedPoint,
  hideAmount = false,
}) => {
  const formattedData = priceLine?.map((item) => ({
    ...item,
    date: new Date(item?.date?.seconds * 1000),
  }));
  const { t } = useTranslation();

  const maxAmount = priceLine?.length
    ? Math.max(...priceLine?.map((item) => item?.amount || 0))
    : 0;
  const minHeight = 50;
  const maxHeight = 200;
  const scaleFactor = maxHeight / maxAmount;

  return (
    <Box>
      <Box sx={styles.container}>
        <Box>
          <Typography sx={{ fontSize: 12 }}>
            {formatDate(selectedValue?.date, "dd MMMM")}
          </Typography>
          {!hideAmount && (
            <Typography>
              {selectedValue?.amount} {t("pieces")}
            </Typography>
          )}
        </Box>

        <Typography sx={styles.priceText}>
          {createCurrency(selectedValue?.y).format()}
        </Typography>
        <Box
          sx={{
            ...styles.priceChangeContainer,
            backgroundColor:
              (selectedValue?.priceChange || 0) === 0
                ? "#0b66c2"
                : selectedValue?.priceChange >= 0
                ? "green"
                : "red",
          }}
        >
          {(selectedValue?.priceChange || 0) === 0 ? (
            <ArrowsAltIcon sx={{ color: "#fff" }} />
          ) : selectedValue?.priceChange >= 0 ? (
            <ArrowDownwardIcon sx={{ color: "#fff" }} />
          ) : (
            <ArrowUpwardIcon sx={{ color: "#fff" }} />
          )}
          <Typography sx={styles.priceChangeText}>
            {Math.abs(selectedValue?.priceChange || 0)?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart
          data={formattedData}
          onClick={(e) => onSelectedPoint(e?.activeTooltipIndex)}
        >
          <XAxis
            dataKey="date"
            tickFormatter={(tick) => formatDate(tick?.seconds, "dd MMMM")}
          />
          <Bar
            dataKey="amount"
            fill="#82ca9d"
            shape={(props) => {
              const { x, y, width, height, value } = props;
              const normalizedHeight = Math.max(
                minHeight,
                Math.min(maxHeight, value * scaleFactor)
              ); // Normalize the height
              return (
                <rect
                  x={x}
                  y={y + height - normalizedHeight}
                  width={width}
                  height={normalizedHeight}
                  fill={props.index === selectedValue.x ? "#0b66c2" : "#cfe6fc"}
                />
              );
            }}
          />
          <Line
            type="monotone"
            dataKey="y"
            stroke="#8884d8"
            dot={(props) => (
              <CustomDot {...props} selectedValue={selectedValue} />
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: 0,
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: 0,
  },
  dateText: {
    color: "#666",
    fontWeight: "bold",
  },
  priceText: {
    fontWeight: "bold",
    fontSize: 24,
  },
  priceChangeContainer: {
    paddingX: 1,
    margin: 1,
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  priceChangeText: {
    color: "#fff",
    fontWeight: "bold",
  },
};

export default ProductLineChart;
