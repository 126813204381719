import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { setCompanyInvoices } from "../../redux/Invoices/invoiceSlice";

export async function fetchVendorInvoices({
  user,
  dispatch,
  vendorName,
  filterByDate,
  startDate,
  endDate,
}) {
  try {
    dispatch(setCompanyInvoices());

    const getVendorInvoices = httpsCallable(functions, "fetchVendorInvoices");

    const response = await getVendorInvoices({
      vendorName,
      startDate,
      endDate,
      filterByDate,
    });
    console.log({ response });
    dispatch(setCompanyInvoices(response.data));
  } catch (error) {
    console.error("Failed to fetch invoices:", error);
  }
}
