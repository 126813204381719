import { Box } from "@mui/material";
import { InvoiceWidget } from "../../components";
import { useSelector } from "react-redux";

export default function CompanyInvoices({ taxId, companyName }) {
  const { companyInvoices } = useSelector((state) => state.invoices);

  return (
    <Box>
      {companyInvoices &&
        Object.entries(companyInvoices).map(([id, invoice]) => {
          return <InvoiceWidget key={id} invoice={invoice} />;
        })}
    </Box>
  );
}
