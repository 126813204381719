import { CategoryTabs, ProductWidget } from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db, functions } from "../../firebase";

import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSelector } from "react-redux";

const sanitizeForFirestore = (str) => {
  return str?.replace(/\//g, "-").replace(/[^a-zA-Z0-9-_]/g, "_");
};
const OverviewProducts = ({ loading, setLoading }) => {
  const user = useSelector((state) => state.user.userDoc);
  const { filters, categories } = useSelector((state) => state.overView);
  const { dateRange } = useSelector((state) => state.dateRange);

  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState({});
  const [categoryTab, setCategoryTab] = useState(0);
  const [subCategoryTab, setSubCategoryTab] = useState(0);
  const [aggPurchases, setAggPurchases] = useState({});

  const fetchSubCategories = useCallback(
    async (categoryIndex) => {
      try {
        setLoading(true);
        const sanitizedCategory = sanitizeForFirestore(
          categories[categoryIndex]
        );
        const subCategoriesRef = collection(
          db,
          `analytics/${
            user?.companyId || user?.uid
          }/categories/${sanitizedCategory}/subCategories`
        );
        const snapshot = await getDocs(subCategoriesRef);
        const fetchedSubCategories = snapshot.docs.map(
          (doc) => doc.data().originalName
        );
        setSubCategories(fetchedSubCategories);
        setSubCategoryTab(0);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      } finally {
        setLoading(false);
      }
    },
    [user, categories, setLoading]
  );

  const fetchAggregatePurchasesByProductList = useCallback(
    async (productList) => {
      if (!productList.length) return;
      try {
        const getAggregatePurchasesByProductList = httpsCallable(
          functions,
          "aggregatePurchasesByProductList"
        );
        const response = await getAggregatePurchasesByProductList({
          customerId: user?.companyId,
          userUid: user?.uid,
          products: productList,
          startDate: Math.floor(new Date(dateRange.startDate).getTime() / 1000),
          endDate: Math.floor(new Date(dateRange.endDate).getTime() / 1000),
          filterByDate: filters?.filterByDate,
        });
        setAggPurchases(response.data.purchaseAggregates);
      } catch (error) {
        console.error("Error fetching aggregate purchases:", error);
      }
    },
    [user, dateRange, filters]
  );

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const sanitizedCategory = sanitizeForFirestore(categories[categoryTab]);
      const sanitizedSubCategory = sanitizeForFirestore(
        subCategories[subCategoryTab]
      );
      const productsRef = collection(
        db,
        `analytics/${
          user?.companyId || user?.uid
        }/categories/${sanitizedCategory}/subCategories/${sanitizedSubCategory}/products`
      );

      const snapshot = await getDocs(query(productsRef));
      const fetchedProducts = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.data().originalName,
          { ...doc.data(), id: doc.id },
        ])
      );

      setProducts(fetchedProducts);
      fetchAggregatePurchasesByProductList(Object.keys(fetchedProducts || {}));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    categories,
    categoryTab,
    subCategories,
    subCategoryTab,
    user?.companyId,
    user?.uid,
    fetchAggregatePurchasesByProductList,
  ]);

  useEffect(() => {
    if (user && categories.length > 0) {
      fetchSubCategories(categoryTab);
    }
  }, [user, categories, categoryTab, fetchSubCategories]);

  useEffect(() => {
    if (user && categories.length > 0 && subCategories.length > 0) {
      fetchProducts();
    }
  }, [
    user,
    categories,
    subCategories,
    categoryTab,
    subCategoryTab,
    fetchProducts,
  ]);

  const handleCategoryChange = (newValue) => {
    setCategoryTab(newValue);
    setSubCategoryTab(0);
  };

  const renderProducts = () => {
    let productsArray = Object.values(products);
    const { activeKey } = filters;
    const sortOrder = filters[activeKey];

    const getProductValue = (product, key) => {
      if (aggPurchases && aggPurchases[product.originalName]) {
        return aggPurchases[product.originalName][key] || product[key] || 0;
      }
      return product[key] || 0;
    };

    const sortFunctions = {
      sortByName: (a, b) => a.originalName.localeCompare(b.originalName),
      sortByCount: (a, b) =>
        (aggPurchases[b.originalName]?.count || 0) -
        (aggPurchases[a.originalName]?.count || 0),
      sortByTax: (a, b) =>
        getProductValue(b, "totalVat") - getProductValue(a, "totalVat"),
      sortByDiscount: (a, b) =>
        getProductValue(b, "totalDiscount") -
        getProductValue(a, "totalDiscount"),
      sortByTotal: (a, b) =>
        getProductValue(b, "totalAmount") - getProductValue(a, "totalAmount"),
    };

    if (sortFunctions[activeKey]) {
      productsArray.sort(sortFunctions[activeKey]);
      if (sortOrder === "asc") {
        productsArray.reverse();
      }
    }
    const productsWithAggPurchases = [];
    const productsWithoutAggPurchases = [];

    productsArray.forEach((product) => {
      const aggPurchase = aggPurchases && aggPurchases[product.originalName];
      const productData = {
        ...product,
        productName: product.originalName,
        tax: aggPurchase ? aggPurchase.tax : product.tax,
        discount: aggPurchase ? aggPurchase.discount : product.discount,
        total: aggPurchase ? aggPurchase.total : product.total,
        date: aggPurchase ? aggPurchase.date : product.date,
      };

      const productWidget = (
        <ProductWidget
          key={product.id}
          product={productData}
          history={aggPurchase}
        />
      );

      if (aggPurchase) {
        productsWithAggPurchases.push(productWidget);
      } else {
        productsWithoutAggPurchases.push(productWidget);
      }
    });

    return [...productsWithAggPurchases, ...productsWithoutAggPurchases];
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          borderBottom: "1px solid lightgray",
          padding: 2,
        }}
      >
        <CategoryTabs
          categories={categories}
          value={categoryTab}
          setValue={handleCategoryChange}
        />
        <CategoryTabs
          categories={subCategories}
          value={subCategoryTab}
          setValue={setSubCategoryTab}
        />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>{renderProducts()}</Box>
    </Box>
  );
};

export default OverviewProducts;
