import { categorizeByProductName } from "./categorizeByProductName";
import { subCategories } from "../constants/categories";

export function categorizeByCategory(purchases) {
  let list = {};

  purchases?.forEach((purchase) => {
    const category = purchase.category || "Undefined";

    if (!list[category]) {
      list[category] = {
        purchases: [],
        products: {},
        subCategories: {},
      };
    }
    list[category].purchases.push(purchase);
  });

  Object.entries(list).forEach(([key, value]) => {
    list[key].products = categorizeByProductName(value.purchases);

    value?.purchases?.forEach((purchase) => {
      const subCategory = purchase.subCategory || "Undefined";
      if (!list[key].subCategories[subCategory]) {
        list[key].subCategories[subCategory] = {
          purchases: [],
          products: {},
        };
      }
      list[key].subCategories[subCategory].purchases.push(purchase);
    });
  });

  // categorize subcategory products
  Object.entries(list).forEach(([key, value]) => {
    Object.entries(value.subCategories).forEach(
      ([subCategory, subCategoryValue]) => {
        list[key].subCategories[subCategory].products = categorizeByProductName(
          subCategoryValue.purchases
        );
      }
    );
  });

  return list;
}
