import { Box } from "@mui/material";
import Input from "../../Input";
import { createCurrency } from "../../../utils";

export default function Total({
  fields,
  setInvoice,
  onClickTotalVat,
  onClickSubTotal,
  onClickDiscount,
  onClickTotal,
}) {
  function calculateDiscountPercentage() {
    const subTotal = fields?.SubTotal?.value?.amount || 0;
    const discount = fields?.TotalDiscount?.value?.amount || 0;
    const discountPercentage = (discount / subTotal) * 100;
    return discountPercentage;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        padding: 2,
      }}
    >
      <Input
        textAlign="right"
        titleSx={{ minWidth: "8rem" }}
        onClick={onClickSubTotal}
        title="Ara Toplam"
        value={fields?.SubTotal?.value?.amount || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              SubTotal: {
                ...prev.fields.SubTotal,
                confidence: 1,
                value: {
                  ...prev.fields.SubTotal?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              SubTotal: {
                ...prev.fields.SubTotal,
                confidence: 1,
                value: {
                  ...prev.fields.SubTotal?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
      />
      <Input
        textAlign="right"
        placeholder={"0.00"}
        titleSx={{ minWidth: "8rem" }}
        title={`Toplam Iskonto %${calculateDiscountPercentage()}`}
        onClick={onClickDiscount}
        value={fields?.TotalDiscount?.value?.amount || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              TotalDiscount: {
                ...prev.fields.TotalDiscount,
                confidence: 1,
                value: {
                  ...prev.fields.TotalDiscount?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              TotalDiscount: {
                ...prev.fields.TotalDiscount,
                confidence: 1,
                value: {
                  ...prev.fields.TotalDiscount?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
      />
      <Input
        textAlign="right"
        onClick={onClickTotalVat}
        titleSx={{ minWidth: "8rem" }}
        placeholder={"0.00"}
        title="Toplam KDV"
        value={fields?.TotalTax?.value?.amount || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              TotalTax: {
                ...prev.fields.TotalTax,
                confidence: 1,
                value: {
                  ...prev.fields.TotalTax?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              TotalTax: {
                ...prev.fields.TotalTax,
                confidence: 1,
                value: {
                  ...prev.fields.TotalTax?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
      />
      <Input
        textAlign="right"
        titleSx={{ minWidth: "8rem" }}
        onClick={onClickTotal}
        placeholder={"0.00"}
        title="Toplam"
        value={fields?.InvoiceTotal?.value?.amount || ""}
        onChange={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              InvoiceTotal: {
                ...prev.fields.InvoiceTotal,
                confidence: 1,
                value: {
                  ...prev.fields.InvoiceTotal?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
        onBlur={(e) =>
          setInvoice((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              InvoiceTotal: {
                ...prev.fields.InvoiceTotal,
                confidence: 1,
                value: {
                  ...prev.fields.InvoiceTotal?.value,
                  amount: e.target.value,
                },
              },
            },
          }))
        }
      />
    </Box>
  );
}
