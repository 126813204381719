import { Grid, Typography } from "@mui/material";

import { Numbers as NumbersIcon } from "@mui/icons-material";

const gridSx = {
  display: { xs: "none", md: "none", lg: "flex" },
  alignItems: "center",
};

export default function ItemsHeader({
  calculateVatTotals,
  calculateTotal,
  calculateDiscount,
  calculateUnitPrice,
  calculateDiscountPercentage,
}) {
  return (
    <Grid
      container
      sx={{
        height: "2rem",
        cursor: "pointer",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          textAlign: "center",
        }}
      >
        <NumbersIcon sx={{ color: "black", fontSize: "1rem" }} />
      </Grid>
      {/* <Grid item xs={1} sx={gridSx}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          Ürün Kodu
        </Typography>
      </Grid> */}
      <Grid item xs={9} md={3}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          Ürün Hizmet
        </Typography>
      </Grid>
      <Grid item xs={1} sx={gridSx}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          Miktar
        </Typography>
      </Grid>
      {/* <Grid item xs={1} sx={gridSx}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          Birim
        </Typography>
      </Grid> */}
      <Grid item xs={1}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
          onClick={calculateUnitPrice}
        >
          Birim Fiyat
        </Typography>
      </Grid>
      <Grid item xs={1} sx={gridSx}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          onClick={calculateDiscountPercentage}
        >
          İskonto Oranı
        </Typography>
      </Grid>
      <Grid item xs={1} sx={gridSx} onClick={calculateDiscount}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          İskonto Tutarı
        </Typography>
      </Grid>
      <Grid item xs={1} sx={gridSx}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          KDV Oranı
        </Typography>
      </Grid>
      <Grid item xs={1} onClick={calculateVatTotals} sx={gridSx}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          KDV Tutarı
        </Typography>
      </Grid>
      <Grid item xs={3} md={1} sx={gridSx} onClick={calculateTotal}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          Ürün Hizmet Tutarı
        </Typography>
      </Grid>
      <Grid item xs={3} md={1} sx={gridSx} onClick={calculateTotal}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
          }}
          variant="subtitle2"
        >
          Toplam Tutar
        </Typography>
      </Grid>
    </Grid>
  );
}
