import { Box, Typography } from "@mui/material";
import { CategoryTabs, ProductWidget } from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db, functions } from "../../firebase";

import { httpsCallable } from "firebase/functions";
import { set } from "date-fns";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const sanitizeForFirestore = (str) => {
  return str?.replace(/\//g, "-").replace(/[^a-zA-Z0-9-_]/g, "_");
};

export default function CompanyProducts({ filters }) {
  const { t } = useTranslation();
  const { sortByName, sortByTax, sortByDiscount, sortByTotal, activeKey } =
    filters;
  const { dateRange } = useSelector((state) => state.dateRange);

  const [products, setProducts] = useState({});
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryTab, setCategoryTab] = useState(0);
  const [subCategoryTab, setSubCategoryTab] = useState(0);
  const [aggPurchases, setAggPurchases] = useState({});
  const location = useLocation();
  const user = useSelector((state) => state.user.userDoc);
  const [loading, setLoading] = useState(false);

  const { taxid, name } = location.state || {};

  const handleCategoryChange = (v) => {
    setCategoryTab(v);
    setSubCategoryTab(0);
    const categoryElement = document.getElementById(`category-${v}`);
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubCategoryChange = (v) => {
    setSubCategoryTab(v);
    const subCategoryElement = document.getElementById(
      `subcategory-${categoryTab}-${v}`
    );
    if (subCategoryElement) {
      subCategoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    fetchCategories({ user });
  }, [taxid, name]);
  useEffect(() => {
    fetchSubCategories(categoryTab);
  }, [categoryTab, categories]);
  async function fetchCategories({ user }) {
    console.log({ taxid, name });
    try {
      const categorizedPurchasesRef = collection(
        db,
        `analytics/${taxid}/customers/${
          user?.companyId || user?.uid
        }/categories`
      );
      const snapshot = await getDocs(categorizedPurchasesRef);
      const fetchedCategories = snapshot.docs.map(
        (doc) => doc.data().originalName
      );
      console.log({ fetchedCategories });
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  const fetchSubCategories = useCallback(
    async (categoryIndex) => {
      try {
        const sanitizedCategory = sanitizeForFirestore(
          categories[categoryIndex]
        );
        const subCategoriesRef = collection(
          db,
          `analytics/${taxid}/customers/${
            user?.companyId || user?.uid
          }/categories/${sanitizedCategory}/subCategories`
        );
        const snapshot = await getDocs(subCategoriesRef);
        const fetchedSubCategories = snapshot.docs.map(
          (doc) => doc.data().originalName
        );
        setSubCategories(fetchedSubCategories);
        setSubCategoryTab(0);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      } finally {
        setLoading(false);
      }
    },
    [user, categories, setLoading]
  );

  const fetchAggregatePurchasesByProductList = useCallback(
    async (productList) => {
      if (!productList.length) return;
      try {
        const getAggregatePurchasesByProductList = httpsCallable(
          functions,
          "aggregatePurchasesByProductList"
        );
        const response = await getAggregatePurchasesByProductList({
          customerId: user?.companyId,
          userUid: user?.uid,
          products: productList,
          startDate: Math.floor(new Date(dateRange.startDate).getTime() / 1000),
          endDate: Math.floor(new Date(dateRange.endDate).getTime() / 1000),
          filterByDate: filters?.filterByDate,
        });
        setAggPurchases(response.data.purchaseAggregates);
      } catch (error) {
        console.error("Error fetching aggregate purchases:", error);
      }
    },
    [user, dateRange, filters, taxid]
  );

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const sanitizedCategory = sanitizeForFirestore(categories[categoryTab]);
      const sanitizedSubCategory = sanitizeForFirestore(
        subCategories[subCategoryTab]
      );
      const productsRef = collection(
        db,
        `analytics/${taxid}/customers/${
          user?.companyId || user?.uid
        }/categories/${sanitizedCategory}/subCategories/${sanitizedSubCategory}/products`
      );

      const snapshot = await getDocs(query(productsRef));
      const fetchedProducts = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.data().originalName,
          { ...doc.data(), id: doc.id },
        ])
      );

      setProducts(fetchedProducts);
      fetchAggregatePurchasesByProductList(Object.keys(fetchedProducts || {}));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, [
    categories,
    categoryTab,
    subCategories,
    subCategoryTab,
    user?.companyId,
    user?.uid,
    fetchAggregatePurchasesByProductList,
    taxid,
  ]);

  useEffect(() => {
    if (categories.length > 0 && subCategories.length > 0) {
      fetchProducts();
    }
  }, [categories, subCategories, categoryTab, subCategoryTab, fetchProducts]);

  const renderProducts = () => {
    let productsArray = Object.values(products);
    const { activeKey } = filters;
    const sortOrder = filters[activeKey];

    const getProductValue = (product, key) => {
      if (aggPurchases && aggPurchases[product.originalName]) {
        return aggPurchases[product.originalName][key] || product[key] || 0;
      }
      return product[key] || 0;
    };

    const sortFunctions = {
      sortByName: (a, b) => a.originalName.localeCompare(b.originalName),
      sortByCount: (a, b) =>
        (aggPurchases[b.originalName]?.count || 0) -
        (aggPurchases[a.originalName]?.count || 0),
      sortByTax: (a, b) =>
        getProductValue(b, "totalVat") - getProductValue(a, "totalVat"),
      sortByDiscount: (a, b) =>
        getProductValue(b, "totalDiscount") -
        getProductValue(a, "totalDiscount"),
      sortByTotal: (a, b) =>
        getProductValue(b, "totalAmount") - getProductValue(a, "totalAmount"),
    };

    if (sortFunctions[activeKey]) {
      productsArray.sort(sortFunctions[activeKey]);
      if (sortOrder === "asc") {
        productsArray.reverse();
      }
    }

    const productsWithAggPurchases = [];
    const productsWithoutAggPurchases = [];

    productsArray.forEach((product) => {
      const aggPurchase = aggPurchases && aggPurchases[product.originalName];
      const productData = {
        ...product,
        productName: product.originalName,
        tax: aggPurchase ? aggPurchase.tax : product.tax,
        discount: aggPurchase ? aggPurchase.discount : product.discount,
        total: aggPurchase ? aggPurchase.total : product.total,
        date: aggPurchase ? aggPurchase.date : product.date,
      };

      const productWidget = (
        <ProductWidget
          key={product.id}
          product={productData}
          history={aggPurchase}
        />
      );

      if (aggPurchase) {
        productsWithAggPurchases.push(productWidget);
      } else {
        productsWithoutAggPurchases.push(productWidget);
      }
    });

    return [...productsWithAggPurchases, ...productsWithoutAggPurchases];
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "auto",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          borderBottom: "1px solid lightgray",
          height: 88,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <CategoryTabs
          categories={categories}
          value={categoryTab}
          setValue={handleCategoryChange}
        />
        <CategoryTabs
          categories={subCategories}
          value={subCategoryTab}
          setValue={handleSubCategoryChange}
        />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>{renderProducts()}</Box>
    </Box>
  );
}
