import { categorizedList } from "./categorizedList";

export function prepareOrderList(products) {
  let list = {};
  products?.forEach((product) => {
    const productName = product?.productName || product?.name;
    const vendorIds = product?.vendorIds || [];
    const customerIds = product?.customerIds || [];
    const category = product?.category || "Uncategorized";
    const subCategory = product?.subCategory || "Uncategorized";

    if (!list[productName]) {
      list[productName] = {
        ...product,
        idList: [product.id],
        vendorIds: vendorIds,
        customerIds: customerIds,
        category,
        subCategory,
      };
    } else {
      list[productName].idList.push(product.id);
      list[productName].vendorIds = [
        ...new Set([...list[productName]?.vendorIds, ...vendorIds]),
      ];
      list[productName].customerIds = [
        ...new Set([...list[productName]?.customerIds, ...customerIds]),
      ];
    }
  });
  return categorizedList(list);
}
