import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Typography,
} from "@mui/material";
import { DescriptionRounded, Groups2Rounded } from "@mui/icons-material";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useAppContext, useInvoiceContext } from "../../context";

import { db } from "../../firebase";
import { formatDate } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Notification = ({ notification, navigate }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        padding: "0.6rem",
        borderBottom: "1px solid rgba(0,0,0,.1)",
        backgroundColor: notification?.notified ? "white" : "#f0f2f5",
      }}
      onClick={() => navigate(`/invoices/${notification?.invoiceId}`)}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Avatar
          variant="rounded"
          sx={{
            backgroundColor: "orange",
            height: "2rem",
            width: "2rem",
          }}
          src={notification?.user?.photoURL}
        >
          <DescriptionRounded />
        </Avatar>
        <Typography
          sx={{
            paddingLeft: "0.8rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "14rem",
          }}
        >
          {t(`${notification?.message}`)}
        </Typography>
      </Box>
      <Typography sx={{ color: "rgba(0,0,0,.5)" }}>
        {formatDate(notification?.timestamp, "dd/MM")}
      </Typography>
    </Box>
  );
};

const CompanyInvite = ({ notification, navigate, user }) => {
  const { t } = useTranslation();
  async function acceptCompanyInvitation() {
    try {
      const invitationRef = doc(
        db,
        "userInvitations",
        notification?.invitationId
      );
      const invitationSnap = await getDoc(invitationRef);

      const notificationRef = doc(db, "notifications", notification?.docId);
      const notificationSnap = await getDoc(notificationRef);

      const companyPageRef = doc(db, "companies", notification?.companyUrl);
      const companyPageSnap = await getDoc(companyPageRef);

      if (companyPageSnap.exists()) {
        // add user to company employees
        const companyData = companyPageSnap.data();
        const companyEmployees = companyData.employees || [];
        companyEmployees.push({
          ...user,
          companyUrl: notification?.companyUrl,
          companyLogo: notification?.logoUrl,
          companyId: notification?.companyId,
          companyName: notification?.companyName,
          role: "User",
        });
        await updateDoc(companyPageRef, { employees: companyEmployees });
      }

      if (invitationSnap.exists()) {
        await deleteDoc(invitationSnap.ref);
      }

      if (notificationSnap.exists()) {
        await deleteDoc(notificationSnap.ref);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function rejectCompanyInvitation() {
    try {
      const invitationRef = doc(
        db,
        "userInvitations",
        notification?.invitationId
      );
      const invitationSnap = await getDoc(invitationRef);
      const notificationRef = doc(db, "notifications", notification?.docId);
      const notificationSnap = await getDoc(notificationRef);

      if (notificationSnap.exists()) {
        await deleteDoc(notificationSnap.ref);
      }
      if (invitationSnap.exists()) {
        await deleteDoc(invitationSnap.ref);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        padding: "0.6rem",
        borderBottom: "1px solid rgba(0,0,0,.1)",
        backgroundColor: notification?.notified ? "white" : "#f0f2f5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            backgroundColor: "#0b66c2",
            height: "2rem",
            width: "2rem",
          }}
          src={notification?.logoUrl}
        >
          <Groups2Rounded />
        </Avatar>
        <Typography
          sx={{
            paddingLeft: "0.8rem",
          }}
        >
          {notification?.message}
        </Typography>
        <Typography sx={{ color: "rgba(0,0,0,.5)" }}>
          {formatDate(notification?.timestamp, "dd/MM")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            padding: "0.5rem 1rem",
            borderRadius: 8,
            height: "1.6rem",
          }}
          color="error"
          variant="outlined"
          onClick={rejectCompanyInvitation}
        >
          {t("reject")}
        </Button>
        <Button
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            padding: "0.5rem 1rem",
            borderRadius: 8,
            height: "1.6rem",
            marginLeft: "0.4rem",
          }}
          variant="contained"
          onClick={acceptCompanyInvitation}
        >
          {t("confirm")}
        </Button>
      </Box>
    </Box>
  );
};

export default function Notifications({ isOpen, height = "20rem" }) {
  const { notifications } = useAppContext();
  const { setNotificationLimit } = useInvoiceContext();
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDoc);

  const fetchMoreNotifications = async () => {
    if (loadingMore) return;
    setLoadingMore(true);

    try {
      setNotificationLimit((prevLimit) => prevLimit + 10);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    } finally {
      setTimeout(() => setLoadingMore(false), 3000);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      fetchMoreNotifications();
    }
  };

  return (
    <Box>
      <Collapse in={isOpen}>
        <Box
          sx={{ marginTop: "0rem", height: height, overflowY: "auto" }}
          onScroll={handleScroll}
        >
          {notifications.map((notification) => {
            if (notification?.invoiceId) {
              return (
                <Notification
                  key={notification.docId}
                  notification={notification}
                  navigate={navigate}
                />
              );
            }
            if (notification.type === "company-invite") {
              return (
                <CompanyInvite
                  key={notification.docId}
                  notification={notification}
                  navigate={navigate}
                  user={user}
                />
              );
            }
          })}
          {loadingMore && (
            <Box sx={{ textAlign: "center", padding: "1rem" }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}
