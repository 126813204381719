import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  orderList: {},
  shoppingCart: {},
  shoppingLists: {},
  previousOrders: [],
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderList: (state, action) => {
      state.orderList = action.payload;
    },
    setShoppingCart: (state, action) => {
      state.shoppingCart = action.payload;
    },
    setPreviousOrders: (state, action) => {
      state.previousOrders = action.payload;
    },
    setShoppingLists: (state, action) => {
      state.shoppingLists = action.payload;
    },
  },
});

export const {
  setOrderList,
  setShoppingCart,
  setPreviousOrders,
  setShoppingLists,
} = orderSlice.actions;

export default orderSlice.reducer;
