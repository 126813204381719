import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
export function getInvoiceStatus(status) {
  if (status === "admin-approved") {
    return {
      color: "success",
      status: "Onaylı",
      icon: <CheckCircleOutlineIcon color="success" />,
    };
  } else if (status === "user-approved") {
    return {
      color: "primary",
      status: "Kullanıcı Onaylı",
      icon: <CheckCircleOutlineIcon color="primary" />,
    };
  } else if (status === "rejected") {
    return {
      color: "error",
      status: "Reddedildi",
      icon: <ErrorOutlineIcon color="error" />,
    };
  } else if (status === "deleted") {
    return {
      color: "error",
      status: "Silindi",
      icon: <ErrorOutlineIcon color="error" />,
    };
  }
  
  else {
    return {
      color: "warning",
      status: "Onay Bekliyor",
      icon: <HourglassBottomIcon color="warning" />,
    };
  }
}
