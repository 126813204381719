import React from "react";

const KeyValueList = ({ items }) => {
  const styles = {
    container: {
      padding: "0 10px",
    },
    item: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      backgroundColor: "#fff",
    },
    firstItem: {
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
    },
    lastItem: {
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
    },
    value: {
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            ...styles.item,
            ...(index === 0 && styles.firstItem),
            ...(index === items.length - 1 && styles.lastItem),
          }}
        >
          <span>{item.label}</span>
          <span style={styles.value}>{item.value}</span>
        </div>
      ))}
    </div>
  );
};

export default KeyValueList;
