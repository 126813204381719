import React from "react";

const Sitemap = () => {
  const sitemapData = [
    { url: "/", title: "Home" },
    { url: "/request-demo", title: "Request Demo" },
    { url: "/login", title: "Login" },
    { url: "/sign-up", title: "Sign Up" },
    { url: "/data-extraction", title: "Data Extraction" },
    { url: "/collaborative-ordering", title: "Collaborative Ordering" },
    { url: "/privacy-policy", title: "Privacy Policy" },
    { url: "/blog", title: "Blog" },
  ];

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Sitemap</h1>
      <ul className="space-y-3">
        {sitemapData.map((item, index) => (
          <li key={index}>
            <a href={item.url} className="text-blue-600 hover:underline">
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sitemap;
