import { header, listItems } from "./ItemTableUtils";
import { useEffect, useState } from "react";

import { Table } from "../../components";
import { sortArrayBy } from "../../utils";
import { useSelector } from "react-redux";

export default function ItemTable({
  items,
  alternativePrices,
  handleNavigate,
  onProductClick,
  editIcon,
}) {
  const [sortConfig, setSortConfig] = useState({
    key: "productName",
    direction: "ascending",
  });

  const user = useSelector((state) => state.user.userDoc);
  const admin = user?.role === "Admin";

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const sortedItems = Object.keys(items || {})?.map((key) => {
      return {
        id: key,
        productName: items[key]?.productName,
        priceChange: items[key]?.priceChange,
        lastPrice: items[key]?.lastPrice,
        lowestPrice: items[key]?.lowestPrice,
        totalAmount: items[key]?.subTotal,
        discount: items[key]?.discount,
        totalTax: items[key]?.tax,
        total: items[key]?.total,
        productId: items[key]?.productId,
        adminHighLight: admin
          ? items[key]?.calculationError
            ? "#ffcece"
            : ""
          : "",
      };
    });
    const sorted = sortArrayBy(
      sortedItems,
      sortConfig.key,
      sortConfig.direction
    );
    setRows(sorted);
  }, [items, admin]);

  const onSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = sortArrayBy(rows, key, direction);
    setRows(sortedData);
  };
  return (
    <Table
      onSort={(key) => onSort(key)}
      onClick={handleNavigate}
      header={header}
      rows={rows}
      items={listItems(items, alternativePrices)}
      onProductClick={onProductClick}
      editIcon={editIcon}
    />
  );
}
