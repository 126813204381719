import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company: {},
  vendors: [],
  matchProductCounts: {},
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setVendors: (state, action) => {
      state.vendors = action.payload;
    },
    setMatchProductCounts: (state, action) => {
      state.matchProductCounts = action.payload;
    },
  },
});

export const { setCompany, setVendors, setMatchProductCounts } =
  companySlice.actions;

export default companySlice.reducer;
