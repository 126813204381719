export function sortArrayBy(array, key, direction = "ascending") {
  return array.sort((a, b) => {
    if (a[key] === null) return 1;
    if (b[key] === null) return -1;

    if (direction === "ascending") {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    } else {
      return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
    }
  });
}
