import { auth } from "../../firebase";
import { setUserDoc } from "../../redux/User/userSlice";
import { signOut } from "firebase/auth";

export async function handleSignOut(dispatch) {
  try {
    signOut(auth);
    dispatch(setUserDoc(null));
  } catch (error) {
    console.error(error);
  }
}
