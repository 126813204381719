import * as React from "react";

import {
  AccountBalanceWallet,
  BusinessCenter,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  CloudUpload,
  Description,
  Link,
} from "@mui/icons-material";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { DesktopNavigation, InputGroup, PageHeader } from "../components";
import { db, storage } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { COMPANY_SCHEMA } from "../constants";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButton = styled(Button)({
  border: "1px dashed #eae6df",
  borderRadius: 4,
  height: "80px",
  width: "100%",
  justifyContent: "center",
  padding: "20px",
});

export default function CreateCompanyPage() {
  const { t } = useTranslation();
  const [consent, setConsent] = useState(false);
  const [logo, setLogo] = useState(null);
  const [company, setCompany] = useState(COMPANY_SCHEMA);
  const [urlCheck, setUrlCheck] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDoc);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo({ dataUrl: e.target.result, file: file });
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file uploaded.");
      setLogo(null);
    }
  };

  async function handleCreatePage() {
    try {
      const url = await handleUpload();

      const isValid = await validateCompanyDetails();
      if (!isValid) return;

      await setDoc(doc(db, "companies", company?.url), {
        ...company,
        logoUrl: url,
        employees: [
          {
            ...user,
            role: "Owner",
            companyId: company?.companyId,
            companyName: company?.name,
          },
        ],
      });

      navigate(`/company/${company?.url}`);
    } catch (error) {
      console.error(error);
    }
  }

  async function validateCompanyDetails() {
    if (!company.name) {
      alert(t("createProfile.errors.name"));
      return false;
    }

    if (!company.description) {
      alert(t("createProfile.errors.description"));
      return false;
    }

    if (!logo || !logo.file) {
      alert(t("createProfile.errors.logo"));
      return false;
    }

    const url = company?.url;
    const docRef = doc(db, "companies", url);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.error("URL is already taken. Please choose another one.");
      alert(t("createProfile.errors.urlTaken", { url: company.url }));
      setUrlCheck(true);
      return false;
    }

    setUrlCheck(false);
    return true;
  }

  async function checkUrl() {
    if (!company?.url) {
      return;
    }
    const docRef = doc(db, "companies", company?.url);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUrlCheck(true);
    } else {
      setUrlCheck(false);
    }
  }

  async function handleUpload() {
    if (!logo || !logo.file) {
      console.error("No logo file to upload.");
      return;
    }

    const logoPath = `companyLogos/${company?.url || "defaultLogo"}`;
    const logoRef = ref(storage, logoPath);

    try {
      const snapshot = await uploadBytes(logoRef, logo.file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error during the upload process:", error);
    }
  }

  const inputItems = [
    {
      label: t("createProfile.placeholders.name"),
      key: "name",
      value: company.name || "",
      iconLeft: <BusinessCenter style={{ color: "#ccc" }} />,
    },
    {
      label: t("createProfile.placeholders.description"),
      key: "description",
      value: company.description || "",
      iconLeft: <Description style={{ color: "#ccc" }} />,
    },
    {
      label: "tedaarik.com/company/*",
      key: "url",
      value: company.url || "",
      iconLeft: <Link style={{ color: "#ccc" }} />,
      error: urlCheck,
      helperText: urlCheck
        ? t("createProfile.errors.urlTaken", { url: company.url })
        : "",
      onBlur: checkUrl,
    },
    {
      label: t("createProfile.placeholders.companyId"),
      key: "companyId",
      value: company.companyId || "",
      iconLeft: <AccountBalanceWallet style={{ color: "#ccc" }} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        background: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",
      }}
    >
      <DesktopNavigation />

      <Box
        sx={{
          minHeight: "100vh",
          width: { lg: "76%", md: "100%", sm: "100%", xs: "100%" },
          borderLeft: "1px solid lightgray",
          borderRight: "1px solid lightgray",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        <PageHeader title={t("createProfile.title")} />

        <Typography sx={{ paddingTop: "1rem", paddingX: 1 }}>
          {t("createProfile.subtitle")}
        </Typography>
        <Grid
          container
          sx={{
            width: {
              lg: "80%",
              md: "90%",
              sm: "100%",
              xs: "100%",
            },
            marginTop: 2,
            marginBottom: 10,
          }}
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: 4,
                padding: 2,
                border: {
                  lg: "1px solid lightgray",
                  md: "1px solid lightgray",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <InputGroup
                items={inputItems}
                onChangeText={(key, value) => {
                  setCompany((prev) => ({ ...prev, [key]: value }));
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingY: 2,
                }}
              >
                <UploadButton component="label" variant="text">
                  {logo?.dataUrl ? (
                    <Avatar
                      src={logo?.dataUrl}
                      sx={{ width: 64, height: 64 }}
                    />
                  ) : (
                    <>
                      <CloudUpload sx={{ color: "black", fontSize: 30 }} />
                      <Typography
                        sx={{ color: "gray", paddingLeft: "0.4rem" }}
                        variant="body2"
                      >
                        {t("createProfile.upload.uploadLogo")}
                      </Typography>
                    </>
                  )}
                  <VisuallyHiddenInput
                    accept="image/jpeg, image/png"
                    type="file"
                    onChange={handleFileChange}
                    multiple={false}
                  />
                </UploadButton>
                <Typography variant="caption">
                  {t("createProfile.upload.recommendation")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    paddingTop: "1rem",
                  }}
                  onClick={() => setConsent(!consent)}
                >
                  {consent ? (
                    <CheckBoxRounded sx={{ color: "#0766c2" }} />
                  ) : (
                    <CheckBoxOutlineBlankRounded />
                  )}
                  <Typography sx={{ cursor: "pointer", paddingLeft: "0.4rem" }}>
                    {t("createProfile.consent")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{ borderRadius: 4, fontWeight: "bold" }}
                disabled={!consent}
                onClick={handleCreatePage}
              >
                {t("createProfile.buttonText")}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 4,
                border: "1px solid lightgray",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              <Typography
                sx={{ paddingTop: 1, paddingX: 2, fontWeight: "bold" }}
              >
                {t("preview")}
              </Typography>
              <Box>
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: 2,
                    borderRadius: 4,
                  }}
                >
                  <Avatar src={logo?.dataUrl} sx={{ width: 64, height: 64 }} />
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                    {company?.name ? company?.name : t("companyName")}
                  </Typography>
                  <Typography>
                    {company?.description
                      ? company?.description
                      : t("companyDescription")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
