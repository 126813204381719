import React, { createContext, useContext, useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";

import { db } from "../firebase";
import { useSelector } from "react-redux";

const AppContext = createContext();

const today = new Date();

const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

export const AppProvider = ({ children }) => {
  const user = useSelector((state) => state.user.userDoc);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: thirtyDaysAgo,
    endDate: today,
    label: "Tüm Tarihler",
    initialLoad: true,
  });

  useEffect(() => {
    let unsubscribeFavorites = null;

    if (user) {
      unsubscribeFavorites = listenFavorites();
    }

    return () => {
      if (unsubscribeFavorites) unsubscribeFavorites();
    };
  }, [user]);

  function listenFavorites() {
    const newInvoiceQuery = query(
      collection(db, "favorites"),
      where("userUid", "==", user?.uid)
    );

    return onSnapshot(newInvoiceQuery, (querySnapshot) => {
      const favorites = querySnapshot.docs.map((doc) => ({
        docId: doc.id,
        ...doc.data(),
      }));
      setFavorites(favorites);
    });
  }

  const value = {
    loading,
    setLoading,
    dateRange,
    setDateRange,
    search,
    setSearch,
    favorites,
    uploadFiles,
    setUploadFiles,
    notifications,
    setNotifications,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
