import { Box, Grid, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { PageFilter, PageHeader } from "../components";

import { ItemTable } from "../components";
import { prepareItemsCards } from "./itemUtils";
import { useProductContext } from "../context";
import { useSelector } from "react-redux";

export default function Items() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDoc);
  const admin = user?.role === "Admin";
  const { purchases, alternativePrices, categories, setEditProductId } =
    useProductContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#f4f2ee",
        padding: { sm: 0, md: 0, lg: 4 },
      }}
    >
      <PageHeader title="Ürünler" />
      <PageFilter cards={prepareItemsCards(purchases)} />
      <Grid container sx={{ marginBottom: 1 }}>
        {Object.keys(categories || {})?.map((c) => {
          return (
            <Grid
              key={c}
              xs={6}
              md={3}
              lg={2}
              sx={{
                padding: 0.2,
              }}
              item
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: 1,
                  borderRadius: 2,
                }}
                onClick={() => navigate(`/categories/${c}`)}
              >
                <Typography>{c}</Typography>
                <Typography>
                  {Object.keys(categories?.[c]?.products || {}).length}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Box
        sx={{
          flexDirection: "column",
          border: "1px solid #dfdeda",
          backgroundColor: "white",
          borderRadius: { md: 0, lg: 4 },
          width: "100%",
          padding: 2,
          overflow: "auto",
          pb: { lg: 0, md: 10, xs: 10 },
          marginBottom: 10,
        }}
      >
        <ItemTable
          items={purchases}
          alternativePrices={alternativePrices}
          handleNavigate={(id) =>
            navigate(`/products-services/${purchases?.[id]?.productName}`)
          }
          editIcon={admin ? true : false}
          onProductClick={(id) => setEditProductId(id)}
        />
      </Box>
      <Outlet />
    </Box>
  );
}
