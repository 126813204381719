import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { setCompanies } from "../../redux/Invoices/invoiceSlice";

export async function fetchAggregateInvoicesByVendor(
  { user, startDate, endDate, filterByDate },
  dispatch
) {
  try {
    const getAggregateInvoicesByVendor = httpsCallable(
      functions,
      "aggregateInvoicesByVendor"
    );

    let response;
    if (filterByDate) {
      response = await getAggregateInvoicesByVendor({
        user,
        startDate: Math.floor(new Date(startDate).getTime() / 1000),
        endDate: Math.floor(new Date(endDate).getTime() / 1000),
      });
    } else {
      response = await getAggregateInvoicesByVendor({
        user,
      });
    }
    dispatch(setCompanies(response.data));
  } catch (error) {
    console.error("Error fetching aggregate invoices by vendor:", error);
    throw error;
  }
}
