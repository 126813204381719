import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
  limit: 15,
  companies: {},
  companyInvoices: [],
};

const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    setInvoiceLimit: (state, action) => {
      state.limit = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setCompanyInvoices: (state, action) => {
      state.companyInvoices = action.payload;
    },
  },
});

export const {
  setInvoices,
  setInvoiceLimit,
  setCompanies,
  setCompanyInvoices,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
