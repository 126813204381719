import {
  DesktopNavigation,
  OverviewCompanies,
  OverviewHelperPage,
  OverviewInvoices,
  OverviewProducts,
  PageHeader,
  Tabs,
} from "../components";
import React, { useEffect, useState } from "react";
import {
  setActiveKey,
  setFilterByDate,
  setFilteredCompanies,
  setSortByDate,
  setSortByDiscount,
  setSortByName,
  setSortByTax,
  setSortByTotal,
  setTab,
} from "../redux/App/OverViewSlice";

import { Box } from "@mui/material";
import { fetchAggregateInvoicesByVendor } from "../scripts/invoices/fetchAggregateInvoicesByVendor";
import { fetchCategories } from "../scripts/overview/fetchCategories";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Overview() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDoc);
  const { tab, filters, categories, filteredCompanies } = useSelector(
    (state) => state.overView
  );
  const { dateRange } = useSelector((state) => state.dateRange);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setLoading(true);
        try {
          await fetchAggregateInvoicesByVendor(
            {
              user,
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              filterByDate: filters?.filterByDate,
            },
            dispatch
          );
          await fetchCategories({ user, dispatch });
        } catch (error) {
          console.error("Error fetching aggregate invoices:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, user, dateRange, filters?.filterByDate]);

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        background: "linear-gradient(to bottom, #FFFFFF, #F2F2F2)",
      }}
    >
      <DesktopNavigation />
      <Box
        sx={{
          minHeight: "100vh",
          width: {
            lg: "40%",
            md: "60%",
            sm: "100%",
            xs: "100%",
          },
          borderLeft: "1px solid lightgray",
          borderRight: "1px solid lightgray",
        }}
      >
        <Box>
          <PageHeader title={t("overview")} loading={loading} />
          <Tabs
            setTab={(v) => dispatch(setTab(v))}
            tab={tab}
            tabs={[
              {
                id: 0,
                label: t("companies"),
                children: (
                  <OverviewCompanies
                    setLoading={setLoading}
                    loading={loading}
                  />
                ),
              },
              {
                id: 1,
                label: t("products"),
                children: (
                  <OverviewProducts setLoading={setLoading} loading={loading} />
                ),
              },
              {
                id: 2,
                label: t("invoices"),
                children: (
                  <OverviewInvoices setLoading={setLoading} loading={loading} />
                ),
              },
            ]}
          />
        </Box>
      </Box>
      <OverviewHelperPage
        filters={filters}
        filteredCompanies={filteredCompanies}
        setFilterByDate={(e) => {
          dispatch(setFilterByDate(e));
          dispatch(setActiveKey(null));
        }}
        setSortByName={(e) => {
          dispatch(setSortByName(e));
          dispatch(setActiveKey("sortByName"));
        }}
        setSortByTax={(e) => {
          dispatch(setSortByTax(e));
          dispatch(setActiveKey("sortByTax"));
        }}
        setSortByDiscount={(e) => {
          dispatch(setSortByDiscount(e));
          dispatch(setActiveKey("sortByDiscount"));
        }}
        setSortByTotal={(e) => {
          dispatch(setSortByTotal(e));
          dispatch(setActiveKey("sortByTotal"));
        }}
        setSortByDate={(e) => {
          dispatch(setSortByDate(e));
          dispatch(setActiveKey("sortByDate"));
        }}
        setFilteredCompanies={(e) => {
          let newFilterCompanies = [];
          if (filteredCompanies.includes(e)) {
            newFilterCompanies = filteredCompanies.filter((f) => f !== e);
          } else {
            newFilterCompanies = [...filteredCompanies, e];
          }
          if (filteredCompanies.includes(e)) {
            dispatch(
              setFilteredCompanies(newFilterCompanies.filter((f) => f !== e))
            );
          } else {
            dispatch(setFilteredCompanies(newFilterCompanies));
          }
        }}
        t={t}
        tab={tab}
        categories={categories}
      />
    </Box>
  );
}
