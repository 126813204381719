import { Box, Grid, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { PageFilter, PageHeader } from "../components";

import { ItemTable } from "../components";
import { prepareItemsCards } from "./itemUtils";
import { useProductContext } from "../context";

export default function Items() {
  const navigate = useNavigate();
  const { purchases, alternativePrices, searchParams } = useProductContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#f4f2ee",
        padding: { sm: 0, md: 0, lg: 4 },
      }}
    >
      <PageHeader title="Mal / Hizmet" />
      <PageFilter cards={prepareItemsCards(purchases)} />

      <Box
        sx={{
          flexDirection: "column",
          border: "1px solid #dfdeda",
          backgroundColor: "white",
          borderRadius: { md: 0, lg: 4 },
          width: "100%",
          padding: 2,
          overflow: "auto",
          pb: { lg: 0, md: 10, xs: 10 },
        }}
      >
        <ItemTable
          items={purchases}
          alternativePrices={alternativePrices}
          handleNavigate={(id) =>
            navigate(`/products-services/${purchases?.[id]?.productName}`)
          }
        />
      </Box>
      <Outlet />
    </Box>
  );
}
