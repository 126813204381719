import {
  AddAPhotoRounded,
  ArticleOutlined,
  BusinessRounded,
  ExitToAppRounded,
  KitchenOutlined,
  Language,
  PrecisionManufacturingRounded,
} from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import React, { useState } from "react";

import { handleSignOut } from "../scripts/auth/handleSignOut";
import i18n from "i18next";
import logo from "../assets/logo.png";
import { setTab } from "../redux/App/OverViewSlice";
import uniqolor from "uniqolor";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ItemButton = ({ icon, label, onClick }) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        color: "black",
        // fontWeight: "bold",
        justifyContent: "flex-start",
        ".MuiButton-startIcon": {
          fontSize: "2rem",
          paddingLeft: "1rem",
        },
        paddingY: "0.8rem",
        borderRadius: "42px",
        fontSize: "1.2rem",
        "&:hover": {
          backgroundColor: "lightgray",
        },
        "&:focus": {
          backgroundColor: "lightgray",
        },
        paddingRight: "2rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      startIcon={icon}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default function DesktopNavigation() {
  const user = useSelector((state) => state.user.userDoc);
  const admin = user?.role === "Admin";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "tr" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  return (
    <>
      <Avatar
        variant="square"
        src={logo}
        sx={{
          marginLeft: "1.4rem",
          marginBottom: "1rem",
        }}
      />
      <ItemButton
        label={t("profile")}
        onClick={() => navigate("/profile")}
        icon={
          <Avatar
            sx={{
              backgroundColor: `${
                uniqolor(user?.displayName || user?.email).color
              }`,
            }}
            src={user?.photoURL}
          >
            {user?.email?.slice(0, 1)?.toUpperCase()}
          </Avatar>
        }
      />
      <ItemButton
        label={user?.companyName || t("createBusinessProfile")}
        onClick={() => {
          user?.companyUrl
            ? navigate(`/company/${user?.companyUrl}`)
            : navigate("/company/setup/new");
        }}
        icon={
          <Avatar
            sx={{
              backgroundColor: `${uniqolor(user?.companyName).color}`,
              width: "2rem",
              height: "2rem",
              fontSize: "11rem",
            }}
            src={user?.companyLogo}
          >
            {user?.companyName?.slice(0, 1)?.toUpperCase()}
          </Avatar>
        }
      />

      <ItemButton
        label={t("companies")}
        onClick={() => {
          dispatch(setTab(0));
          navigate("/overview");
        }}
        icon={
          <BusinessRounded
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        }
      />
      <ItemButton
        label={t("products")}
        onClick={() => {
          dispatch(setTab(1));
          navigate("/overview");
        }}
        icon={
          <KitchenOutlined
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        }
      />
      <ItemButton
        label={t("invoices")}
        onClick={() => {
          dispatch(setTab(2));
          navigate("/overview");
        }}
        icon={
          <ArticleOutlined
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        }
      />

      <ItemButton
        label={t("uploadInvoice")}
        onClick={() => navigate("/upload")}
        icon={
          <AddAPhotoRounded
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        }
      />

      {admin && (
        <>
          <ItemButton
            label={t("products")}
            onClick={() => navigate("/products")}
            icon={
              <PrecisionManufacturingRounded
                sx={{
                  width: "2rem",
                  height: "2rem",
                }}
              />
            }
          />
        </>
      )}

      <ItemButton
        label={t("systemLanguage")}
        onClick={toggleLanguage}
        icon={
          <Language
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        }
      />
      <ItemButton
        label={t("signOut")}
        onClick={() => {
          handleSignOut(dispatch);
          navigate("/");
        }}
        icon={
          <ExitToAppRounded
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        }
      />
    </>
  );
}
