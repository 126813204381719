import { ArrowForwardIos, ReportGmailerrorred } from "@mui/icons-material";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { createCurrency, formatDate } from "../../utils";

import uniqolor from "uniqolor";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function InvoiceWidget({ invoice }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingX: "1rem",
        paddingY: "0.4rem",
        cursor: "pointer",
        borderBottom: "1px solid #e0e0e0",
      }}
      onClick={() => {
        navigate(`/invoices/${invoice.id}`);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar
          variant="square"
          sx={{
            borderRadius: "8px",
            backgroundColor: uniqolor(invoice?.vendorName).color,
            width: "64px",
            height: "64px",
            fontSize: "2rem",
          }}
        >
          {invoice?.vendorName?.[0]}
        </Avatar>
        <Box
          sx={{
            marginLeft: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.8rem",
              // color: "gray",
              lineHeight: "1",
            }}
          >
            {invoice?.date && formatDate(invoice?.date, "dd MMMM")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            {invoice?.vendorName}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              justifyContent: "space-between",
              gap: 1,
              marginTop: 0.2,
            }}
          >
            {invoice?.falsy && (
              <Chip
                sx={{
                  height: 18,
                }}
                label={t("reviewRequiered")}
                color="error"
                size="small"
                icon={<ReportGmailerrorred />}
              />
            )}

            {invoice?.discount > 0 && (
              <Chip
                sx={{
                  height: 18,
                }}
                label={`${t("discount")} ${createCurrency(
                  invoice?.totalDiscount
                ).format()}`}
                size="small"
                color="success"
                variant="outlined"
              />
            )}
            {invoice?.totalVat > 0 && (
              <Chip
                sx={{
                  height: 18,
                }}
                label={`${t("vat")} ${createCurrency(
                  invoice?.totalVat
                ).format()}`}
                size="small"
                color="info"
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          //   paddingRight: 5,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "bold",
            color: "#0b66c2",
          }}
        >
          {createCurrency(invoice?.total).format()}
        </Typography>
        <ArrowForwardIos
          sx={{
            color: "#0b66c2",
            fontSize: 16,
          }}
        />
      </Box>
    </Box>
  );
}
