import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { setActiveKey, setSortByDate } from "../../redux/App/OverViewSlice";
import { useDispatch, useSelector } from "react-redux";

import { InvoiceWidget } from "..";
import { Outlet } from "react-router-dom";
import { Verified } from "@mui/icons-material";
import { fetchInvoices } from "../../scripts/invoices/fetchInvoices";
import { fetchPendingInvoices } from "../../scripts/invoices/fetchPendingInvoices";
import { useTranslation } from "react-i18next";

export default function Invoices({ loading, setLoading }) {
  const { t } = useTranslation();
  const { dateRange } = useSelector((state) => state.dateRange);
  const { filters } = useSelector((state) => state.overView);
  const user = useSelector((state) => state.user.userDoc);
  const dispatch = useDispatch();

  const [invoices, setInvoices] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState(null);

  const {
    sortByName,
    sortByTax,
    sortByDiscount,
    sortByTotal,
    activeKey,
    sortByDate,
  } = filters;

  useEffect(() => {
    dispatch(setSortByDate("desc"));
    dispatch(setActiveKey("sortByDate"));
  }, [dispatch]);

  useEffect(() => {
    if (!user) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetchInvoices({
          user,
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
          filterByDate: filters?.filterByDate,
        });
        setInvoices(res);

        await fetchPendingInvoices({ user, setPendingInvoices });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, dateRange, filters?.filterByDate]);

  useEffect(() => {
    const compare = (a, b, key, order) => {
      if (order === "asc") {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    };

    const sortInvoices = (a, b) => {
      switch (activeKey) {
        case "sortByTax":
          return compare(a, b, "totalVat", sortByTax);
        case "sortByDiscount":
          return compare(a, b, "totalDiscount", sortByDiscount);
        case "sortByTotal":
          return compare(a, b, "total", sortByTotal);
        case "sortByDate":
          return sortByDate === "asc"
            ? a.date.seconds - b.date.seconds
            : b.date.seconds - a.date.seconds;
        case "sortByName":
        default:
          return sortByName === "asc"
            ? a.vendorName.localeCompare(b.vendorName)
            : b.vendorName.localeCompare(a.vendorName);
      }
    };
    const sortedInvoices = invoices.sort(sortInvoices);
    setInvoices([...sortedInvoices]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortByName,
    sortByTax,
    sortByDiscount,
    sortByTotal,
    activeKey,
    sortByDate,
  ]);

  return (
    <Box sx={{ marginBottom: 10 }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              paddingBottom: "0.5rem",
            }}
          >
            {Object.keys(pendingInvoices || {}).length > 0 ? (
              <Typography
                sx={{
                  paddingX: "1rem",
                  paddingTop: "0.6rem",
                  fontWeight: "bold",
                }}
              >
                {t("pendingDocuments")}
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0.6rem",
                  borderBottom: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <Verified
                  style={{
                    color: "#34a853",
                  }}
                />
                <Typography
                  sx={{
                    paddingX: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {t("noPendingDocuments")}
                </Typography>
              </Box>
            )}

            {/* {pendingInvoices === null && (
              <Box sx={{ padding: 1 }}>
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={60}
                  sx={{ marginBottom: 1 }}
                />
                <Skeleton variant="rounded" width={"100%"} height={60} />
              </Box>
            )} */}

            {Object.values(pendingInvoices || {})?.map((invoice) => {
              return <InvoiceWidget invoice={invoice} key={invoice.id} />;
            })}
          </Box>
          <Box>
            <Typography
              sx={{
                paddingX: "1rem",
                paddingTop: "0.6rem",
                fontWeight: "bold",
              }}
            >
              {t("invoices")}
            </Typography>
            {Object.values(invoices || {})?.map((invoice) => {
              return <InvoiceWidget invoice={invoice} key={invoice.id} />;
            })}
          </Box>
        </Box>
      </Box>

      <Outlet />
    </Box>
  );
}
