import {
  AccountTree,
  ArticleOutlined,
  AttachMoney,
  BusinessRounded,
  Campaign,
  CurrencyLira,
  Discount,
  LocalOffer,
  Percent,
  PercentOutlined,
  PointOfSale,
  ShoppingCart,
} from "@mui/icons-material";

import { createCurrency } from "../utils";

export function prepareCompanyCards(companies, t) {
  return [
    {
      title: t("company"),
      icon: <BusinessRounded sx={{ fontSize: 18 }} />,
      value: Object.keys(companies || {}).length,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: t("invoices"),
      icon: <ArticleOutlined sx={{ fontSize: 18 }} />,
      value: Object.values(companies || {}).reduce(
        (acc, nex) => acc + nex.invoiceCount,
        0
      ),
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },

    {
      icon: <LocalOffer sx={{ fontSize: 18 }} />,
      title: t("subtotal"),
      value:
        Object.values(companies || {}).reduce(
          (acc, nex) => acc + nex.subTotal,
          0
        ) > 0
          ? createCurrency(
              Object.values(companies || {}).reduce(
                (acc, nex) => acc + nex.subTotal,
                0
              )
            ).format()
          : null,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: t("totalDiscount"),
      icon: <PercentOutlined sx={{ fontSize: 18 }} />,

      value:
        Object.values(companies || {}).reduce(
          (acc, nex) => acc + nex.discount,
          0
        ) > 0
          ? createCurrency(
              Object.values(companies || {}).reduce(
                (acc, nex) => acc + nex.discount,
                0
              )
            ).format()
          : createCurrency(0).format(),
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: t("totalVat"),
      icon: <AttachMoney sx={{ fontSize: 18 }} />,

      value:
        Object.values(companies || {}).reduce(
          (acc, nex) => acc + Number(nex.tax || 0),
          0
        ) > 0
          ? createCurrency(
              Object.values(companies || {}).reduce(
                (acc, nex) => acc + Number(nex.tax || 0),
                0
              )
            ).format()
          : null,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: t("totalPrice"),
      icon: <ShoppingCart sx={{ fontSize: 18 }} />,

      value:
        Object.values(companies || {}).reduce(
          (acc, nex) => acc + nex.total,
          0
        ) > 0
          ? createCurrency(
              Object.values(companies || {}).reduce(
                (acc, nex) => acc + nex.total,
                0
              )
            ).format()
          : null,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
  ];
}

export function prepareCompanyViewCards(invoices, purchases) {
  return [
    {
      title: "Fırsat Ürünü",
      value: Object.keys(purchases || {}).filter(
        (p) => purchases?.[p]?.lastPrice > purchases?.[p]?.lowestPrice
      ).length,
      icon: Campaign,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Ürün Çeşiti",
      value: Object.keys(purchases || {}).length,
      icon: AccountTree,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "İskonto",
      value: createCurrency(
        Object.values(invoices || {}).reduce(
          (acc, nex) => acc + nex.discount,
          0
        )
      ).format(),
      icon: Discount,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Ara Toplam",
      value: createCurrency(
        Object.values(invoices || {}).reduce(
          (acc, nex) => acc + Number(nex.subTotal || 0),
          0
        )
      ).format(),
      icon: PointOfSale,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Kdv",
      value: createCurrency(
        Object.values(invoices || {}).reduce(
          (acc, nex) => acc + Number(nex.tax || 0),
          0
        )
      ).format(),
      icon: Percent,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
    {
      title: "Toplam",
      value: createCurrency(
        Object.values(invoices || {}).reduce(
          (acc, nex) => acc + Number(nex.total || 0),
          0
        )
      ).format(),
      icon: CurrencyLira,
      xs: 4,
      md: 4,
      lg: 2,
      xl: 2,
    },
  ];
}
