import * as React from "react";

import { Area, AreaChart, ResponsiveContainer } from "recharts";

export default function ItemLineChart({ purchases }) {
  const [trendColor, setTrendColor] = React.useState("#808080"); // Default gray
  const gradientId = React.useId(); // Generate a unique ID for this instance

  React.useEffect(() => {
    if (purchases && purchases.length >= 2) {
      const firstValue = purchases[purchases.length - 2]?.price;
      const lastValue = purchases[purchases.length - 1]?.price;
      setTrendColor(lastValue <= firstValue ? "#00C853" : "#FF3D00");
    }
  }, [purchases]);

  if (!purchases || purchases.length < 2) {
    return null; // Or you could return a placeholder/loading state
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={purchases}>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={trendColor} stopOpacity={0.2} />
            <stop offset="95%" stopColor={trendColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="price"
          stroke={trendColor}
          fillOpacity={1}
          fill={`url(#${gradientId})`}
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
