import { Avatar, Box, Chip, Typography } from "@mui/material";

import { ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import { createCurrency } from "../../utils";
import uniqolor from "uniqolor";

export default function CompanyWidget({ company, details, navigate, t }) {
  return (
    <Box
      key={company}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingX: "1rem",
        paddingY: "0.4rem",
        cursor: "pointer",
        borderBottom: "1px solid #e0e0e0",
      }}
      onClick={() =>
        navigate(`company/${company}`, {
          state: { name: company, taxid: details.vendorId },
        })
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar
          variant="square"
          sx={{
            borderRadius: "8px",
            backgroundColor: uniqolor(company).color,
            width: "64px",
            height: "64px",
            fontSize: "2rem",
          }}
        >
          {company[0]}
        </Avatar>
        <Box
          sx={{
            marginLeft: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            {company}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "gray",
              lineHeight: "1",
            }}
          >
            {details?.invoiceCount} {t("invoice")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              gap: "0.5rem",
              marginTop: "0.2rem",
            }}
          >
            {details?.discount > 0 && (
              <Chip
                sx={{
                  height: 18,
                }}
                size="small"
                label={`${t("discount")} ${createCurrency(
                  details?.discount
                ).format()}`}
                color="success"
                variant="outlined"
              />
            )}
            <Chip
              sx={{
                height: 18,
              }}
              size="small"
              label={`${t("vat")} ${createCurrency(details?.tax).format()}`}
              color="info"
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          //   paddingRight: 5,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "bold",
            color: "#0b66c2",
          }}
        >
          {createCurrency(details?.total).format()}
        </Typography>
        <ArrowForwardIos
          sx={{
            color: "#0b66c2",
            fontSize: 16,
          }}
        />
      </Box>
    </Box>
  );
}
