import React, { createContext, useContext, useEffect, useState } from "react";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";
import { useSelector } from "react-redux";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const user = useSelector((state) => state.user.userDoc);

  const [orders, setOrders] = useState([]);
  const [approvedOrders, setApprovedOrders] = useState([]);
  const [groupedOrders, setGroupedOrders] = useState({});
  const [groupedApprovedOrders, setGroupedApprovedOrders] = useState({});
  const [previousOrders, setPreviousOrders] = useState([]);

  useEffect(() => {
    if (user) {
      fetchOrders();
      fetchPastOrders();
    }
  }, [user]);

  useEffect(() => {
    // categorize the orders by productId
    let groupedOrders = orders.reduce((acc, order) => {
      if (!acc[order.vendorName]) {
        acc[order.vendorName] = {
          products: {},
          taxId: order.taxId,
          phone: order.phone,
        };
      }
      if (!acc[order.vendorName].products[order.productName]) {
        acc[order.vendorName].products[order.productName] = {
          ...order,
          orderCount: 0,
        };
      }
      acc[order.vendorName].products[order.productName].orderCount +=
        order.orderCount;
      return acc;
    }, {});
    // remove the orders with orderCount 0
    Object.keys(groupedOrders).forEach((vendorName) => {
      Object.keys(groupedOrders[vendorName].products).forEach((productName) => {
        if (groupedOrders[vendorName].products[productName].orderCount === 0) {
          delete groupedOrders[vendorName].products[productName];
        }
      });
      if (Object.keys(groupedOrders[vendorName].products).length === 0) {
        delete groupedOrders[vendorName];
      }
    });

    let groupedApprovedOrders = approvedOrders.reduce((acc, order) => {
      if (!acc[order.vendorName]) {
        acc[order.vendorName] = {
          products: {},
        };
      }
      if (!acc[order.vendorName].products[order.productName]) {
        acc[order.vendorName].products[order.productName] = {
          ...order,
          orderCount: 0,
        };
      }
      acc[order.vendorName].products[order.productName].orderCount +=
        order.orderCount;
      return acc;
    }, {});
    // remove the orders with orderCount 0
    Object.keys(groupedOrders).forEach((vendorName) => {
      Object.keys(groupedOrders[vendorName].products).forEach((productName) => {
        if (groupedOrders[vendorName].products[productName].orderCount === 0) {
          delete groupedOrders[vendorName].products[productName];
        }
      });
      if (Object.keys(groupedOrders[vendorName].products).length === 0) {
        delete groupedOrders[vendorName];
      }
    });

    setGroupedApprovedOrders(groupedApprovedOrders);
    setGroupedOrders(groupedOrders);
  }, [orders]);

  async function fetchOrders() {
    try {
      let orderQuery = query(collection(db, "orders"));

      if (user?.companyId) {
        orderQuery = query(
          orderQuery,
          where("companyId", "==", user.companyId)
        );
      } else {
        orderQuery = query(orderQuery, where("userId", "==", user.uid));
      }

      onSnapshot(orderQuery, (querySnapshot) => {
        let orders = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          orders.push(data);
        });
        setOrders(orders);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchPastOrders() {
    try {
      let orderQuery = query(collection(db, "orderHistory"));

      if (user?.companyId) {
        orderQuery = query(
          orderQuery,
          where("companyId", "==", user.companyId),
          orderBy("createdAt", "desc")
        );
      } else {
        orderQuery = query(
          orderQuery,
          where("userId", "==", user.uid),
          orderBy("createdAt", "desc")
        );
      }

      onSnapshot(orderQuery, (querySnapshot) => {
        let orders = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          orders.push({ ...data, docId: doc.id });
        });
        setPreviousOrders(orders);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function addToOrderCart(e) {
    if (!user?.uid) return;
    try {
      await addDoc(collection(db, "orders"), {
        ...e,
        userId: user?.uid,
        companyId: user?.companyId,
        userName: user?.displayName,
        orderCount: 1,
        status: "pending",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function addProductsFromPreviousOrder(order) {
    if (!user?.uid) return;
    try {
      Object.keys(order.products || {}).map(async (product) => {
        await addDoc(collection(db, "orders"), {
          ...order.products[product],
          userId: user?.uid,
          companyId: user?.companyId,
          userName: user?.displayName,
          orderCount: order.products[product]?.orderCount,
          status: "pending",
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function removeFromOrderCount(e) {
    if (!user?.uid) return;

    try {
      await addDoc(collection(db, "orders"), {
        ...e,
        userId: user?.uid,
        companyId: user?.companyId,
        userName: user?.displayName,
        orderCount: -1,
        status: "pending",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function removeProductFromOrderCart(e, count) {
    if (!user?.uid) return;

    try {
      await addDoc(collection(db, "orders"), {
        ...e,
        userId: user?.uid,
        companyId: user?.companyId,
        userName: user?.displayName,
        orderCount: count * -1,
        status: "pending",
      });
    } catch (error) {
      console.error(error);
    }
  }

  const value = {
    addToOrderCart,
    removeFromOrderCount,
    removeProductFromOrderCart,
    groupedOrders,
    groupedApprovedOrders,
    previousOrders,
    addProductsFromPreviousOrder,
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

export const useOrderContext = () => useContext(OrderContext);
