import { GoogleAuthProvider, getAuth } from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAcfyUIq1RCafke9m9Uw7quuG1NYXfbhs4",
  authDomain: "teklif-app-35e18.firebaseapp.com",
  projectId: "teklif-app-35e18",
  storageBucket: "teklif-app-35e18.appspot.com",
  messagingSenderId: "1032865958954",
  appId: "1:1032865958954:web:1dfbc22ea9589f3fd94753",
  measurementId: "G-FHNYC6Y0CJ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app, "gs://teklif-app-35e18.appspot.com");

export { auth, provider, db, functions, storage, analytics };
