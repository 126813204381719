import { Box } from "@mui/material";
import { MenuItems } from "../components";
import fullLogo from "../assets/full-logo.png";
import { useNavigate } from "react-router-dom";

export default function Menu() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: 2,
        paddingTop: 4,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 2,
        }}
      >
        {/* <img
          src={fullLogo}
          alt="logo"
          style={{
            height: "3rem",
          }}
          onClick={() => navigate(-1)}
        /> */}
      </Box>

      <MenuItems />
    </Box>
  );
}
